import { CartItem } from '../../../../models/cart-item/cart-item';

function isSameModifier(item: CartItem, targetItem: CartItem): boolean {
  // オプションがない時はtrue
  if (
    item.modifierSetControllers === null &&
    targetItem.modifierSetControllers === null
  ) {
    return true;
  }

  // どちらか一方のオプションがない場合はfalse
  if (
    item.modifierSetControllers === null ||
    targetItem.modifierSetControllers === null
  ) {
    return false;
  }

  // オプションの数が一致しない場合はfalse
  if (
    item.modifierSetControllers.countModifiers() !==
    targetItem.modifierSetControllers.countModifiers()
  ) {
    return false;
  }

  const modifiers = item.modifierSetControllers.getModifiers();
  // オプションが全て一致する場合のみtrue
  return targetItem.modifierSetControllers
    .getModifiers()
    .every((targetModifier) => {
      return modifiers.some((modifierWithQuantity) => {
        return [
          targetModifier.modifier.modifierId.eq(
            modifierWithQuantity.modifier.modifierId
          ),
          targetModifier.quantity === modifierWithQuantity.quantity,
        ].every((v) => v);
      });
    });
}

export function findSameItemFromCart(
  items: CartItem[],
  targetItem: CartItem
): CartItem | null {
  const found = items.find((item) => {
    return [
      item.itemId.eq(targetItem.itemId),
      item.variationId.eq(targetItem.variationId),
      isSameModifier(item, targetItem),
    ].every((v) => v);
  });
  return found ?? null;
}
