import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type Nullable<T> = T extends string | boolean ? never : T;

export function nonNullFilter<T>(): (
  source: Observable<Nullable<T> | null>
) => Observable<NonNullable<T>> {
  return (source: Observable<T | null>) => {
    const stream = source.pipe(
      filter((v) => v !== null),
      map((v) => {
        if (v === null) {
          throw new Error('value must be non-null');
        }
        return v;
      })
    );
    return stream as Observable<NonNullable<T>>;
  };
}
