import { adaptExternalLink } from '../../../models/brand/adapt-external-link';
import { FavoriteTasteAreaItemGroup } from '../../../models/recommended/favorite-taste-area-item-group';
import {
  Recommended,
  StaticContentsType,
} from '../../../models/recommended/recommended';
import { RecommendedAreaLItemGroup } from '../../../models/recommended/recommended-area-l-item-group';
import { RecommendedAreaSItemGroup } from '../../../models/recommended/recommended-area-s-item-group';
import { RecommendedId } from '../../../models/recommended/recommended-id';
import { adaptFavoriteTasteAreaItemGroup } from '../favorite-taste-area-item-group/adapt-favorite-taste-area-item-group';
import { adaptImage } from '../image/adapt-image';
import { adaptRecommendedAreaLItemGroup } from '../recommended-area-item-group/adapt-recommended-area-l-item-group';
import { adaptRecommendedAreaSItemGroup } from '../recommended-area-item-group/adapt-recommended-area-s-item-group';
import { RecommendedResponse } from './recommended.response';

export function adaptRecommended(
  menuCategoryResponse: RecommendedResponse
): Recommended {
  let areaL: RecommendedAreaLItemGroup | null = null;
  let areaSList: RecommendedAreaSItemGroup[] | null = null;
  let areaFavoriteTaste: FavoriteTasteAreaItemGroup | null = null;

  // 商品エリア大に表示する商品
  if (menuCategoryResponse.fields.area_l) {
    areaL = adaptRecommendedAreaLItemGroup(menuCategoryResponse.fields.area_l);
  }

  // 商品エリア小に表示する商品
  if (menuCategoryResponse.fields.area_s_list) {
    areaSList = menuCategoryResponse.fields.area_s_list.map((v) => {
      return adaptRecommendedAreaSItemGroup(v);
    });
  }

  // 好みの味エリアに表示する商品
  if (menuCategoryResponse.fields.area_favorite_taste) {
    areaFavoriteTaste = adaptFavoriteTasteAreaItemGroup(
      menuCategoryResponse.fields.area_favorite_taste
    );
  }

  return {
    id: new RecommendedId(menuCategoryResponse.sys.id),
    contentType: menuCategoryResponse.sys.contentType.sys.id,
    name: menuCategoryResponse.fields.name,
    icon: adaptImage(menuCategoryResponse.fields.icon),
    areaL,
    areaSList,
    areaFavoriteTaste,
    staticContentsType:
      (menuCategoryResponse.fields
        .static_contents_type as StaticContentsType) ?? null,
    externalLink: menuCategoryResponse.fields.external_link
      ? adaptExternalLink(menuCategoryResponse.fields.external_link)
      : null,
  };
}
