import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { planFeatureName } from './plan.reducer';
import { PlanState } from './plan.state';

const selectFeature = createFeatureSelector<PlanState>(planFeatureName);

export const selectPlan = pipe(
  select(createSelector(selectFeature, (v) => v.plan))
);

export const selectPlanId = pipe(
  select(createSelector(selectFeature, ({ plan }) => plan?.id ?? null)),
  distinctUntilChanged((a, b) => {
    if (a === null && b === null) {
      return true;
    }
    if (a !== null && b !== null) {
      return a.eq(b);
    }
    return false;
  })
);

export const selectExtraMenuBookTypeId = pipe(
  select(
    createSelector(
      selectFeature,
      ({ plan }) => plan?.extraMenuBookTypeId ?? null
    )
  ),
  distinctUntilChanged((a, b) => {
    if (a === null && b === null) {
      return true;
    }
    if (a !== null && b !== null) {
      return a.eq(b);
    }
    return false;
  })
);
