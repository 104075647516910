import { BookTypeId } from '../../../models/book-type/book-type-id';
import { BookTypes } from '../../../models/book-type/book-types';
import { MenuBook } from '../../../models/menu-book/menu-book';
import { PlanId } from '../../../models/plan/plan-id';
import { adaptBookType } from '../book-type/adapt-book-type';
import { LocationResponse } from '../location/location.response';

export function isPlan(
  extraMenuBookTypeId: BookTypeId | null,
  target: BookTypeId
): boolean {
  return extraMenuBookTypeId !== null ? extraMenuBookTypeId.eq(target) : false;
}

export function adaptMenuBook(
  res: LocationResponse,
  planId: PlanId | null,
  extraMenuBookTypeId: BookTypeId | null
): MenuBook {
  const bookTypeList = res.fields.menu_book.fields.book_types.map((v) => {
    if (!isPlan(extraMenuBookTypeId, new BookTypeId(v.sys.id))) {
      return adaptBookType(v, null);
    }
    return adaptBookType(v, planId);
  });

  if (bookTypeList.length < 1) {
    throw new Error('default menu book type is not found.');
  }

  return {
    name: res.fields.menu_book.fields.name,
    bookTypes: new BookTypes(bookTypeList),
  };
}
