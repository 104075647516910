import { PrintNotificationController } from '../../../models/printer/print-notification-controller';
import { adaptPrinter } from './adapt-printer';
import { PrintNotificationControllerResponse } from './printer-notification-controller.response';

export function adaptPrintNotificationController(
  res: PrintNotificationControllerResponse
): PrintNotificationController {
  return {
    type: res.fields.type,
    printer: adaptPrinter(res.fields.printer),
    tables: res.fields.tables.map((v) => {
      return { name: v.fields.name };
    }),
  };
}
