import { PayState } from './pay.state';

export const initialState: PayState = {
  isAgreedRules: false,
  isProcessingPayByCash: false,
  isProcessingPayByCard: false,
  isProcessingNotification: false,
  isProcessingRefresh: false,
  invoiceDetail: null,
  discountDetail: null,
};
