import { createAction, union } from '@ngrx/store';

import { Banners } from '../../../../models/banner/banners';

export const storeBanners = createAction(
  'BannerActions storeBanners',
  (payload: Banners) => ({ payload })
);

const all = union({
  storeBanners,
});

export type BannerActionsUnion = typeof all;
