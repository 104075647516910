/**
 * https://firebase.google.com/docs/reference/js/firebase.FirebaseError?hl=ja
 */
interface FirebaseErrorResponse {
  code: string;
  message: string;
  name: string;
  stack?: string;
}

export class FirebaseError {
  readonly code: string;
  readonly message: string;
  readonly name: string;
  readonly stack: string;

  constructor(params: FirebaseErrorResponse) {
    this.code = params.code;
    this.message = params.message;
    this.name = params.name;
    this.stack = params.stack ?? '';
  }

  getErrorResponse(): FirebaseErrorResponse {
    return {
      code: this.code,
      message: this.message,
      name: this.name,
      stack: this.stack,
    };
  }
}
