import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Spinner } from 'spin.js';

@Component({
  selector: 'app-loading-component',
  styleUrls: ['./loading.component.scss'],
  templateUrl: './loading.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoadingComponent implements AfterViewInit {
  @ViewChild('spinner')
  spinnerRef: ElementRef | null = null;

  spinnerObj: Spinner = new Spinner({
    lines: 11, // The number of lines to draw
    length: 5, // The length of each line
    width: 3, // The line thickness
    radius: 12, // The radius of the inner circle
    scale: 0.95, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1.3, // Rounds per second
    rotate: 18, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#ffffff', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
  });

  ngAfterViewInit(): void {
    if (this.spinnerRef !== null) {
      this.spinnerObj.spin(this.spinnerRef?.nativeElement);
    }
  }
}
