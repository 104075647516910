import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { BookTypeId } from '../../../../models/book-type/book-type-id';
import { Plan } from '../../../../models/plan/plan';
import { nonNullFilter } from '../../non-null-filter';
import { locationFeatureName } from './location.reducer';
import { LocationState } from './location.state';

const selectFeature = createFeatureSelector<LocationState>(locationFeatureName);

export const selectLocationId = pipe(
  select(createSelector(selectFeature, (v) => v.locationId)),
  nonNullFilter()
);

export const selectApiLocationId = pipe(
  select(createSelector(selectFeature, (v) => v.apiLocationId)),
  nonNullFilter()
);

export const selectLocationName = pipe(
  select(createSelector(selectFeature, (v) => v.name))
);

export const selectTel = pipe(
  select(createSelector(selectFeature, (v) => v.tel))
);

export const selectMenuBook = pipe(
  select(createSelector(selectFeature, (v) => v.menuBook)),
  nonNullFilter()
);

export const selectHasBookTypeForOrderedPlan = pipe(
  select(createSelector(selectFeature, (v) => v.menuBook)),
  nonNullFilter(),
  map((v) => v.bookTypes.hasBookTypeForOrderedPlan())
);

export const selectBookTypeForOrderedPlan = pipe(
  select(createSelector(selectFeature, (v) => v.menuBook)),
  nonNullFilter(),
  map((v) => v.bookTypes.selectBookTypeForOrderedPlan())
);

// メニューブック内の全プラン
export const selectAllPlanList = pipe(
  select(createSelector(selectFeature, (v) => v.menuBook)),
  nonNullFilter(),
  map((v) => {
    return [...v.bookTypes].reduce((acc, cur) => {
      const planList = cur.categories.getPlanList();
      if (planList.length === 0) {
        return acc;
      }
      return acc.concat(planList);
    }, [] as Plan[]);
  })
);

// メニューブック内の全プランのextraMenuId
export const selectAllPlanExtraMenuIdList = pipe(
  selectAllPlanList,
  map((v) => {
    return v.reduce((acc, cur) => {
      if (cur.extraMenuBookTypeId === null) {
        return acc;
      }
      return acc.concat(cur.extraMenuBookTypeId);
    }, [] as BookTypeId[]);
  })
);

export const selectPrintItemControllers = pipe(
  select(createSelector(selectFeature, (v) => v.printItemControllers)),
  nonNullFilter()
);

export const selectPrintControllers = pipe(
  select(createSelector(selectFeature, (v) => v.printControllers)),
  nonNullFilter()
);

export const selectPrintNotificationControllers = pipe(
  select(createSelector(selectFeature, (v) => v.printNotificationControllers)),
  nonNullFilter()
);

export const selectWelcomeMessage = pipe(
  select(createSelector(selectFeature, (v) => v.welcomeMessage))
);

export const selectStaffImages = pipe(
  select(createSelector(selectFeature, (v) => v.staffImages)),
  nonNullFilter()
);

export const hasStaffImages = pipe(
  select(createSelector(selectFeature, (v) => v.staffImages)),
  map((v) => v !== null)
);

export const selectOrderCompleteBanner = pipe(
  select(createSelector(selectFeature, (v) => v.orderCompleteBanner)),
  nonNullFilter()
);

export const selectLogoImage = pipe(
  select(createSelector(selectFeature, (v) => v.logoImage)),
  nonNullFilter()
);
