import { createAction, union } from '@ngrx/store';

import { MenuItemDetail } from '../../../../models/menu-item/menu-item-detail';
import { Variation } from '../../../../models/menu-item/variation';
import { PlanDetail } from '../../../../models/plan/plan-detail';
import { SelectedModifier } from '../../modifier-bottom-sheet/components/selected-modifier';

export const storeItemDetail = createAction(
  'MenuItemActions storeItemDetail',
  (payload: MenuItemDetail | PlanDetail) => ({ payload })
);

export const clearItemDetail = createAction('MenuItemActions clearItemDetail');

export const storeSelectedVariation = createAction(
  'MenuItemActions storeSelectedVariation',
  (payload: Variation) => ({ payload })
);

export const toggleSelectedModifier = createAction(
  'MenuItemActions toggleSelectedModifier',
  (payload: SelectedModifier) => ({ payload })
);

export const incrementSelectedModifier = createAction(
  'MenuItemActions incrementSelectedModifier',
  (payload: SelectedModifier) => ({ payload })
);

export const decrementSelectedModifier = createAction(
  'MenuItemActions decrementSelectedModifier',
  (payload: SelectedModifier) => ({ payload })
);

export const clearSelectedModifier = createAction(
  'MenuItemActions clearSelectedModifier'
);

const all = union({
  storeItemDetail,
  clearItemDetail,
  storeSelectedVariation,
  toggleSelectedModifier,
  decrementSelectedModifier,
  incrementSelectedModifier,
  clearSelectedModifier,
});

export type MenuItemActionsUnion = typeof all;
