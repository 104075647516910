import { createReducer, on } from '@ngrx/store';

import {
  PayActionsUnion,
  storeAgreedRule,
  storeInvoiceDetail,
  storeInvoiceDetailAndDiscountDetail,
  storeIsProcessingNotification,
  storeIsProcessingPayByCard,
  storeIsProcessingPayByCash,
  storeIsProcessingRefresh,
} from './pay.actions';
import { initialState } from './pay.initial-state';
import { PayState } from './pay.state';

export function payReducer(
  payState: PayState,
  action: PayActionsUnion
): PayState {
  type S = PayState;
  const reducer = createReducer<S>(
    initialState,
    on(storeAgreedRule, (state): S => {
      return { ...state, isAgreedRules: true };
    }),
    on(storeIsProcessingPayByCash, (state, { payload }): S => {
      return { ...state, isProcessingPayByCash: payload };
    }),
    on(storeIsProcessingPayByCard, (state, { payload }): S => {
      return { ...state, isProcessingPayByCard: payload };
    }),
    on(storeIsProcessingNotification, (state, { payload }): S => {
      return { ...state, isProcessingNotification: payload };
    }),
    on(storeIsProcessingRefresh, (state, { payload }): S => {
      return { ...state, isProcessingRefresh: payload };
    }),
    on(storeInvoiceDetailAndDiscountDetail, (state, { payload }): S => {
      return {
        ...state,
        invoiceDetail: payload.invoiceDetail,
        discountDetail: payload.discountDetail,
      };
    }),
    on(storeInvoiceDetail, (state, { payload }): S => {
      return { ...state, invoiceDetail: payload };
    })
  );
  return reducer(payState, action);
}

export const payFeatureName = 'payFeature';
