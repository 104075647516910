import { MenuCategoryId } from '../menu-category/menu-category-id';
import { MenuItemId } from '../menu-item/menu-item-id';
import { PlanId } from '../plan/plan-id';
import { RecommendedId } from '../recommended/recommended-id';
import { BookType } from './book-type';
import { BookTypeId } from './book-type-id';

export class BookTypes {
  private firstBookType: BookType;

  constructor(private readonly list: BookType[]) {
    if (this.list[0] === undefined) {
      throw new Error(`FirstBookType is undefined.`);
    }
    this.firstBookType = this.list[0];
  }

  selectBookTypeListForCustomer(isDuringExpandMenu: boolean): BookType[] {
    return this.list.filter((v) => {
      return isDuringExpandMenu
        ? v.isVisibleDuringCourse()
        : v.isVisibleDefault();
    });
  }

  findBookTypeByBookTypeId(bookTypeId: BookTypeId): BookType {
    const found = this.list.find((v) => {
      return v.bookTypeId.eq(bookTypeId);
    });
    // 注文完了画面で表示するデータのBookTypeを登録ミスした場合発生する
    if (found === undefined) {
      throw new Error(
        `BookType is undefined. BookTypeId is ${bookTypeId.toString()}`
      );
    }
    return found;
  }

  findBookTypeByMenuItemId(itemId: MenuItemId | PlanId): BookType {
    const found = this.list.find((v) => {
      return v.hasMenuItem(itemId);
    });
    // カテゴリに属していない商品の場合発生しうる(おすすめにしか登録していない場合など)
    if (found === undefined) {
      throw new Error(
        `BookType is undefined. MenuItemId is ${itemId.toString()}`
      );
    }
    return found;
  }

  getFirstBookTypeId(): BookTypeId {
    return this.firstBookType.bookTypeId;
  }

  getFirstCategoryIdOfFirstBookType(): PlanId | RecommendedId | MenuCategoryId {
    return this.firstBookType.getFirstCategoryId();
  }

  hasBookTypeForOrderedPlan(): boolean {
    return this.list.some((v) => v.planId !== null);
  }

  selectBookTypeForOrderedPlan(): BookType | null {
    return this.list.find((v) => v.planId !== null) ?? null;
  }

  *[Symbol.iterator](): IterableIterator<BookType> {
    for (const v of this.list) {
      yield v;
    }
  }
}
