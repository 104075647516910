import { Injectable } from '@angular/core';

import { stopAnimation } from '../../cart/store/cart.actions';
import { Dispatcher } from '../../dispatcher';

@Injectable()
export class OrderListButtonCommands {
  constructor(private readonly dispatcher: Dispatcher) {}

  stopAnimation(): void {
    this.dispatcher.dispatch(stopAnimation());
  }
}
