import { Printer } from '../../../models/printer/printer';
import { PrinterId } from '../../../models/printer/printer-id';
import { PrinterResponse } from './printer.response';

export function adaptPrinter(res: PrinterResponse): Printer {
  return {
    printerId: new PrinterId(res.sys.id),
    name: res.fields.name,
    macAddress: res.fields.mac_address,
  };
}
