/**
 * @see https://github.com/toreta/mojito/blob/c706b814339d9f3bdc97a1dd3a14e53e465ebf71/resources/mojito/schema.graphql#L134
 */
export const chargeStatuses = [
  'PENDING',
  'REQUIRES_ACTION',
  'PROCESSING',
  'SUCCEEDED',
  'FAILED',
] as const;
export type ChargeStatus = (typeof chargeStatuses)[number];
