import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OrderListButtonCommands } from './components/order-list-button.commands';
import { OrderListButtonComponent } from './components/order-list-button.component';
import { OrderListButtonQueries } from './components/order-list-button.queries';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [OrderListButtonComponent],
  exports: [OrderListButtonComponent],
  providers: [OrderListButtonQueries, OrderListButtonCommands],
})
export class OrderListButtonModule {}
