import { PrintItemController } from '../../../models/printer/print-item-controller';
import { adaptPrintItemSet } from './adapt-print-item-set';
import { adaptPrinter } from './adapt-printer';
import { PrintItemControllerResponse } from './print-item-controller.response';

export function adaptPrintItemController(
  res: PrintItemControllerResponse
): PrintItemController {
  return {
    name: res.fields.name,
    printer: adaptPrinter(res.fields.printer),
    printItemSet: adaptPrintItemSet(res.fields.print_item_set),
  };
}
