import { createReducer, on } from '@ngrx/store';

import { PlanDetail } from '../../../../models/plan/plan-detail';
import { PlanActionsUnion, storePlan, storePlanTime } from './plan.actions';
import { initialState } from './plan.initial-state';
import { PlanState } from './plan.state';

export function planReducer(
  identityState: PlanState,
  action: PlanActionsUnion
): PlanState {
  type S = PlanState;
  const reducer = createReducer<S>(
    initialState,
    on(storePlan, (state, { payload }): S => {
      return { ...state, plan: payload };
    }),
    on(storePlanTime, (state, { payload }): S => {
      return {
        ...state,
        plan:
          state.plan !== null
            ? new PlanDetail({
                id: state.plan.id,
                contentType: state.plan.contentType,
                name: state.plan.name,
                namePrint: state.plan.namePrint,
                planType: state.plan.planType,
                comment: state.plan.comment,
                description: state.plan.description,
                image: state.plan.image,
                video: state.plan.video,
                stamp: state.plan.stamp,
                variations: state.plan.variations,
                startTime: payload !== null ? payload.startTime : null,
                orderStopPeriod:
                  payload !== null
                    ? payload.orderStopPeriod
                    : state.plan.orderStopPeriod,
                planEndPeriod:
                  payload !== null
                    ? payload.planEndPeriod
                    : state.plan.planEndPeriod,
                extraMenuBookTypeId: state.plan.extraMenuBookTypeId,
                planItemControllers: state.plan.planItemControllers,
                relatedItems: state.plan.relatedItems,
                story: state.plan.story,
                cmsMetadata: state.plan.cmsMetadata,
              })
            : null,
      };
    })
  );
  return reducer(identityState, action);
}

export const planFeatureName = 'planFeature';
