import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { firstValueFrom } from 'rxjs';
import { BookTypeId } from '../../../../models/book-type/book-type-id';
import { MenuCategoryId } from '../../../../models/menu-category/menu-category-id';
import { PlanId } from '../../../../models/plan/plan-id';
import { RecommendedId } from '../../../../models/recommended/recommended-id';
import { Session } from '../../../../models/session/session';
import { isTrial } from '../../../../utils/is-trial';
import { FooterMenuCommands } from './footer-menu.commands';
import { FooterMenuQueries } from './footer-menu.queries';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent implements OnInit {
  session!: Session;
  isSelectedOrderHistory = false;

  constructor(
    readonly queries: FooterMenuQueries,
    private readonly commands: FooterMenuCommands,
    private readonly router: Router
  ) {}

  ngOnInit() {
    const currentPath = this.router.url.split('?')[0]?.split('/')[1] ?? '';
    if (currentPath === 'order-history') {
      this.isSelectedOrderHistory = true;
    }
  }

  async onClickBookType(
    bookTypeId: BookTypeId,
    menuCategoryId: PlanId | RecommendedId | MenuCategoryId
  ): Promise<void> {
    await this.commands.onClickBookType();
    await this.router.navigate(['book-type'], {
      queryParamsHandling: 'merge',
      queryParams: {
        bookTypeId,
        menuCategoryId,
      },
    });
  }

  // bookTypeパラメータを引き継ぎたくないので、 `queryParamsHandling:'preserve'` は使わない
  async onClickOrderHistory(): Promise<void> {
    const session = await firstValueFrom(this.queries.session$);
    const locationId = await firstValueFrom(this.queries.locationId$);
    const apiLocationId = await firstValueFrom(this.queries.apiLocationId$);

    await this.router.navigate(['order-history'], {
      queryParams: {
        sessionId: session.getSessionId().toString(),
        locationId: apiLocationId.toString(),
        cmsLocationId: locationId.toString(),
      },
    });

    await this.commands.sendLogEventFooterOrderHistoryClicked({
      pageType: 'footer',
      pageName: 'フッター',
      areaName: '伝票/会計',
    });
  }

  canDisplayOrderHistoryNavButton(): boolean {
    return !isTrial();
  }
}
