import { BookTypeId } from '../../../models/book-type/book-type-id';
import { Plan } from '../../../models/plan/plan';
import { PlanId } from '../../../models/plan/plan-id';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { adaptImage } from '../image/adapt-image';
import { asPlanType } from './as-plan-type';
import { PlanResponse } from './plan.response';

export function adaptPlan(res: PlanResponse): Plan {
  return {
    id: new PlanId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    namePrint: res.fields.name_print,
    planType: asPlanType(res.fields.plan_type),
    image: adaptImage(res.fields.image),
    extraMenuBookTypeId: res.fields.extra_menu
      ? new BookTypeId(res.fields.extra_menu.sys.id)
      : null,
    cmsMetadata: adaptCMSMetadata(res),
  };
}
