import { SelectedModifier } from '../../modifier-bottom-sheet/components/selected-modifier';
import { SelectedModifierSetControllers } from '../../modifier-bottom-sheet/components/selected-modifier-set-controllers';

export function toggleModifier(
  selectedModifiers: SelectedModifierSetControllers | null,
  selectedModifier: SelectedModifier
): SelectedModifierSetControllers {
  // 選択済みオプションがない場合、単純に追加する
  if (selectedModifiers === null) {
    return new SelectedModifierSetControllers([
      {
        modifierSetController: selectedModifier.modifierSetController,
        modifiers: [{ modifier: selectedModifier.modifier, quantity: 1 }],
      },
    ]);
  }

  // 同じオプション選択パターンを探す
  const hasModifierSetController = selectedModifiers.hasModifierSetController(
    selectedModifier.modifierSetController.modifierSetControllerId
  );

  // 同じオプション選択パターンがない場合、単純に追加する
  if (!hasModifierSetController) {
    return selectedModifiers.addModifierSetController(selectedModifier);
  }

  // 同じオプション選択パターンがある場合
  return selectedModifiers.merge(selectedModifier);
}
