import { BookTypeId } from '../../../models/book-type/book-type-id';
import { PlanDetail } from '../../../models/plan/plan-detail';
import { PlanId } from '../../../models/plan/plan-id';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { adaptImage } from '../image/adapt-image';
import { adaptRecommendedComment } from '../recommended-comment/adapt-recommended-comment';
import { adaptRelatedItems } from '../related-item/adapt-related-items';
import { adaptStamp } from '../stamp/adapt-stamp';
import { adaptStory } from '../story/adapt-story';
import { adaptVariation } from '../variation/adapt-variation';
import { adaptVideo } from '../video/adapt-video';
import { adaptPlanItemController } from './adapt-plan-item-controller';
import { asPlanType } from './as-plan-type';
import { PlanResponse } from './plan.response';

export function adaptPlanDetail(res: PlanResponse): PlanDetail {
  const planItemControllers = res.fields.plan_item_controllers
    ? res.fields.plan_item_controllers.map((v) => adaptPlanItemController(v))
    : null; // planItemControllers がない時は null を渡す
  return new PlanDetail({
    id: new PlanId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    namePrint: res.fields.name_print,
    planType: asPlanType(res.fields.plan_type),
    comment: res.fields.comment
      ? adaptRecommendedComment(res.fields.comment)
      : null,
    description: res.fields.description ?? '',
    image: adaptImage(res.fields.image),
    video: res.fields.video ? adaptVideo(res.fields.video) : null,
    stamp: res.fields.stamp ? adaptStamp(res.fields.stamp) : null,
    variations: res.fields.variations.map((v) => adaptVariation(v)),
    startTime: null,
    orderStopPeriod: res.fields.order_stop_period,
    planEndPeriod: res.fields.plan_end_period,
    extraMenuBookTypeId: res.fields.extra_menu
      ? new BookTypeId(res.fields.extra_menu.sys.id)
      : null,
    planItemControllers,
    relatedItems: res.fields.related_items
      ? adaptRelatedItems(res.fields.related_items)
      : null,
    story: res.fields.story ? adaptStory(res.fields.story) : null,
    cmsMetadata: adaptCMSMetadata(res),
  });
}
