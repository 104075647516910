import { OrderHistoryDetails } from '../../../models/order/order-history-details';
import { adaptOrderHistoryDetail } from './adapt-order-history-detail';
import { OrderHistoryResponse } from './order-history.response';

export function adaptOrderHistoryDetails(
  res: OrderHistoryResponse[]
): OrderHistoryDetails {
  const orderHistoryList = res.map((v) => adaptOrderHistoryDetail(v)) ?? [];
  return new OrderHistoryDetails(orderHistoryList);
}
