import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { loadingFeatureName } from './loading.reducer';
import { LoadingState } from './loading.state';

const selectFeature = createFeatureSelector<LoadingState>(loadingFeatureName);

export const selectIsLoading = pipe(
  select(
    createSelector(selectFeature, (v) => {
      return [v.isBooting, v.isLoading].some((v) => v);
    })
  ),
  distinctUntilChanged()
);
