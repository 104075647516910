import { environment } from '../../../environments/environment';
import { isHttpError } from '../../lib/cocktail/is-http-error';
import { isContentfulError } from '../../lib/contentful/is-contentful-error';
import { isFirebaseError } from '../../lib/firestore/is-firebase-error';
import { isWorkflowError } from '../../models/error/worlflow-error';
import { isError } from './is-error';

export function trackError(e: unknown): void {
  if (!environment.production && !environment.staging) {
    console.error(e);
    return;
  }

  // 本番環境とステージング環境の場合、Sentryにエラーログを送信する
  try {
    // @see https://github.com/segmentio/analytics.js-integrations/blob/7a6bca08e0a46ae4c2d2b327ca0cf77c4ad872a3/integrations/sentry/test/index.test.js#L111
    window.Sentry.captureException(e);
  } catch (e) {
    // Sentryが読み込まれていない場合は、その旨をSegmentへ送信する
    window.analytics.track('Error', {
      errorType: e.name,
      response: {
        name: e.name,
        message: e.message,
        stack: e.stack,
      },
    });
  }

  // 本番環境とステージング環境の場合、Segmentにエラーログを送信する
  if (isFirebaseError(e)) {
    window.analytics.track('Error', {
      errorType: 'Firebase Error',
      response: e.getErrorResponse(),
    });
    return;
  }
  if (isContentfulError(e)) {
    window.analytics.track('Error', {
      errorType: 'Contentful Error',
      response: e.getErrorResponse(),
    });
    return;
  }
  if (isWorkflowError(e)) {
    window.analytics.track('Error', {
      errorType: 'Workflow Error',
      response: e.getErrorResponse(),
    });
    return;
  }
  if (isHttpError(e)) {
    window.analytics.track('Error', {
      errorType: 'Http Error',
      response: e,
    });
    return;
  }
  if (isError(e)) {
    window.analytics.track('Error', {
      errorType: e.name,
      response: {
        name: e.name,
        message: e.message,
        stack: e.stack,
      },
    });
    return;
  }
  // どれにも該当しない場合
  window.analytics.track('Error', {
    errorType: e,
    response: e,
  });
}
