import { MenuItemGroupResponse } from '../menu-item-group/menu-item-group.response';
import { MenuItemResponse } from '../menu-item/menu-item.response';
import { ContentTypeIds } from '../recommended-area-item-group/content-type-ids';
import { PlanExtensionResponse } from './plan-extension.response';
import { PlanResponse } from './plan.response';

export function isPlanResponse(
  arg:
    | MenuItemResponse
    | MenuItemGroupResponse
    | PlanResponse
    | PlanExtensionResponse
): arg is PlanResponse {
  return arg.sys.contentType.sys.id === ContentTypeIds.plan;
}
