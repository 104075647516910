import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { cartFeatureName } from './cart.reducer';
import { CartState } from './cart.state';

const selectFeature = createFeatureSelector<CartState>(cartFeatureName);

export const selectCartItems = pipe(
  select(createSelector(selectFeature, (v) => v.cartItems))
);

export const selectIsStartAnimation = pipe(
  select(createSelector(selectFeature, (v) => v.isStartAnimation))
);
