import { Discount } from '../../../models/discount/discount';
import { DiscountId } from '../../../models/discount/discount-id';
import { DiscountType } from './discount-type';
import { DiscountResponse } from './identity.response';

export function adaptDiscount(res: DiscountResponse): Discount {
  return {
    id: new DiscountId(res.id),
    name: res.name.forDisplay,
    type: res.type as DiscountType,
    amount: res.amount ? Number(res.amount) : 0,
  };
}
