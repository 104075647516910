import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { interval, Subject, timer, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppCommands } from './app.commands';
import { AppQueries } from './app.queries';
import { trackError } from './pages/shared/track-error';
import { exists } from './utils/exists';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    readonly queries: AppQueries,
    private readonly commands: AppCommands,
    private readonly title: Title,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  async ngOnInit(): Promise<void> {
    const ids = await firstValueFrom(this.queries.ids$);
    if (ids === null) {
      await this.commands.urlError();
      return;
    }
    this.commands.init(ids);

    this.queries.title$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.setTitle(v);
    });

    this.queries.isPreparing$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.commands.preparing();
    });

    this.queries.isOrderStart$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      try {
        this.commands.orderStart();
      } catch (e) {
        trackError(e);
      }
    });

    this.queries.isOrderStop$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      try {
        this.commands.orderStop();
      } catch (e) {
        trackError(e);
      }
    });

    this.queries.isLeaving$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      try {
        this.commands.leaving();
      } catch (e) {
        trackError(e);
      }
    });

    this.queries.isReceivable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isReceivable) => {
        if (!isReceivable) {
          return;
        }
        try {
          this.commands.receivable();
        } catch (e) {
          trackError(e);
        }
      });

    /**
     * @TODO 1分ごとにポーリングする。暫定対応。
     * https://toreta.esa.io/posts/24197#comment-1923139
     * https://toreta.slack.com/archives/C01G8QSKVD5/p1623812505430900
     */
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          if (ids === null) {
            this.commands.urlError();
            return;
          }
          this.commands.pollingEvents(ids);
        },
        (e) => {
          trackError(e);
        }
      );

    this.prepareFetchNow();
    this.prepareUpdate();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private prepareFetchNow(): void {
    timer(0, 60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        await this.commands.pollingNow();
      });
  }

  private prepareUpdate(): void {
    this.queries.orderHistories$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        const session = await firstValueFrom(this.queries.session$);
        const identity = await firstValueFrom(this.queries.identity$);
        const apiLocationId = await firstValueFrom(this.queries.apiLocationId$);
        await this.commands.updateIdentityDetail(
          session.getSessionId(),
          identity.id,
          apiLocationId
        );

        // プラン時間更新
        const extraMenuBookTypeId = await firstValueFrom(
          this.queries.extraMenuBookTypeId$
        );
        if (!exists(extraMenuBookTypeId)) {
          return;
        }
        const identityDetail = await firstValueFrom(
          this.queries.identityDetail$
        );
        await this.commands.updatePlanTime(identityDetail);
      });
  }

  private setTitle(v: string): void {
    if (v === '') {
      return;
    }
    this.title.setTitle(v);
  }
}
