import { createReducer, on } from '@ngrx/store';

import {
  SpecialActionsUnion,
  clearSpecial,
  storeSpecial,
} from './special.actions';
import { initialState } from './special.initial-state';
import { SpecialState } from './special.state';

export function specialReducer(
  specialState: SpecialState,
  action: SpecialActionsUnion
): SpecialState {
  type S = SpecialState;
  const reducer = createReducer<S>(
    initialState,
    on(storeSpecial, (state, { payload }): S => {
      return { ...state, special: payload };
    }),
    on(clearSpecial, (state): S => {
      return { ...state, special: null };
    })
  );
  return reducer(specialState, action);
}

export const specialFeatureName = 'specialFeature';
