import { Injectable } from '@angular/core';

import { ContentfulClient } from '../../lib/contentful/contentful-client';
import { ContentfulError } from '../../lib/contentful/contentful-error';
import { adaptLocation } from '../../lib/contentful/location/adapt-location';
import { LocationResponse } from '../../lib/contentful/location/location.response';
import { adaptMenuItemDetail } from '../../lib/contentful/menu-item/adapt-menu-item-detail';
import { MenuItemResponse } from '../../lib/contentful/menu-item/menu-item.response';
import { adaptSpecial } from '../../lib/contentful/special/adapt-special';
import { SpecialResponse } from '../../lib/contentful/special/special.response';
import { BookTypeId } from '../book-type/book-type-id';
import { MenuItemDetail } from '../menu-item/menu-item-detail';
import { MenuItemId } from '../menu-item/menu-item-id';
import { PlanId } from '../plan/plan-id';
import { Special } from '../special/special';
import { SpecialId } from '../special/special-id';
import { ApiLocationId } from './api-location-id';
import { LocationId } from './location-id';
import { LocationOfContentful } from './location-of-contentful';

/**
 * @TODO RepositoryとClientの見直し #320
 * https://github.com/toreta/toreta-ap-ox-app/issues/320
 */
@Injectable({ providedIn: 'root' })
export class LocationRepository {
  constructor(private readonly client: ContentfulClient) {}

  async getLocation(
    locationId: LocationId,
    planId: PlanId | null,
    extraMenuBookTypeId: BookTypeId | null,
    apiLocationId: ApiLocationId
  ): Promise<LocationOfContentful> {
    let res: LocationResponse | null = null;
    try {
      res = await this.client.listLocation(locationId, apiLocationId);
    } catch (e) {
      throw new ContentfulError(e);
    }
    return adaptLocation(res, planId, extraMenuBookTypeId);
  }

  async getMenuItemDetail(
    menuItemId: MenuItemId,
    apiLocationId: ApiLocationId
  ): Promise<MenuItemDetail> {
    let res: MenuItemResponse | null = null;
    try {
      res = await this.client.listMenuItem(menuItemId, apiLocationId);
    } catch (e) {
      throw new ContentfulError(e);
    }
    return adaptMenuItemDetail(res);
  }

  async getSpecial(
    specialId: SpecialId,
    apiLocationId: ApiLocationId
  ): Promise<Special> {
    let res: SpecialResponse | null = null;
    try {
      res = await this.client.listSpecial(specialId, apiLocationId);
    } catch (e) {
      throw new ContentfulError(e);
    }
    return adaptSpecial(res);
  }
}
