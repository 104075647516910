import { createReducer, on } from '@ngrx/store';

import { isAddresseeValid } from './is-addressee-valid';
import { isEmailValid } from './is-email-valid';
import {
  blurAddressee,
  blurEmail,
  enterAddressee,
  enterEmail,
  ReceiptActions,
  storeIsProcessing,
} from './receipt.actions';
import { initialState } from './receipt.initial-state';
import { ReceiptState } from './receipt.state';

export function receiptReducer(
  payState: ReceiptState,
  action: ReceiptActions
): ReceiptState {
  type S = ReceiptState;
  const reducer = createReducer<S>(
    initialState,
    on(enterEmail, (state, { payload }): S => {
      return { ...state, enteredEmail: payload };
    }),
    on(blurEmail, (state): S => {
      return {
        ...state,
        isEmailValid: isEmailValid(state.enteredEmail),
        isEmailTouched: true,
      };
    }),
    on(enterAddressee, (state, { payload }): S => {
      return { ...state, enteredAddressee: payload };
    }),
    on(blurAddressee, (state): S => {
      return {
        ...state,
        isAddresseeValid: isAddresseeValid(state.enteredAddressee),
        isAddresseeTouched: true,
      };
    }),
    on(storeIsProcessing, (state, { payload }): S => {
      return { ...state, isProcessing: payload };
    })
  );
  return reducer(payState, action);
}

export const receiptFeatureName = 'receiptFeature';
