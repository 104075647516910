import { Injectable } from '@angular/core';

import { BrandResponse } from '../../lib/contentful/brand/brand.response';
import { ContentfulClient } from '../../lib/contentful/contentful-client';
import { ContentfulError } from '../../lib/contentful/contentful-error';
import { ApiLocationId } from '../location/api-location-id';
import { adaptBrand } from './adapt-brand';
import { Brand } from './brand';

@Injectable({ providedIn: 'root' })
export class BrandRepository {
  constructor(private readonly client: ContentfulClient) {}

  async getBrand(apiLocationId: ApiLocationId): Promise<Brand> {
    let res: BrandResponse | null = null;
    try {
      res = await this.client.listBrand(apiLocationId);
    } catch (e) {
      throw new ContentfulError(e);
    }
    return adaptBrand(res);
  }
}
