import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { State, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import {
  filter,
  map,
  share,
  shareReplay,
  withLatestFrom,
} from 'rxjs/operators';

import { AccountIds } from './models/accounts/account-ids';
import { BookTypeId } from './models/book-type/book-type-id';
import { Identity, IdentityDetail } from './models/identity/identity';
import { ApiLocationId } from './models/location/api-location-id';
import { LocationId } from './models/location/location-id';
import { MenuBook } from './models/menu-book/menu-book';
import { OrderHistories } from './models/order/order-histories';
import { PlanId } from './models/plan/plan-id';
import { Session } from './models/session/session';
import { SessionId } from './models/session/session-id';
import {
  selectAccountIds,
  selectReceivableAccountId,
} from './pages/shared/account-ids/store/account-ids.slectors';
import { selectBrand } from './pages/shared/brand/store/brand.selectors';
import {
  selectIdentity,
  selectIdentityDetail,
  selectIsLeaving,
  selectIsOrderStart,
  selectIsOrderStop,
  selectIsPreparing,
  selectOrderHistories,
} from './pages/shared/identity/store/identity.selectors';
import { selectIsLoading } from './pages/shared/loading/store/loading.selector';
import {
  hasStaffImages,
  selectApiLocationId,
  selectLocationId,
  selectMenuBook,
} from './pages/shared/location/store/location.selectors';
import { makeMenuBookForCustomer } from './pages/shared/menuBook/make-menu-book-for-customer';
import { selectIsNestSupported } from './pages/shared/nest/store/is-nest-supported.slectors';
import { nonNullFilter } from './pages/shared/non-null-filter';
import { selectNow } from './pages/shared/now/store/now.selectors';
import {
  selectExtraMenuBookTypeId,
  selectPlan,
  selectPlanId,
} from './pages/shared/plan/store/plan.selectors';
import { sessionIdQueryKey } from './pages/shared/session/session-id-query-key';
import { selectSession } from './pages/shared/session/store/session.selectors';

export interface Ids {
  sessionId: SessionId;
  locationId: LocationId;
  apiLocationId: ApiLocationId;
}

@Injectable()
export class AppQueries {
  readonly ids$: Observable<Ids | null>;
  readonly locationId$: Observable<LocationId>;
  readonly apiLocationId$: Observable<ApiLocationId>;
  readonly sessionId$: Observable<SessionId | null>;
  readonly session$: Observable<Session>;
  readonly isPreparing$: Observable<boolean>;
  readonly isOrderStart$: Observable<boolean>;
  readonly isOrderStop$: Observable<boolean>;
  readonly isLeaving$: Observable<boolean>;
  readonly hasStaffImages$: Observable<boolean>;
  readonly identity$: Observable<Identity>;
  readonly identityDetail$: Observable<IdentityDetail>;
  readonly planId$: Observable<PlanId | null>;
  readonly extraMenuBookTypeId$: Observable<BookTypeId | null>;
  readonly menuBookForCustomer$: Observable<MenuBook>;
  readonly allMenuBook$: Observable<MenuBook>;
  readonly orderHistories$: Observable<OrderHistories>;
  readonly isLoading$: Observable<boolean>;
  readonly title$: Observable<string>;
  readonly isNestSupported$: Observable<boolean>;
  readonly accountIds$: Observable<AccountIds>;
  readonly isReceivable$: Observable<boolean>;

  constructor(store: Store<State<any>>, router: Router, route: ActivatedRoute) {
    const navigationEnd$ = router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      map((ev) => ev as NavigationEnd),
      share() // スコープが constructor に閉じているので shareReplay(1) ではない
    );

    this.ids$ = navigationEnd$.pipe(
      withLatestFrom(route.queryParamMap),
      map(([_, params]) => {
        const sessionId = params.get('sessionId');
        const locationId = params.get('cmsLocationId');
        const apiLocationId = params.get('locationId');
        if (
          sessionId === null ||
          locationId === null ||
          apiLocationId === null
        ) {
          return null;
        } else {
          return {
            sessionId: new SessionId(sessionId),
            locationId: new LocationId(locationId),
            apiLocationId: new ApiLocationId(apiLocationId),
          };
        }
      })
    );

    this.locationId$ = store.pipe(selectLocationId, shareReplay(1));

    this.apiLocationId$ = store.pipe(selectApiLocationId, shareReplay(1));

    this.sessionId$ = navigationEnd$.pipe(
      withLatestFrom(route.queryParams),
      map(([_, params]) => {
        const sessionId = params[sessionIdQueryKey];
        return sessionId ? new SessionId(sessionId) : null;
      })
    );

    this.session$ = store.pipe(selectSession, nonNullFilter(), shareReplay(1));

    this.isPreparing$ = store.pipe(selectIsPreparing, shareReplay(1));

    this.isOrderStart$ = store.pipe(selectIsOrderStart, shareReplay(1));

    this.isOrderStop$ = store.pipe(selectIsOrderStop, shareReplay(1));

    this.isLeaving$ = store.pipe(selectIsLeaving, shareReplay(1));

    this.hasStaffImages$ = store.pipe(hasStaffImages, shareReplay(1));

    this.identity$ = store.pipe(selectIdentity, shareReplay(1));

    this.identityDetail$ = store.pipe(selectIdentityDetail, shareReplay(1));

    this.planId$ = store.pipe(selectPlanId, shareReplay(1));

    this.extraMenuBookTypeId$ = store.pipe(
      selectExtraMenuBookTypeId,
      shareReplay(1)
    );

    this.menuBookForCustomer$ = combineLatest([
      store.pipe(selectMenuBook, shareReplay(1)),
      store.pipe(selectPlan, shareReplay(1)),
      store.pipe(selectNow, shareReplay(1)),
    ]).pipe(
      map(([manuBook, plan, now]) =>
        makeMenuBookForCustomer(manuBook, plan, now)
      ),
      shareReplay(1)
    );

    this.allMenuBook$ = store.pipe(selectMenuBook, shareReplay(1));

    this.orderHistories$ = store.pipe(selectOrderHistories, shareReplay(1));

    this.isLoading$ = store.pipe(selectIsLoading, shareReplay(1));

    this.title$ = store.pipe(
      selectBrand,
      map((v) => v.title),
      shareReplay(1)
    );

    this.isNestSupported$ = store.pipe(selectIsNestSupported, shareReplay(1));

    this.accountIds$ = store.pipe(selectAccountIds, shareReplay(1));

    this.isReceivable$ = combineLatest([
      store.pipe(selectIdentityDetail, shareReplay(1)),
      store.pipe(selectReceivableAccountId, shareReplay(1)),
    ]).pipe(
      filter(
        ([identityDetail, receivableAccountId]) =>
          identityDetail !== null && receivableAccountId !== null
      ),
      map(([identityDetail, receivableAccountId]) => {
        if (receivableAccountId === undefined) {
          return false;
        }
        return identityDetail.getIsReceivable(receivableAccountId);
      })
    );
  }
}
