import { createReducer, on } from '@ngrx/store';

import {
  LoadingActionsUnion,
  startBooting,
  startLoading,
  stopBooting,
  stopLoading,
} from './loading.actions';
import { initialState } from './loading.initial-state';
import { LoadingState } from './loading.state';

export function loadingReducer(
  loadingState: LoadingState,
  action: LoadingActionsUnion
): LoadingState {
  type S = LoadingState;
  const reducer = createReducer<S>(
    initialState,
    on(startBooting, (state): S => {
      return { ...state, isBooting: true };
    }),
    on(stopBooting, (state): S => {
      return { ...state, isBooting: false };
    }),
    on(startLoading, (state): S => {
      return { ...state, isLoading: true };
    }),
    on(stopLoading, (state): S => {
      return { ...state, isLoading: false };
    })
  );
  return reducer(loadingState, action);
}

export const loadingFeatureName = 'loadingFeature';
