import { Injectable } from '@angular/core';

import { CocktailClient } from '../../lib/cocktail/cocktail-client';
import { adaptSession } from '../../lib/cocktail/session/adapt-session';
import { ApiLocationId } from '../location/api-location-id';
import { Session } from './session';
import { SessionId } from './session-id';

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  constructor(private readonly cocktailClient: CocktailClient) {}

  async getSession(
    sessionId: SessionId,
    apiLocationId: ApiLocationId
  ): Promise<Session> {
    const sessionResponse = await this.cocktailClient.getSession(
      sessionId,
      apiLocationId
    );
    return adaptSession(sessionResponse);
  }
}
