import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiLocationId } from '../../models/location/api-location-id';
import { SessionId } from '../../models/session/session-id';
import { retryByErrorStatus } from '../../pages/shared/retry-by-error-status/retry-by-error-status';
import { GetRequestChargesState } from './charge/get-request-charges-state.request';
import { GetRequestChargesStateResponse } from './charge/get-request-charges-state.response';
import { GetOrdersResponse } from './identity/get-orders.response';
import { GetTimestampsResponse } from './identity/get-timestamps.response';
import { IdentitiesRequest } from './identity/identities.request';
import { GetIdentitiesResponse } from './identity/identities.response';
import { GetIdentityRequest } from './identity/identity.request';
import { PostIdentityEndRequest } from './identity/post-identity-end.request';
import { GetDiscountsRequest } from './invoice/get-discounts.request';
import { GetDiscountsResponse } from './invoice/get-discounts.response';
import { GetRequestPayByCardWorkflowState } from './invoice/get-invoice-request-pay-by-card-workflow-state.request';
import { GetInvoiceRequestPayByCardWorkflowStateResponse } from './invoice/get-invoice-request-pay-by-card-workflow-state.response';
import { GetInvoiceRequest } from './invoice/get-invoice.request';
import { GetInvoiceResponse } from './invoice/get-invoice.response';
import { PostInvoiceRequest } from './invoice/post-invoice.request';
import { PostInvoiceResponse } from './invoice/post-invoice.response';
import { PostRequestChargeRequest } from './invoice/post-request-charge.request';
import { PostRequestChargeResponse } from './invoice/post-request-charge.response';
import { PostRequestPayByCardRequest } from './invoice/post-request-pay-by-card.request';
import { PostRequestPayByCardResponse } from './invoice/post-request-pay-by-card.response';
import { PostSendReceiptRequest } from './invoice/post-send-receipt.request';
import { PostOrderStopRequest } from './order/post-order-stop.request';
import { PostOrdersRequest } from './order/post-orders.request';
import { PostOrdersResponse } from './order/post-orders.response';
import { Response } from './response';
import { PostSendNotificationRequest } from './session/post-send-notification.request';
import { SessionResponse } from './session/session.response';

@Injectable({ providedIn: 'root' })
export class CocktailClient {
  private readonly cocktailErrorListToRetry = [502, 504];
  private readonly processingErrorListToRetry = [408, 429, 502, 504];
  //Mojitoが5回リトライするので5回にしています。
  private readonly cocktailErrorRetryCount = 5;
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * session取得
   */
  async getSession(
    sessionId: SessionId,
    apiLocationId: ApiLocationId
  ): Promise<SessionResponse> {
    const params = new HttpParams().set('locationId', apiLocationId.toString());
    return await firstValueFrom(
      this.httpClient
        .get<SessionResponse>(`api/sessions/${sessionId.toString()}`, {
          params,
        })
        .pipe(
          retryByErrorStatus<SessionResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * identities取得
   */
  async getIdentities(
    request: IdentitiesRequest
  ): Promise<GetIdentitiesResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetIdentitiesResponse>(
          `api/sessions/${request.sessionId.toString()}/identities`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetIdentitiesResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * orders取得
   */
  async getOrders(request: GetIdentityRequest): Promise<GetOrdersResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetOrdersResponse>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/orders`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetOrdersResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * timestamps取得
   */
  async getTimestamps(
    request: GetIdentityRequest
  ): Promise<GetTimestampsResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetTimestampsResponse>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/timestamps`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetTimestampsResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * オーダーリクエスト送信
   */
  async postOrders(request: PostOrdersRequest): Promise<PostOrdersResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return await firstValueFrom(
      this.httpClient
        .post<PostOrdersResponse>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/orders`,
          {
            locationId: request.locationId.toString(),
            items: request.items,
            targetDevices: request.targetDevices,
            debitAccountId:
              request.unconfirmedTradeAccountsReceivableAccountId?.toString(), //@TODO AP-1058 オプショナルを取り除く
            creditAccountId: request.salesAccountId?.toString(), //@TODO AP-1058 オプショナルを取り除く
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<PostOrdersResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * オーダーストップ
   */
  async postOrderStop(request: PostOrderStopRequest): Promise<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return await firstValueFrom(
      this.httpClient
        .post<Response>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/order-stop`,
          {
            locationId: request.locationId.toString(),
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<Response>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * 請求額確定
   */
  async postInvoice(request: PostInvoiceRequest): Promise<PostInvoiceResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    const from = request.from.map((v) => v.toString());
    return await firstValueFrom(
      this.httpClient
        .post<PostInvoiceResponse>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/invoices`,
          {
            locationId: request.locationId.toString(),
            receivableDebitAccountId:
              request.tradeAccountsReceivableAccountId?.toString(), //@TODO AP-1058 オプショナルを取り除く
            discountsDebitAccountId:
              request.salesDiscountsAccountId?.toString(), //@TODO AP-1058 オプショナルを取り除く
            creditAccountId:
              request.unconfirmedTradeAccountsReceivableAccountId?.toString(), //@TODO AP-1058 オプショナルを取り除く
            from,
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<PostInvoiceResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * クレジットカード決済のワークフローを開始する
   * 失敗時リトライする
   */
  async postRequestPayByCard(
    request: PostRequestPayByCardRequest
  ): Promise<PostRequestPayByCardResponse> {
    const retryCount = 3;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return await firstValueFrom(
      this.httpClient
        .post<PostRequestPayByCardResponse>(
          `api/invoices/${request.invoiceId.toString()}/request-pay-by-card`,
          {
            locationId: request.locationId.toString(),
            paymentToken: request.paymentToken,
            returnURL: request.returnURL,
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<PostRequestPayByCardResponse>(
            retryCount,
            this.processingErrorListToRetry
          )
        )
    );
  }
  /**
   * クレジットカード決済のワークフローを開始する
   * 失敗時リトライする
   */
  async postRequestCharge(
    request: PostRequestChargeRequest
  ): Promise<PostRequestChargeResponse> {
    const retryCount = 3;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return await firstValueFrom(
      this.httpClient
        .post<PostRequestChargeResponse>(
          `api/invoices/${request.invoiceId.toString()}/charges`,
          {
            locationId: request.locationId.toString(),
            paymentToken: request.paymentToken,
            debitAccountId:
              request.stripeTradeAccountsReceivableAccountId.toString(),
            creditAccountId:
              request.tradeAccountsReceivableAccountId.toString(),
            returnUrl: request.returnURL,
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<PostRequestChargeResponse>(
            retryCount,
            this.processingErrorListToRetry
          )
        )
    );
  }

  /**
   * クレジットカード決済のワークフローの状態を取得する
   */
  async getRequestPayByCardWorkflowState(
    request: GetRequestPayByCardWorkflowState
  ): Promise<GetInvoiceRequestPayByCardWorkflowStateResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetInvoiceRequestPayByCardWorkflowStateResponse>(
          `api/invoices/${request.invoiceId.toString()}/request-pay-by-card-workflow-state/${request.workflowExecutionId.toString()}`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetInvoiceRequestPayByCardWorkflowStateResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * end identity
   * 失敗時リトライする
   */
  async postEndIdentity(request: PostIdentityEndRequest): Promise<Response> {
    const retryCount = 5;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return await firstValueFrom(
      this.httpClient
        .post<Response>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}/end`,
          {
            locationId: request.locationId.toString(),
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<Response>(
            retryCount,
            this.processingErrorListToRetry
          )
        )
    );
  }

  /**
   * プリンタに通知する
   */
  async postSendNotification(
    request: PostSendNotificationRequest
  ): Promise<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return firstValueFrom(
      this.httpClient
        .post<Response>(
          `api/sessions/${request.sessionId}/send-notification`,
          {
            locationId: request.locationId.toString(),
            targetDevices: request.targetDevices,
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<Response>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * 領収書メールを送信する
   */
  async postSendReceipt(request: PostSendReceiptRequest): Promise<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-toreta-idempotency-key': `toreta-ap-ox-app-${request.cuid}`,
      }),
    };
    return firstValueFrom(
      this.httpClient
        .post<Response>(
          `api/invoices/${request.invoiceId}/receipt`,
          {
            locationId: request.locationId.toString(),
            mailAddress: request.mailAddress,
            name: request.name,
          },
          httpOptions
        )
        .pipe(
          retryByErrorStatus<Response>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * invoice取得
   */
  async getInvoices(request: GetInvoiceRequest): Promise<GetInvoiceResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetInvoiceResponse>(
          `api/invoices/${request.invoiceId.toString()}`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetInvoiceResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * discounts取得
   */
  async getDiscounts(
    request: GetDiscountsRequest
  ): Promise<GetDiscountsResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetDiscountsResponse>(
          `api/invoices/${request.invoiceId.toString()}/discounts`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetDiscountsResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }

  /**
   * 決済の状態を取得する
   */
  async getRequestChargesState(
    request: GetRequestChargesState
  ): Promise<GetRequestChargesStateResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.httpClient
        .get<GetRequestChargesStateResponse>(
          `api/charges/${request.chargeId}/state`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetRequestChargesStateResponse>(
            this.cocktailErrorRetryCount,
            this.cocktailErrorListToRetry
          )
        )
    );
  }
}
