import { createReducer, on } from '@ngrx/store';

import { BrandActionsUnion, storeBrand } from './brand.actions';
import { initialState } from './brand.initial-state';
import { BrandState } from './brand.state';

export function brandReducer(
  brandState: BrandState,
  action: BrandActionsUnion
): BrandState {
  type S = BrandState;
  const reducer = createReducer<S>(
    initialState,
    on(storeBrand, (state, { payload }): S => {
      return {
        ...state,
        brand: payload,
      };
    })
  );
  return reducer(brandState, action);
}

export const brandFeatureName = 'brandFeature';
