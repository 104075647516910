import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FirebaseError } from '../../lib/firestore/firebase-error';
import { adaptSoldOuts } from '../../lib/firestore/sold-out/adapt-sold-outs';
import { SoldOutClient } from '../../lib/firestore/sold-out/sold-out-client';
import { ApiLocationId } from '../location/api-location-id';
import { getEmptySoldOuts } from './get-empty-sold-outs';
import { SoldOuts } from './sold-outs';

@Injectable({ providedIn: 'root' })
export class SoldOutRepository {
  constructor(private readonly client: SoldOutClient) {}

  listenSoldOuts(apiLocationId: ApiLocationId): Observable<SoldOuts> {
    try {
      return this.client.listSoldOut(apiLocationId).pipe(
        map((v) => {
          if (v === undefined) {
            return getEmptySoldOuts();
          }
          return adaptSoldOuts(v);
        })
      );
    } catch (e) {
      throw new FirebaseError(e);
    }
  }
}
