import { assertChargeState } from '../../../models/charge/assert-charge-state';
import { Charge } from '../../../models/charge/charge';
import { ChargeId } from '../../../models/charge/charge-id';
import { assertExists } from '../../../utils/exists';
import { ChargeResponse } from './identity.response';

export function adaptCharge(v: ChargeResponse): Charge {
  assertChargeState(v.state);
  assertExists(v.metadata, 'charge.metadata');
  return {
    id: new ChargeId(v.id),
    metadata: v.metadata,
    status: v.state,
  };
}
