import { ModifierSet } from '../../../models/menu-item/modifier-set';
import { adaptModifiers } from './adapt-modifiers';
import { ModifierSetResponse } from './modifier-set.response';

export function adaptModifierSet(res: ModifierSetResponse): ModifierSet {
  return {
    name: res.fields.name,
    modifiers: adaptModifiers(res.fields.modifiers),
  };
}
