import { createAction, union } from '@ngrx/store';
import { ChargesStateErrorType } from '../charges-state-error.type';

export const storeChargesStateErrorType = createAction(
  'ChargesProcessingActions storeChargesErrorType',
  (payload: ChargesStateErrorType | null) => ({ payload })
);

const all = union({
  storeChargesStateErrorType,
});

export type ChargesProcessingActions = typeof all;
