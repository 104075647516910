import { PlanId } from '../plan/plan-id';
import { OrderHistoryDetail } from './order-history-detail';

export class OrderHistoryDetails {
  constructor(private list: OrderHistoryDetail[]) {}

  hasValidOrderItems(): boolean {
    return this.list.some((v) => v.hasValidOrderItems());
  }

  // 注文日時の昇順で並べ替え
  getSortedListByCreateTime(): OrderHistoryDetail[] {
    return this.list.slice().sort((a, b) => {
      return a.createTime.getTime() - b.createTime.getTime();
    });
  }

  getFirstOrderPlanId(): PlanId | null {
    const ids = this.getSortedListByCreateTime().reduce((acc, cur) => {
      if (!cur.items.exists()) {
        return acc;
      }
      const tmp: PlanId[] = [...cur.items]
        .filter((v) => v.isPlan() && v.exists())
        .map((v) => new PlanId(v.metadata?.id ?? ''));

      if (tmp.length === 0) {
        return acc;
      }

      return [...acc, ...tmp];
    }, [] as PlanId[]);

    if (ids[0] === undefined) {
      return null;
    }
    return ids[0];
  }

  count(): number {
    return this.list.length;
  }

  *[Symbol.iterator](): IterableIterator<OrderHistoryDetail> {
    for (const v of this.list) {
      yield v;
    }
  }
}
