import { Injectable } from '@angular/core';
import { Action, State, Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class Dispatcher {
  constructor(private readonly store: Store<State<any>>) {}

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
