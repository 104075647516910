import { Injectable } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CartItem } from '../../../../models/cart-item/cart-item';
import {
  selectCartItems,
  selectIsStartAnimation,
} from '../../cart/store/cart.selectors';
import {
  selectAllPlanExtraMenuIdList,
  selectMenuBook,
} from '../../location/store/location.selectors';
import { selectNow } from '../../now/store/now.selectors';
import { makeAllPlansItemIdList } from '../../plan/make-all-plans-item-id-list';
import { makeIsCartItemOrderStopFunction } from '../../plan/make-is-cart-item-order-stop-function';
import { selectPlan } from '../../plan/store/plan.selectors';
import { makeIsCartItemSoldOutFunction } from '../../sold-out/make-is-cart-item-sold-out-function';
import { selectSoldOuts } from '../../sold-out/store/sold-out.selectors';
import { countCartItems } from './count-cart-items';

@Injectable()
export class OrderListButtonQueries {
  readonly cartItems$: Observable<CartItem[]>;
  readonly cartItemCount$: Observable<number>;
  readonly isStartAnimation$: Observable<boolean>;

  constructor(store: Store<State<any>>) {
    this.cartItems$ = store.pipe(selectCartItems, shareReplay(1));

    const isCartItemSoldOut$ = store.pipe(
      selectSoldOuts,
      map((v) => makeIsCartItemSoldOutFunction(v)),
      shareReplay(1)
    );

    // 全プランのextraMenuに含まれる商品
    const allPlansItemIdList$ = combineLatest([
      store.pipe(selectMenuBook, shareReplay(1)),
      store.pipe(selectAllPlanExtraMenuIdList, shareReplay(1)),
    ]).pipe(
      map((v) => {
        return makeAllPlansItemIdList(...v);
      })
    );

    const isCartItemOrderStop$ = combineLatest([
      store.pipe(selectPlan, shareReplay(1)),
      store.pipe(selectNow, shareReplay(1)),
      allPlansItemIdList$,
    ]).pipe(
      map(([plan, now, allPlansItemIds]) =>
        makeIsCartItemOrderStopFunction(plan, now, allPlansItemIds)
      ),
      shareReplay(1)
    );

    this.cartItemCount$ = combineLatest([
      this.cartItems$,
      isCartItemSoldOut$,
      isCartItemOrderStop$,
    ]).pipe(
      map((v) => {
        return countCartItems(...v);
      }),
      shareReplay(1)
    );

    this.isStartAnimation$ = store.pipe(selectIsStartAnimation, shareReplay(1));
  }
}
