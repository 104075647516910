import { CartItem } from '../../../models/cart-item/cart-item';
import { SoldOuts } from '../../../models/sold-out/sold-outs';
import { makeIsMenuItemSoldOutFunction } from './make-is-menu-item-sold-out-function';
import { makeIsModifierSoldOutFunction } from './make-is-modifier-sold-out-function';
import { makeIsVariationSoldOutFunction } from './make-is-variation-sold-out-function';

export type IsCartItemSoldOutFunction<T> = (value: T) => boolean;

export function makeIsCartItemSoldOutFunction(
  soldOuts: SoldOuts
): IsCartItemSoldOutFunction<CartItem> {
  const isMenuItemSoldOut = makeIsMenuItemSoldOutFunction(soldOuts);
  const isVariationSoldOut = makeIsVariationSoldOutFunction(soldOuts);
  const isModifierSoldOut = makeIsModifierSoldOutFunction(soldOuts);
  return (item) => {
    /**
     * オプションが選択されていない場合は、オプション内では品切れとならない
     */
    const isModifiersSoldOut =
      item.modifierSetControllers !== null
        ? item.modifierSetControllers
            .getModifiers()
            .some((v) => isModifierSoldOut(v.modifier.modifierId))
        : false;
    return [
      isMenuItemSoldOut(item.itemId),
      isVariationSoldOut(item.variationId),
      isModifiersSoldOut,
    ].some((v) => v);
  };
}
