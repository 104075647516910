export function isWorkflowError(error: unknown): error is WorkflowError {
  return error instanceof WorkflowError;
}

interface WorkflowErrorResponse {
  message: string;
  error: unknown;
}

export class WorkflowError extends Error {
  error: unknown;

  constructor(message: string, error: unknown) {
    super(message);
    this.name = 'WorkflowError';
    this.error = error;
  }

  getErrorResponse(): WorkflowErrorResponse {
    return {
      message: this.message,
      error: this.error,
    };
  }
}
