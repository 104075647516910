import { createReducer, on } from '@ngrx/store';

import { SessionActionsUnion, storeSession } from './session.actions';
import { initialState } from './session.initial-state';
import { SessionState } from './session.state';

export function sessionReducer(
  sessionState: SessionState,
  action: SessionActionsUnion
): SessionState {
  type S = SessionState;
  const reducer = createReducer<S>(
    initialState,
    on(storeSession, (state, { payload }): S => {
      return { ...state, session: payload };
    })
  );
  return reducer(sessionState, action);
}

export const sessionFeatureName = 'sessionFeature';
