import { SoldOuts } from '../../../models/sold-out/sold-outs';
import { VariationId } from '../../../models/variation/variation-id';

export type IsVariationSoldOutFunction<T> = (value: T) => boolean;

export function makeIsVariationSoldOutFunction(
  soldOuts: SoldOuts
): IsVariationSoldOutFunction<VariationId> {
  return (id) => {
    return soldOuts.variationIds.some((v) => v.eq(id));
  };
}
