import { Asset } from 'contentful';

import { Media } from '../../../models/media';
import { convertToBffMediaPath } from '../convert-to-bff-media-path';
import { adaptMediaType } from './adapt-media-type';

export function adaptMedia(res: Asset): Media {
  return {
    name: res.fields.title,
    type: adaptMediaType(res.fields.file.contentType),
    imgUrl: convertToBffMediaPath(res.fields.file.url),
  };
}
