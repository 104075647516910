import { createAction, union } from '@ngrx/store';

export const storeNow = createAction(
  'NowActions storeNow',
  (payload: number) => ({ payload })
);

const all = union({
  storeNow,
});

export type NowActionsUnion = typeof all;
