import { BookType } from '../../../models/book-type/book-type';
import { BookTypeId } from '../../../models/book-type/book-type-id';
import { MenuCategories } from '../../../models/menu-category/menu-categories';
import { PlanId } from '../../../models/plan/plan-id';
import { adaptCaution } from '../caution/adapt-caution';
import { adaptImage } from '../image/adapt-image';
import { adaptMenuCategory } from '../menu-category/adapt-menu-category';
import { adaptRecommended } from '../recommended/adapt-recommended';
import { adaptDisplayCustomerAppCode } from './adapt-display-customer-app-code';
import { BookTypeResponse } from './book-type.response';

export function adaptBookType(
  bookTypeResponse: BookTypeResponse,
  planId: PlanId | null
): BookType {
  const categoryList = bookTypeResponse.fields.menu_categories.map(
    (menuCategoryResponse) => {
      return adaptMenuCategory(menuCategoryResponse);
    }
  );

  return new BookType({
    bookTypeId: new BookTypeId(bookTypeResponse.sys.id),
    name: bookTypeResponse.fields.name,
    icon: adaptImage(bookTypeResponse.fields.icon),
    recommended: bookTypeResponse.fields.recommended
      ? adaptRecommended(bookTypeResponse.fields.recommended)
      : null,
    categories: new MenuCategories(categoryList),
    caution: bookTypeResponse.fields.caution
      ? bookTypeResponse.fields.caution.map((v) => adaptCaution(v))
      : [],
    planId,
    displayCustomerApp: adaptDisplayCustomerAppCode(
      bookTypeResponse.fields.display_customer_app
    ),
  });
}
