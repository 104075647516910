import { createAction, union } from '@ngrx/store';

import { Session } from '../../../../models/session/session';

export const storeSession = createAction(
  'SessionActions storeSession',
  (payload: Session) => ({ payload })
);

const all = union({
  storeSession,
});

export type SessionActionsUnion = typeof all;
