import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { BookTypeId } from '../../../../models/book-type/book-type-id';
import { ApiLocationId } from '../../../../models/location/api-location-id';
import { LocationId } from '../../../../models/location/location-id';
import { MenuBook } from '../../../../models/menu-book/menu-book';
import { Session } from '../../../../models/session/session';
import { bookTypeIdQueryKey } from '../../book-type/book-type-id-query-key';
import {
  selectApiLocationId,
  selectLocationId,
  selectLocationName,
  selectMenuBook,
} from '../../location/store/location.selectors';
import { makeMenuBookForCustomer } from '../../menuBook/make-menu-book-for-customer';
import { nonNullFilter } from '../../non-null-filter';
import { selectNow } from '../../now/store/now.selectors';
import { selectPlan } from '../../plan/store/plan.selectors';
import { selectSession } from '../../session/store/session.selectors';
import { BookTypeViewModel } from './book-type-view-model';
import { makeBookTypeViewModel } from './make-book-type-view-model';

@Injectable()
export class FooterMenuQueries {
  readonly session$: Observable<Session>;
  readonly locationId$: Observable<LocationId>;
  readonly locationName$: Observable<string>;
  readonly apiLocationId$: Observable<ApiLocationId>;
  readonly menuBookForCustomer$: Observable<MenuBook>;
  readonly bookTypeViewModels$: Observable<BookTypeViewModel[]>;
  readonly selectedBookTypeId$: Observable<BookTypeId | null>;

  constructor(store: Store<State<any>>, route: ActivatedRoute) {
    this.session$ = store.pipe(selectSession, nonNullFilter(), shareReplay(1));

    this.locationId$ = store.pipe(
      selectLocationId,
      nonNullFilter(),
      shareReplay(1)
    );

    this.locationName$ = store.pipe(selectLocationName, shareReplay(1));

    this.apiLocationId$ = store.pipe(
      selectApiLocationId,
      nonNullFilter(),
      shareReplay(1)
    );

    this.menuBookForCustomer$ = combineLatest([
      store.pipe(selectMenuBook, shareReplay(1)),
      store.pipe(selectPlan, shareReplay(1)),
      store.pipe(selectNow, shareReplay(1)),
    ]).pipe(
      map(([manuBook, plan, now]) =>
        makeMenuBookForCustomer(manuBook, plan, now)
      ),
      shareReplay(1)
    );

    this.selectedBookTypeId$ = route.queryParams.pipe(
      map((params) => {
        const bookTypeId = params[bookTypeIdQueryKey];
        if (!bookTypeId) {
          return null;
        }
        return new BookTypeId(bookTypeId);
      })
    );

    this.bookTypeViewModels$ = combineLatest([
      this.menuBookForCustomer$,
      this.selectedBookTypeId$,
      store.pipe(selectPlan, shareReplay(1)),
      store.pipe(selectNow, shareReplay(1)),
    ]).pipe(
      map(([menuBook, selectedBookTypeId, plan, now]) =>
        makeBookTypeViewModel(menuBook, selectedBookTypeId, plan, now)
      ),
      shareReplay(1)
    );
  }
}
