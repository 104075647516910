import { Modifier } from '../../../../models/menu-item/modifier';
import { ModifierWithQuantity } from './modifier-with-quantity';

export function toggleModifier(
  selectedModifierList: ModifierWithQuantity[],
  modifier: Modifier
): ModifierWithQuantity[] {
  const found = selectedModifierList.find((v) => {
    return v.modifier.modifierId.eq(modifier.modifierId);
  });

  if (found === undefined) {
    return selectedModifierList.concat({ modifier, quantity: 1 });
  }

  return selectedModifierList.filter((v) => {
    return !v.modifier.modifierId.eq(modifier.modifierId);
  });
}
