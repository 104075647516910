import { BookTypeId } from '../../../models/book-type/book-type-id';
import { LocationId } from '../../../models/location/location-id';
import { LocationOfContentful } from '../../../models/location/location-of-contentful';
import { PlanId } from '../../../models/plan/plan-id';
import { PrintController } from '../../../models/printer/print-controller';
import { PrintControllers } from '../../../models/printer/print-controllers';
import { StaffImages } from '../../../models/staff-image/staff-images';
import { adaptBanners } from '../banner/adapt-banners';
import { adaptImage } from '../image/adapt-image';
import { adaptMenuBook } from '../menu-book/adapt-menu-book';
import { adaptPrintItemController } from '../printer/adapt-print-item-controller';
import { adaptPrintNotificationControllers } from '../printer/adapt-print-notification-controllers';
import { adaptPrinter } from '../printer/adapt-printer';
import { LocationResponse } from './location.response';

export function adaptLocation(
  res: LocationResponse,
  planId: PlanId | null,
  extraMenuBookTypeId: BookTypeId | null
): LocationOfContentful {
  const printControllerList: PrintController[] =
    res.fields.print_qr_controllers.map((v) => {
      return {
        name: v.fields.name,
        printer: adaptPrinter(v.fields.printer),
        tables: v.fields.tables.map((table) => {
          return { name: table.fields.name };
        }),
      };
    });
  return {
    locationId: new LocationId(res.sys.id),
    name: res.fields.name,
    tel: res.fields.tel,
    menuBook: adaptMenuBook(res, planId, extraMenuBookTypeId),
    printItemControllers: res.fields.print_item_controllers.map((v) =>
      adaptPrintItemController(v)
    ),
    printControllers: new PrintControllers(printControllerList),
    printNotificationControllers: adaptPrintNotificationControllers(
      res.fields.print_notification_controllers
    ),
    welcomeMessage:
      res.fields.staff_images_and_welcome_message.fields.welcome_message,
    staffImages: new StaffImages(
      res.fields.staff_images_and_welcome_message.fields.staff_images.map((v) =>
        adaptImage(v)
      )
    ),
    orderCompleteBanner: adaptBanners(res.fields.order_complete_banner),
    logoImage: adaptImage(res.fields.logo_image),
  };
}
