import { MediaType } from '../../../models/media-type';

export function adaptMediaType(res: string): MediaType {
  if (res.indexOf('image') !== -1) {
    return 'image';
  }
  if (res.indexOf('video') !== -1) {
    return 'video';
  }
  throw new Error('Media type is undefined.');
}
