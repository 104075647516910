import { createReducer, on } from '@ngrx/store';

import {
  OrderListActionsUnion,
  startSending,
  stopSending,
} from './order-list.actions';
import { initialState } from './order-list.initial-state';
import { OrderListState } from './order-list.state';

export function orderListReducer(
  orderListState: OrderListState,
  action: OrderListActionsUnion
): OrderListState {
  type S = OrderListState;
  const reducer = createReducer<S>(
    initialState,
    on(startSending, (state): S => {
      return { ...state, isSending: true };
    }),
    on(stopSending, (state): S => {
      return { ...state, isSending: false };
    })
  );
  return reducer(orderListState, action);
}

export const orderListFeatureName = 'orderListFeature';
