import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { nonNullFilter } from '../../non-null-filter';
import { brandFeatureName } from './brand.reducer';
import { BrandState } from './brand.state';

const selectFeature = createFeatureSelector<BrandState>(brandFeatureName);

export const selectBrand = pipe(
  select(createSelector(selectFeature, (v) => v.brand)),
  nonNullFilter()
);
