import { createAction, union } from '@ngrx/store';

export const storeScrollLeftCategory = createAction(
  'BookTypeActions storeScrollLeftCategory',
  (payload: number) => ({ payload })
);

const all = union({
  storeScrollLeftCategory,
});

export type BookTypeActionsUnion = typeof all;
