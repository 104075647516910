import { ExternalLinkResponse } from '../../lib/contentful/brand/external-link-field-response';
import { adaptImage } from '../../lib/contentful/image/adapt-image';
import { ExternalLink } from './external-link';
import { ExternalLinkId } from './external-link-id';
import { isUrlValid } from './is-url-valid';

export function adaptExternalLink(res: ExternalLinkResponse): ExternalLink {
  if (!isUrlValid(res.fields.url)) {
    throw new Error('External Link Url is valid.');
  }
  return {
    id: new ExternalLinkId(res.sys.id),
    title: res.fields.title ?? '',
    image: adaptImage(res.fields.image),
    url: res.fields.url,
    icon: res.fields.icon ? adaptImage(res.fields.icon) : null,
  };
}
