import { MenuItem } from '../menu-item/menu-item';
import { MenuItemId } from '../menu-item/menu-item-id';
import { Plan } from '../plan/plan';
import { PlanId } from '../plan/plan-id';
import { MenuCategory } from './menu-category';
import { MenuCategoryId } from './menu-category-id';

export class MenuCategories {
  private firstMenuCategory: MenuCategory;
  constructor(private readonly list: MenuCategory[]) {
    if (this.list[0] === undefined) {
      throw new Error(`FirstMenuCategory is undefined.`);
    }
    this.firstMenuCategory = this.list[0];
  }

  asMenuCategoryList(): MenuCategory[] {
    return this.list;
  }

  findMenuCategoryByMenuCategoryId(
    menuCategoryId: MenuCategoryId
  ): MenuCategory | null {
    const found = this.list.find((v) => {
      return v.id.eq(menuCategoryId);
    });
    return found ?? null;
  }

  findMenuCategoryByMenuItemId(
    itemId: MenuItemId | PlanId
  ): MenuCategory | null {
    return (
      this.fidMenuCategoryFromMenuItemInAreaL(itemId) ??
      this.fidMenuCategoryFromMenuItemInAreaS(itemId)
    );
  }

  getFirstMenuCategoryId(): MenuCategoryId {
    return this.firstMenuCategory.id;
  }

  getMenuItemList(): MenuItem[] {
    const areaItemGroupList = this.list.reduce((acc, cur) => {
      return acc.concat(cur.items);
    }, [] as Array<MenuItem | Plan>);

    return areaItemGroupList.filter(
      (v): v is MenuItem => v.contentType === 'menu_item'
    );
  }

  getPlanList(): Plan[] {
    const areaItemGroupList = this.list.reduce((acc, cur) => {
      return acc.concat(cur.items);
    }, [] as Array<MenuItem | Plan>);

    return areaItemGroupList.filter(
      (v): v is Plan => v.contentType === 'plans'
    );
  }

  hasMenuItem(itemId: MenuItemId | PlanId): boolean {
    return this.findMenuCategoryByMenuItemId(itemId) !== null;
  }

  *[Symbol.iterator](): IterableIterator<MenuCategory> {
    for (const v of this.list) {
      yield v;
    }
  }

  private fidMenuCategoryFromMenuItemInAreaL(
    itemId: MenuItemId | PlanId
  ): MenuCategory | null {
    const found = this.list.find((v) => {
      if (v.areaL === null) {
        return;
      }
      return v.areaL.items.some((item) => {
        return item.id.eq(itemId);
      });
    });
    return found ?? null;
  }

  private fidMenuCategoryFromMenuItemInAreaS(
    itemId: MenuItemId | PlanId
  ): MenuCategory | null {
    const found = this.list.find((v) => {
      if (v.areaSList.length === 0) {
        return false;
      }
      return v.areaSList.some((areaS) => {
        return areaS.items.some((item) => {
          return item.id.eq(itemId);
        });
      });
    });
    return found ?? null;
  }
}
