import { RelatedItem } from '../../../models/menu-item/related-item';
import { RelatedItems } from '../../../models/menu-item/related-items';
import { RelatedItemsId } from '../../../models/menu-item/related-items-id';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { isPlanResponse } from '../plan/is-plan-response';
import { isSetItemResponse } from '../plan/is-set-item-response';
import { RelatedItemsResponse } from './related-items.response';

export function adaptRelatedItems(
  res: RelatedItemsResponse
): RelatedItems | null {
  const items: RelatedItem[] = res.fields.items.reduce((acc, cur) => {
    if (
      isPlanResponse(cur.fields.menu_item) &&
      !isSetItemResponse(cur.fields.menu_item)
    ) {
      return acc;
    }
    return [
      ...acc,
      {
        menuItem: adaptMenuItem(cur.fields.menu_item),
        description: cur.fields.description ?? '',
      },
    ];
  }, [] as RelatedItem[]);
  if (items.length === 0) {
    return null;
  }
  return {
    id: new RelatedItemsId(res.sys.id),
    title: res.fields.title,
    items,
  };
}
