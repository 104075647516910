import { MenuItem } from '../../../models/menu-item/menu-item';
import { Plan } from '../../../models/plan/plan';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { MenuItemResponse } from '../menu-item/menu-item.response';
import { adaptPlan } from '../plan/adapt-plan';
import { PlanResponse } from '../plan/plan.response';
import { isMenuItem } from '../recommended-area-item-group/is-menu-item';

export function adaptBannerItem(
  item: MenuItemResponse | PlanResponse
): MenuItem | Plan {
  return isMenuItem(item) ? adaptMenuItem(item) : adaptPlan(item);
}
