import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { OrderItem } from '../../../models/order/order-item';
import { ItemResponse } from './get-orders.response';

export function adaptOrderItem(res: ItemResponse): OrderItem {
  return new OrderItem({
    menuItemId: new MenuItemId(res.id),
    quantity: Number(res.quantity),
    metadata: res.metadata ?? null,
  });
}
