import { Stamp } from '../../../models/menu-item/stamp';
import { adaptImage } from '../image/adapt-image';
import { StampResponse } from './stamp-response';

export function adaptStamp(res: StampResponse): Stamp {
  return {
    name: res.fields.name,
    image: adaptImage(res.fields.image),
    description: res.fields.description,
    supplement: res.fields.supplement ?? '',
  };
}
