import { createReducer, on } from '@ngrx/store';

import {
  LocationActionsUnion,
  storeLocation,
  storeLocationId,
  storeMenuBook,
} from './location.actions';
import { initialState } from './location.initial-state';
import { LocationState } from './location.state';

export function locationReducer(
  locationState: LocationState,
  action: LocationActionsUnion
): LocationState {
  type S = LocationState;
  const reducer = createReducer<S>(
    initialState,
    on(storeLocationId, (state, { payload }): S => {
      return {
        ...state,
        locationId: payload.locationId,
        apiLocationId: payload.apiLocationId,
      };
    }),
    on(storeLocation, (state, { payload }): S => {
      return {
        ...state,
        locationId: payload.locationId,
        tel: payload.tel,
        name: payload.name,
        menuBook: payload.menuBook,
        printItemControllers: payload.printItemControllers,
        printControllers: payload.printControllers,
        printNotificationControllers: payload.printNotificationControllers,
        welcomeMessage: payload.welcomeMessage,
        staffImages: payload.staffImages,
        orderCompleteBanner: payload.orderCompleteBanner,
        logoImage: payload.logoImage,
      };
    }),
    on(storeMenuBook, (state, { payload }): S => {
      return {
        ...state,
        menuBook: payload,
      };
    })
  );
  return reducer(locationState, action);
}

export const locationFeatureName = 'locationFeature';
