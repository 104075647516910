import { BrandResponse } from '../../lib/contentful/brand/brand.response';
import { adaptExternalLink } from './adapt-external-link';
import { Brand } from './brand';

export function adaptBrand(res: BrandResponse): Brand {
  return {
    title: res.fields.title,
    externalLinkList: res.fields.external_links
      ? res.fields.external_links.map((v) => {
          return adaptExternalLink(v);
        })
      : [],
  };
}
