import { GetIdentitiesResponse } from 'src/app/lib/cocktail/identity/identities.response';

export const getIdentitiesResponseMock: GetIdentitiesResponse = {
  status: 'ok',
  result: {
    identities: [
      {
        id: 'trial',
        total: { currencyUnit: 'JPY', amount: 0 },
      },
    ],
  },
};
