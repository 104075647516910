import { createReducer, on } from '@ngrx/store';
import { storeAccountIds } from './account-ids.actions';
import { initialState } from './account-ids.initial-state';
import { AccountIdsState } from './accounts.state';

export const accountIdsReducer = createReducer<AccountIdsState>(
  initialState,
  on(storeAccountIds, (state, { payload }) => {
    if (state === null) {
      return payload;
    }
    return {
      ...state,
      ...payload,
    };
  })
);

export const accountIdsFeatureName = 'accountIdsFeature';
