export type DisplayCustomerAppLabel =
  | '常に表示する'
  | '常に表示しない'
  | '飲み放題中のみ表示する'
  | '飲み放題中のみ表示しない';

export type DisplayCustomerAppCode =
  | 'visibleAlways'
  | 'hiddenAlways'
  | 'visibleOnlyDuringCourse'
  | 'hiddenOnlyDuringCourse';

export interface DisplayCustomerAppMap {
  label: DisplayCustomerAppLabel;
  code: DisplayCustomerAppCode;
}

export const displayCustomerAppMap: DisplayCustomerAppMap[] = [
  { label: '常に表示する', code: 'visibleAlways' },
  { label: '常に表示しない', code: 'hiddenAlways' },
  { label: '飲み放題中のみ表示する', code: 'visibleOnlyDuringCourse' },
  { label: '飲み放題中のみ表示しない', code: 'hiddenOnlyDuringCourse' },
];
