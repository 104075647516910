import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SessionId } from '../../../../models/session/session-id';
import { OrderListButtonCommands } from './order-list-button.commands';
import { OrderListButtonQueries } from './order-list-button.queries';

@Component({
  selector: 'app-order-list-button',
  templateUrl: './order-list-button.component.html',
  styleUrls: ['./order-list-button.component.scss'],
  animations: [
    trigger('addCartDefault', [
      state('addCart', style({ transform: 'scale3d(1, 1, 1)' })),
      state('default', style({ transform: 'scale3d(1, 1, 1)' })),
      transition('addCart => default', [
        animate(
          '2s',
          keyframes([
            style({ transform: 'scale3d(1, 1, 1)', offset: 0 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 0.05 }),
            style({ transform: 'scale3d(1.3, 1.3, 1.3)', offset: 0.15 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 0.2 }),
            style({ transform: 'scale3d(1.3, 1.3, 1.3)', offset: 0.25 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 0.3 }),
            style({ transform: 'scale3d(1, 1, 1)', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class OrderListButtonComponent implements AfterViewInit, OnDestroy {
  @Input() sessionId: SessionId | null = null;

  private readonly destroy$ = new Subject<void>();
  isAnimation = false;

  constructor(
    readonly queries: OrderListButtonQueries,
    private readonly commands: OrderListButtonCommands
  ) {}

  ngAfterViewInit(): void {
    this.queries.isStartAnimation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        if (!v) {
          return;
        }
        this.isAnimation = false;
        requestAnimationFrame(() => {
          this.isAnimation = true;
          this.commands.stopAnimation();
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
