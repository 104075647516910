import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-location-back-button',
  templateUrl: './location-back-button.component.html',
  styleUrls: ['./location-back-button.component.scss'],
})
export class LocationBackButtonComponent {
  constructor(private readonly location: Location) {}

  onClickBack(): void {
    this.location.back();
  }
}
