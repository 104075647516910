import { MetadataAny } from '../../lib/cocktail/models/metadata-any';
import { MenuItemId } from '../menu-item/menu-item-id';

export interface Params {
  menuItemId: MenuItemId;
  quantity: number;
  metadata: MetadataAny | null;
}

export class OrderItem {
  private readonly menuItemId: MenuItemId;
  private readonly quantity: number;
  private readonly metadata: MetadataAny | null;

  constructor(params: Params) {
    this.menuItemId = params.menuItemId;
    this.quantity = params.quantity;
    this.metadata = params.metadata;
  }

  // 数量変更が行われたかをチェックする
  eqQuantity(other: OrderItem): boolean {
    return [
      this.menuItemId.eq(other.menuItemId),
      this.quantity === other.quantity,
    ].every((v) => v);
  }
}
