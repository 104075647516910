import { Injectable } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { ApiLocationId } from '../../../models/location/api-location-id';
import { SoldOutResponse } from './sold-out.response';

/**
 * @TODO clientではなくapiの方が良いかも
 */
@Injectable({ providedIn: 'root' })
export class SoldOutClient {
  constructor(private firestore: Firestore) {}

  /**
   * 売り止め情報を取得
   *
   * @see https://dev.to/jdgamble555/angular-12-with-firebase-9-49a0
   */
  listSoldOut(apiLocationId: ApiLocationId): Observable<SoldOutResponse> {
    return docData(
      doc(this.firestore, `sold_outs/${apiLocationId.toString()}`)
    ) as Observable<SoldOutResponse>; // ライブラリの都合でasを許容
  }
}
