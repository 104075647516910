import { PlanExtensionResponse } from '../plan/plan-extension.response';
import { PlanResponse } from '../plan/plan.response';
import { ContentTypeIds } from '../recommended-area-item-group/content-type-ids';
import { MenuItemResponse } from './menu-item.response';

export function isMenuItemResponse(
  arg: MenuItemResponse | PlanResponse | PlanExtensionResponse
): arg is MenuItemResponse {
  return arg.sys.contentType.sys.id === ContentTypeIds.menuItem;
}
