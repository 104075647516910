import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { distinctUntilChanged, filter, mapTo } from 'rxjs/operators';
import { IdentityDetail } from '../../../../models/identity/identity';
import { nonNullFilter } from '../../non-null-filter';
import { identityFeatureName } from './identity.reducer';
import { IdentityState } from './identity.state';

const selectFeature = createFeatureSelector<IdentityState>(identityFeatureName);

export const selectIdentity = pipe(
  select(createSelector(selectFeature, (v) => v.identity)),
  nonNullFilter()
);

export const selectIdentityDetail = pipe(
  select(createSelector(selectFeature, (v) => v.identityDetail)),
  nonNullFilter()
);

export const selectOrderHistories = pipe(
  select(createSelector(selectFeature, (v) => v.orderHistories)),
  nonNullFilter(),
  distinctUntilChanged((a, b) => a.eq(b))
);

export const selectIsPreparing = pipe(
  selectIdentityDetail,
  nonNullFilter(),
  // identity に変更があったときだけ流す
  distinctUntilChanged((a, b) => {
    return a.eqIdentityTime(b as IdentityDetail);
  }),
  filter((identityDetail) => identityDetail.getIsPreparing()),
  mapTo(true)
  // 流れてきた値は常に受け取るので、ここで distinctUntilChanged() はしない
);

export const selectIsOrderStart = pipe(
  selectIdentityDetail,
  nonNullFilter(),
  // identity に変更があったときだけ流す
  distinctUntilChanged((a, b) => {
    return a.eqIdentityTime(b as IdentityDetail);
  }),
  filter((identityDetail) => identityDetail.getIsOrderStart()),
  mapTo(true)
  // 流れてきた値は常に受け取るので、ここで distinctUntilChanged() はしない
);

export const selectIsOrderStop = pipe(
  selectIdentityDetail,
  nonNullFilter(),
  filter((identityDetail) => identityDetail.getIsOrderStop()),
  mapTo(true)
  // 流れてきた値は常に受け取るので、ここで distinctUntilChanged() はしない
);

export const selectIsLeaving = pipe(
  selectIdentityDetail,
  nonNullFilter(),
  // 退店状態で閲覧可能な画面は時間制限なく公開するため、!identityDetail.getIsDeadline()を見なくて良い
  filter((identityDetail) => identityDetail.getIsLeaving()),
  mapTo(true)
  // 流れてきた値は常に受け取るので、ここで distinctUntilChanged() はしない
);
