import { ModifierSetControllerId } from '../../../models/location/modifier-set-controller-id';
import { ModifierSetController } from '../../../models/menu-item/modifier-set-controller';
import { adaptModifierSet } from './adapt-modifier-set';
import { ModifierSetControllerResponse } from './modifier-set-controller.response';

export function adaptModifierSetControllers(
  res: ModifierSetControllerResponse[]
): ModifierSetController[] {
  return res.map((v) => {
    return {
      modifierSetControllerId: new ModifierSetControllerId(v.sys.id),
      name: v.fields.name,
      min: v.fields.min,
      max: v.fields.max,
      allowDuplicates: v.fields.allow_duplicates,
      modifierSet: adaptModifierSet(v.fields.modifier_set),
    };
  });
}
