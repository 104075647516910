import { PrintController } from './print-controller';
import { Printer } from './printer';

export class PrintControllers {
  private firstPrintController: PrintController;
  constructor(private readonly list: PrintController[]) {
    if (this.list[0] === undefined) {
      throw new Error('Printer is undefined');
    }
    this.firstPrintController = this.list[0];
  }

  findFromTableName(name: string): Printer[] {
    const printers = this.list
      .filter((printController) =>
        printController.tables.some((v) => v.name === name)
      )
      .map((v) => v.printer);

    return printers.length > 0 ? printers : [this.firstPrintController.printer];
  }

  *[Symbol.iterator](): IterableIterator<PrintController> {
    for (const v of this.list) {
      yield v;
    }
  }
}
