import { PrintNotificationControllers } from '../../../models/printer/print-notification-controllers';
import { adaptPrintNotificationController } from './adapt-print-notification-controller';
import { adaptPrinter } from './adapt-printer';
import { PrintNotificationControllersResponse } from './printer-notification-controllers.response';

export function adaptPrintNotificationControllers(
  res: PrintNotificationControllersResponse
): PrintNotificationControllers {
  return new PrintNotificationControllers({
    defaultPrinter: adaptPrinter(res.fields.default_printer),
    list: res.fields.print_notification_controllers
      ? res.fields.print_notification_controllers.map((v) =>
          adaptPrintNotificationController(v)
        )
      : [],
  });
}
