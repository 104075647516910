import { Injectable } from '@angular/core';
import { CocktailClient } from '../../lib/cocktail/cocktail-client';
import { adaptOrderHistories } from '../../lib/cocktail/identity/adapt-order-histories';
import { OrderInputModel } from '../../lib/cocktail/order/order-input-model';
import { PostOrdersResponse } from '../../lib/cocktail/order/post-orders.response';
import { Response } from '../../lib/cocktail/response';
import { IdentityId } from '../identity/identity-id';
import { ApiLocationId } from '../location/api-location-id';
import { SessionId } from '../session/session-id';
import { OrderHistories } from './order-histories';

@Injectable({ providedIn: 'root' })
export class OrderRepository {
  constructor(private readonly cocktailClient: CocktailClient) {}

  async getOrders(inputModel: {
    sessionId: SessionId;
    identityId: IdentityId;
    locationId: ApiLocationId;
  }): Promise<OrderHistories> {
    const res = await this.cocktailClient.getOrders(inputModel);
    return adaptOrderHistories(res.result.orders);
  }

  async postOrders(inputModel: OrderInputModel): Promise<PostOrdersResponse> {
    return await this.cocktailClient.postOrders(inputModel.asRequest());
  }

  async postOrderStop(inputModel: {
    sessionId: SessionId;
    locationId: ApiLocationId;
    identityId: IdentityId;
    cuid: string;
  }): Promise<Response> {
    return await this.cocktailClient.postOrderStop(inputModel);
  }
}
