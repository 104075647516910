import { BookTypeId } from '../../../models/book-type/book-type-id';
import { MenuBook } from '../../../models/menu-book/menu-book';
import { MenuItem } from '../../../models/menu-item/menu-item';
import { MenuItemId } from '../../../models/menu-item/menu-item-id';

// 全プランのextraMenuに含まれる商品を返す
export function makeAllPlansItemIdList(
  menuBook: MenuBook,
  allPlanExtraMenuIdList: BookTypeId[]
): MenuItemId[] {
  const planExtraMenuBookList = [...menuBook.bookTypes].filter((bookType) => {
    return allPlanExtraMenuIdList.find((v) => v.eq(bookType.bookTypeId));
  });
  const allPlansItems = planExtraMenuBookList.reduce((acc, cur) => {
    return acc.concat(cur.categories.getMenuItemList());
  }, [] as MenuItem[]);
  return allPlansItems.map((v) => v.id);
}
