import { CartItem } from '../../../models/cart-item/cart-item';
import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { PlanDetail } from '../../../models/plan/plan-detail';

export type IsCartItemOrderStopFunction<T> = (value: T) => boolean;

export function makeIsCartItemOrderStopFunction(
  plan: PlanDetail | null,
  now: Date,
  allPlansItemIds: MenuItemId[]
): IsCartItemOrderStopFunction<CartItem> {
  return (item) => {
    if (plan === null) {
      return allPlansItemIds.some((v) => v.eq(item.itemId));
    }
    if (plan.extraMenuBookTypeId === null) {
      return false;
    }
    if (!plan.extraMenuBookTypeId.eq(item.bookTypeId)) {
      return false;
    }

    return plan.isOrderStop(now);
  };
}
