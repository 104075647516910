import {
  DisplayCustomerAppCode,
  displayCustomerAppMap,
} from '../../../models/book-type/display-customer-app';

export function adaptDisplayCustomerAppCode(
  res: string
): DisplayCustomerAppCode {
  const found = displayCustomerAppMap.find((v) => v.label === res);
  if (found === undefined) {
    throw new Error('Display customer app is undefined.');
  }
  return found.code;
}
