import { Asset, Entry } from 'contentful';

import { RecommendedCommentResponse } from '../recommended-comment/recommended-comment.response';
import { RelatedItemsResponse } from '../related-item/related-items.response';
import { StampResponse } from '../stamp/stamp-response';
import { StoryResponse } from '../story/story.response';
import { VariationResponse } from '../variation/variation.response';

export const MENU_ITEM_RESPONSE_INCLUDE_LEVEL = 4;
export interface MenuItemFieldsResponse {
  name: string;
  name_print: string;
  comment?: RecommendedCommentResponse;
  card_description?: string;
  image: Asset;
  video?: Asset;
  stamp?: StampResponse;
  variations: VariationResponse[];
  related_items?: RelatedItemsResponse;
  story?: StoryResponse;
}

export type MenuItemResponse = Entry<MenuItemFieldsResponse>;
