import { AccountIds } from '../../../models/accounts/account-ids';
import { PreconditionError } from '../../../utils/assert-string';

export function assertNestSupportedAccounts(
  isNestSupported: boolean,
  accountIds: AccountIds
): void {
  if (isNestSupported && accountIds === null) {
    throw new PreconditionError('Accounts must be non-null for Nest supported');
  }
}
