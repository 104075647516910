import { Identity } from '../../../models/identity/identity';
import { IdentityId } from '../../../models/identity/identity-id';
import { GetIdentitiesResponse } from './identities.response';

export function adaptIdentities(res: GetIdentitiesResponse): Identity[] {
  return res.result.identities.map((v) => {
    return {
      id: new IdentityId(v.id),
    };
  });
}
