import resolveResponse from 'contentful-resolve-response';
import { toPlainObject, freezeSys } from 'contentful-sdk-core';
import jsonStringifySafe from 'json-stringify-safe';

import { ContentfulResponse } from './contentful-response';

/**
 * @see https://github.com/contentful/contentful.js/blob/dc05a6c8ffad6f5b0f7bc8456611ff7964289ca4/lib/mixins/stringify-safe.js
 */
function mixinStringifySafe<T>(data: ContentfulResponse<T>) {
  return Object.defineProperty(data, 'stringifySafe', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function (serializer = null, indent = '') {
      return jsonStringifySafe(this, serializer, indent, (key, value) => {
        return {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: value.sys.id,
            circular: true,
          },
        };
      });
    },
  });
}

export function adaptEntry<T>(data: ContentfulResponse<T>): T {
  const wrappedData = mixinStringifySafe<T>(toPlainObject(data));
  wrappedData.items = resolveResponse(wrappedData, {
    removeUnresolved: false,
    itemEntryPoints: ['fields'],
  });
  const frozenData = freezeSys<ContentfulResponse<T>>(wrappedData);
  if (0 === frozenData.items.length) {
    throw new Error('not found');
  }
  return freezeSys(toPlainObject(frozenData.items[0]));
}
