import { Modifier } from '../../../../models/menu-item/modifier';
import { ModifierWithQuantity } from './modifier-with-quantity';

export function decrementModifier(
  selectedModifierList: ModifierWithQuantity[],
  modifier: Modifier
): ModifierWithQuantity[] {
  const found = selectedModifierList.find((v) => {
    return v.modifier.modifierId.eq(modifier.modifierId);
  });

  if (found === undefined) {
    return selectedModifierList;
  }

  return selectedModifierList
    .map((v) => {
      if (!v.modifier.modifierId.eq(modifier.modifierId)) {
        return v;
      }
      return {
        modifier: v.modifier,
        quantity: v.quantity - 1,
      };
    })
    .filter((v) => {
      return v.quantity > 0;
    });
}
