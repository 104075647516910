import { createReducer, on } from '@ngrx/store';

import {
  TimeLimitBarActionsUnion,
  storeIsProcessingNotification,
} from './time-limit-bar.actions';
import { initialState } from './time-limit-bar.initial-state';
import { TimeLimitBarState } from './time-limit-bar.state';

export function timeLimitBarReducer(
  TimeLimitBarState: TimeLimitBarState,
  action: TimeLimitBarActionsUnion
): TimeLimitBarState {
  type S = TimeLimitBarState;
  const reducer = createReducer<S>(
    initialState,
    on(storeIsProcessingNotification, (state, { payload }): S => {
      return { ...state, isProcessingNotification: payload };
    })
  );
  return reducer(TimeLimitBarState, action);
}

export const timeLimitBarFeatureName = 'timeLimitBarFeature';
