import { Asset, Entry } from 'contentful';

import { BannerResponse } from '../banner/banner.response';
import { MenuBookResponse } from '../menu-book/menu-book.response';
import { PrintControllerResponse } from '../printer/print-controller.response';
import { PrintItemControllerResponse } from '../printer/print-item-controller.response';
import { PrintNotificationControllersResponse } from '../printer/printer-notification-controllers.response';
import { StaffImagesAndWelcomeMessageResponse } from './staff-image-and-welcome-message-response';

export const LOCATION_RESPONSE_INCLUDE_LEVEL = 6;

export interface LocationFieldsResponse {
  name: string;
  tel: string;
  menu_book: MenuBookResponse;
  print_item_controllers: PrintItemControllerResponse[];
  print_qr_controllers: PrintControllerResponse[];
  print_notification_controllers: PrintNotificationControllersResponse;
  order_complete_banner: BannerResponse[];
  logo_image: Asset;
  staff_images_and_welcome_message: StaffImagesAndWelcomeMessageResponse;
}

export type LocationResponse = Entry<LocationFieldsResponse>;
