import { AccountIds } from '../../../models/accounts/account-ids';
import { ReceivableAccountId } from '../../../models/accounts/receivable-account-id';
import { RECEIVABLE_POINTER } from '../../../models/accounts/receivable-pointer';
import { SalesAccountId } from '../../../models/accounts/sales-account-id';
import { SalesDiscountsAccountId } from '../../../models/accounts/sales-discounts-account-id';
import { SALES_DISCOUNTS_POINTER } from '../../../models/accounts/sales-discounts-pointer';
import { SALES_POINTER } from '../../../models/accounts/sales-pointer';
import { StripeTradeAccountsReceivableAccountId } from '../../../models/accounts/stripe-trade-accounts-receivable-account-id';
import { STRIPE_TRADE_ACCOUNTS_RECEIVABLE_POINTER } from '../../../models/accounts/stripe-trade-accounts-receivable-pointer';
import { TradeAccountsReceivableAccountId } from '../../../models/accounts/trade-accounts-receivable-account-id';
import { TRADE_ACCOUNTS_RECEIVABLE_POINTER } from '../../../models/accounts/trade-accounts-receivable-pointer';
import { UnconfirmedTradeAccountsReceivableAccountId } from '../../../models/accounts/unconfirmed-trade-accounts-receivable-account-id';
import { UNCONFIRMED_TRADE_ACCOUNTS_RECEIVABLE_POINTER } from '../../../models/accounts/unconfirmed-trade-accounts-receivable-pointer';
import { PreconditionError } from '../../../utils/assert-string';
import { AccountsResponse } from './accounts.response';

export function adaptAccountIds(res: AccountsResponse): AccountIds {
  let stripeTradeAccountsReceivableAccountId:
    | StripeTradeAccountsReceivableAccountId
    | undefined;
  let tradeAccountsReceivableAccountId:
    | TradeAccountsReceivableAccountId
    | undefined;
  let salesDiscountsAccountId: SalesDiscountsAccountId | undefined;
  let unconfirmedTradeAccountsReceivableAccountId:
    | UnconfirmedTradeAccountsReceivableAccountId
    | undefined;
  let salesAccountId: SalesAccountId | undefined;
  let receivableAccountId: ReceivableAccountId | undefined;

  if (res.accounts.length === 0) {
    return null;
  }
  res.accounts.forEach((accountResponse) => {
    switch (accountResponse.pointer) {
      case TRADE_ACCOUNTS_RECEIVABLE_POINTER:
        tradeAccountsReceivableAccountId = new TradeAccountsReceivableAccountId(
          accountResponse.id
        );
        break;
      case UNCONFIRMED_TRADE_ACCOUNTS_RECEIVABLE_POINTER:
        unconfirmedTradeAccountsReceivableAccountId =
          new UnconfirmedTradeAccountsReceivableAccountId(accountResponse.id);
        break;
      case STRIPE_TRADE_ACCOUNTS_RECEIVABLE_POINTER:
        stripeTradeAccountsReceivableAccountId =
          new StripeTradeAccountsReceivableAccountId(accountResponse.id);
        break;
      case SALES_POINTER:
        salesAccountId = new SalesAccountId(accountResponse.id);
        break;
      case SALES_DISCOUNTS_POINTER:
        salesDiscountsAccountId = new SalesDiscountsAccountId(
          accountResponse.id
        );
        break;
      case RECEIVABLE_POINTER:
        receivableAccountId = new ReceivableAccountId(accountResponse.id);
        break;
      default:
        // noop
        break;
    }
  });

  if (
    !tradeAccountsReceivableAccountId ||
    !unconfirmedTradeAccountsReceivableAccountId ||
    !stripeTradeAccountsReceivableAccountId ||
    !salesAccountId ||
    !salesDiscountsAccountId ||
    !receivableAccountId
  ) {
    throw new PreconditionError('Some accounts are missing.');
  }

  return {
    tradeAccountsReceivableAccountId,
    unconfirmedTradeAccountsReceivableAccountId,
    stripeTradeAccountsReceivableAccountId,
    salesAccountId,
    salesDiscountsAccountId,
    receivableAccountId,
  };
}
