import { PrintItemSet } from '../../../models/printer/print-item-set';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { isMenuItemResponse } from '../menu-item/is-menu-item-response';
import { MenuItemResponse } from '../menu-item/menu-item.response';
import { adaptPlan } from '../plan/adapt-plan';
import { isPlanResponse } from '../plan/is-plan-response';
import { PlanResponse } from '../plan/plan.response';
import { PrintItemSetResponse } from './print-item-set.response';

export function adaptPrintItemSet(res: PrintItemSetResponse): PrintItemSet {
  return {
    name: res.fields.name,
    items: res.fields.items
      .filter((item): item is MenuItemResponse | PlanResponse =>
        [isMenuItemResponse(item), isPlanResponse(item)].some((v) => v)
      )
      .map((item) => {
        if (isMenuItemResponse(item)) {
          return adaptMenuItem(item);
        }
        return adaptPlan(item);
      }),
  };
}
