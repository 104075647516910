import { createReducer, on } from '@ngrx/store';

import { PayByCashActions, storeIsProcessing } from './pay-by-cash.actions';
import { initialState } from './pay-by-cash.initial-state';
import { PayByCashState } from './pay-by-cash.state';

export function payByCashReducer(
  payByCashState: PayByCashState,
  action: PayByCashActions
): PayByCashState {
  type S = PayByCashState;
  const reducer = createReducer<S>(
    initialState,
    on(storeIsProcessing, (state, { payload }): S => {
      return { ...state, isProcessing: payload };
    })
  );
  return reducer(payByCashState, action);
}

export const payByCashFeatureName = 'payByCashFeature';
