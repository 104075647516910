import { createReducer, on } from '@ngrx/store';

import {
  IdentityActionsUnion,
  storeIdentityTimestamps,
  storeIdentity,
  storeIdentityDetail,
  storeOrderHistories,
} from './identity.actions';
import { initialState } from './identity.initial-state';
import { IdentityState } from './identity.state';

export function identityReducer(
  identityState: IdentityState,
  action: IdentityActionsUnion
): IdentityState {
  type S = IdentityState;
  const reducer = createReducer<S>(
    initialState,
    on(storeIdentity, (state, { payload }): S => {
      return {
        ...state,
        identity: payload,
      };
    }),
    on(storeIdentityDetail, (state, { payload }): S => {
      return {
        ...state,
        identityDetail: payload,
      };
    }),
    on(storeIdentityTimestamps, (state, { payload }): S => {
      return {
        ...state,
        identityDetail: state.identityDetail
          ? state.identityDetail.getMergedIdentityTimestamps(payload)
          : null,
      };
    }),
    on(storeOrderHistories, (state, { payload }): S => {
      return {
        ...state,
        orderHistories: payload,
      };
    })
  );
  return reducer(identityState, action);
}

export const identityFeatureName = 'identityFeature';
