import { createAction, union } from '@ngrx/store';

import { PlanDetail } from '../../../../models/plan/plan-detail';
import { PlanTime } from '../../../../models/plan/plan-time';

export const storePlan = createAction(
  'PlanActions storePlan',
  (payload: PlanDetail | null) => ({
    payload,
  })
);

export const storePlanTime = createAction(
  'PlanActions storePlanTime',
  (payload: PlanTime | null) => ({
    payload,
  })
);

const all = union({
  storePlan,
  storePlanTime,
});

export type PlanActionsUnion = typeof all;
