import { RecommendedComment } from '../../../models/menu-item/recommended-comment';
import { RecommendedCommentResponse } from './recommended-comment.response';

export function adaptRecommendedComment(
  res: RecommendedCommentResponse
): RecommendedComment {
  return {
    comment: res.fields.comment,
  };
}
