import { createAction, union } from '@ngrx/store';

export const startBooting = createAction('LoadingActions startBooting');
export const stopBooting = createAction('LoadingActions stopBooting');

export const startLoading = createAction('LoadingActions startLoading');
export const stopLoading = createAction('LoadingActions stopLoading');

const all = union({
  startBooting,
  stopBooting,
  startLoading,
  stopLoading,
});

export type LoadingActionsUnion = typeof all;
