import { BookTypes } from '../../../models/book-type/book-types';
import { MenuBook } from '../../../models/menu-book/menu-book';
import { PlanDetail } from '../../../models/plan/plan-detail';
import { isDuringExpandMenu } from '../plan/is-during-expand-menu';

export function makeMenuBookForCustomer(
  menuBook: MenuBook,
  plan: PlanDetail | null,
  now: Date
): MenuBook {
  return {
    ...menuBook,
    bookTypes: new BookTypes(
      menuBook.bookTypes.selectBookTypeListForCustomer(
        isDuringExpandMenu(plan, now)
      )
    ),
  };
}
