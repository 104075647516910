import { PrimitiveValue } from './primitive-value';

export class FloatValue extends PrimitiveValue<number> {
  protected value: number;

  constructor(value: number | null | undefined) {
    if (value === undefined) {
      throw new TypeError('FloatValue was given undefined');
    }
    if (value === null) {
      throw new TypeError('FloatValue was given null');
    }
    if (isNaN(value)) {
      throw new TypeError('FloatValue was given NaN');
    }
    super(value);
    this.value = value;
  }

  toString(): string {
    return this.value.toString();
  }
}
