import { createReducer, on } from '@ngrx/store';

import { SelectedModifierSetControllers } from '../../modifier-bottom-sheet/components/selected-modifier-set-controllers';
import { decrementModifier } from './decrementModifier';
import { incrementModifier } from './incrementModifier';
import {
  clearItemDetail,
  clearSelectedModifier,
  MenuItemActionsUnion,
  storeItemDetail,
  toggleSelectedModifier,
  storeSelectedVariation,
  incrementSelectedModifier,
  decrementSelectedModifier,
} from './menu-item.actions';
import { initialState } from './menu-item.initial-state';
import { MenuItemState } from './menu-item.state';
import { toggleModifier } from './toggle-modifier';

export function menuItemReducer(
  menuItemState: MenuItemState,
  action: MenuItemActionsUnion
): MenuItemState {
  type S = MenuItemState;
  const reducer = createReducer<S>(
    initialState,
    on(storeItemDetail, (state, { payload }): S => {
      return { ...state, itemDetail: payload };
    }),
    on(clearItemDetail, (state): S => {
      return { ...state, itemDetail: null };
    }),
    on(storeSelectedVariation, (state, { payload }): S => {
      return { ...state, selectedVariation: payload };
    }),
    on(toggleSelectedModifier, (state, { payload }): S => {
      return {
        ...state,
        selectedModifiers: toggleModifier(state.selectedModifiers, payload),
      };
    }),
    on(incrementSelectedModifier, (state, { payload }): S => {
      const selectedModifiers = incrementModifier(
        state.selectedModifiers,
        payload
      );
      return {
        ...state,
        selectedModifiers,
      };
    }),
    on(decrementSelectedModifier, (state, { payload }): S => {
      const selectedModifiers = decrementModifier(
        state.selectedModifiers,
        payload
      );
      return {
        ...state,
        selectedModifiers,
      };
    }),
    on(clearSelectedModifier, (state): S => {
      return {
        ...state,
        selectedModifiers: new SelectedModifierSetControllers([]),
      };
    })
  );
  return reducer(menuItemState, action);
}

export const menuItemFeatureName = 'menuItemFeature';
