import { Caution } from '../caution/caution';
import { Image } from '../image';
import { MenuCategories } from '../menu-category/menu-categories';
import { MenuCategory } from '../menu-category/menu-category';
import { MenuCategoryId } from '../menu-category/menu-category-id';
import { MenuItemId } from '../menu-item/menu-item-id';
import { PlanId } from '../plan/plan-id';
import { Recommended } from '../recommended/recommended';
import { RecommendedId } from '../recommended/recommended-id';
import { BookTypeId } from './book-type-id';
import { DisplayCustomerAppCode } from './display-customer-app';

export function isMenuCategoryId(
  arg: RecommendedId | MenuCategoryId
): arg is MenuCategoryId {
  return arg.type === 'menuCategoryId';
}

export interface Params {
  bookTypeId: BookTypeId;
  name: string;
  icon: Image;
  recommended: Recommended | null;
  categories: MenuCategories;
  caution: Caution[];
  displayCustomerApp: DisplayCustomerAppCode;
  planId: PlanId | null;
}

export class BookType {
  readonly bookTypeId: BookTypeId;
  readonly name: string;
  readonly icon: Image;
  readonly recommended: Recommended | null;
  readonly categories: MenuCategories;
  readonly caution: Caution[];
  readonly displayCustomerApp: DisplayCustomerAppCode;
  readonly planId: PlanId | null;

  constructor(params: Params) {
    this.bookTypeId = params.bookTypeId;
    this.name = params.name;
    this.icon = params.icon;
    this.recommended = params.recommended;
    this.categories = params.categories;
    this.caution = params.caution;
    this.displayCustomerApp = params.displayCustomerApp;
    this.planId = params.planId;
  }

  findMenuCategoryByMenuItemId(itemId: MenuItemId | PlanId): MenuCategory {
    const found = this.categories.findMenuCategoryByMenuItemId(itemId);
    if (found === null) {
      throw new Error(`MenuCategory is undefined. MenuItemId is ${itemId}`);
    }
    return found;
  }

  /**
   * @TODO Recommended と MenuCategory をまとめた概念に名前を付けたい
   * 現状、コード上ではまとめた概念も MenuCategoryと呼んでいてわかりづらい
   */
  findMenuCategoryNameByMenuCategoryId(
    menuCategoryId: RecommendedId | MenuCategoryId
  ): string {
    if (isMenuCategoryId(menuCategoryId)) {
      const category =
        this.categories.findMenuCategoryByMenuCategoryId(menuCategoryId);
      return category === null ? '' : category.name;
    }
    if (this.recommended === null) {
      return '';
    }
    const isRecommended = this.recommended.id.eq(menuCategoryId);
    if (!isRecommended) {
      return '';
    }
    return this.recommended.name;
  }

  getFirstCategoryId(): PlanId | RecommendedId | MenuCategoryId {
    if (this.planId !== null) {
      return this.planId;
    }
    if (this.recommended !== null) {
      return this.recommended.id;
    }
    return this.categories.getFirstMenuCategoryId();
  }

  getRecommended(): Recommended | null {
    return this.recommended;
  }

  hasMenuItem(itemId: MenuItemId | PlanId): boolean {
    return this.categories.hasMenuItem(itemId);
  }

  isVisibleDefault(): boolean {
    return [this.isVisibleAlways(), this.isHiddenOnlyDuringCourse()].some(
      (v) => v
    );
  }

  isVisibleDuringCourse(): boolean {
    return [this.isVisibleAlways(), this.planId !== null].some((v) => v);
  }

  private isVisibleAlways(): boolean {
    return this.displayCustomerApp === 'visibleAlways';
  }

  private isHiddenOnlyDuringCourse(): boolean {
    return this.displayCustomerApp === 'hiddenOnlyDuringCourse';
  }
}
