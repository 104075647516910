import { Payment } from '../../../models/identity/identity';
import { PaymentResponse } from './identity.response';

export function adaptPayment(res: PaymentResponse): Payment {
  return {
    id: res.id,
    type: res.type,
    provider: res.provider,
    providerId: res.providerId ?? '',
  };
}
