import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppCommands } from './app.commands';
import { AppComponent } from './app.component';
import { AppQueries } from './app.queries';
import { CocktailInterceptor } from './lib/intercept/cocktail/cocktail-interceptor';
import {
  bookTypeFeatureName,
  bookTypeReducer,
} from './pages/book-type-page/store/book-type.reducer';
import {
  orderHistoryFeatureName,
  orderHistoryReducer,
} from './pages/order-history-page/store/order-history.reducer';
import {
  orderListFeatureName,
  orderListReducer,
} from './pages/order-list-page/store/order-list.reducer';
import {
  payByCardFeatureName,
  payByCardReducer,
} from './pages/pay-by-card-page/store/pay-by-card.reducer';
import {
  payByCashFeatureName,
  payByCashReducer,
} from './pages/pay-by-cash-page/store/pay-by-cash.reducer';
import { payFeatureName, payReducer } from './pages/pay-page/store/pay.reducer';
import {
  paymentDetailFeatureName,
  paymentDetailReducer,
} from './pages/payment-detail-page/store/payment-detail.reducer';
import {
  chargesProcessingFeatureName,
  chargesProcessingReducer,
} from './pages/processing-page/store/charges-processing.reducer';
import {
  payByCardProcessingFeatureName,
  payByCardProcessingReducer,
} from './pages/processing-page/store/pay-by-card-processing.reducer';
import {
  receiptFeatureName,
  receiptReducer,
} from './pages/receipt-page/store/receipt.reducer';
import {
  accountIdsFeatureName,
  accountIdsReducer,
} from './pages/shared/account-ids/store/account-ids.reducer';
import {
  bannerFeatureName,
  bannerReducer,
} from './pages/shared/banner/store/banner.reducer';
import {
  brandFeatureName,
  brandReducer,
} from './pages/shared/brand/store/brand.reducer';
import {
  cartFeatureName,
  cartReducer,
} from './pages/shared/cart/store/cart.reducer';
import { GlobalErrorHandler } from './pages/shared/error-handler/global-error-handler';
import {
  identityFeatureName,
  identityReducer,
} from './pages/shared/identity/store/identity.reducer';
import {
  menuItemFeatureName,
  menuItemReducer,
} from './pages/shared/item/store/menu-item.reducer';
import {
  loadingFeatureName,
  loadingReducer,
} from './pages/shared/loading/store/loading.reducer';
import {
  locationFeatureName,
  locationReducer,
} from './pages/shared/location/store/location.reducer';
import {
  isNestSupportedFeatureName,
  isNestSupportedReducer,
} from './pages/shared/nest/store/is-nest-supported.reducer';
import {
  nowFeatureName,
  nowReducer,
} from './pages/shared/now/store/now.reducer';
import {
  planFeatureName,
  planReducer,
} from './pages/shared/plan/store/plan.reducer';
import {
  sessionFeatureName,
  sessionReducer,
} from './pages/shared/session/store/session.reducer';
import { SharedModule } from './pages/shared/shared.module';
import {
  soldOutFeatureName,
  soldOutReducer,
} from './pages/shared/sold-out/store/sold-out.reducer';
import {
  timeLimitBarFeatureName,
  timeLimitBarReducer,
} from './pages/shared/time-limit-bar/store/time-limit-bar.reducer';
import {
  specialFeatureName,
  specialReducer,
} from './pages/special-page/store/special.reducer';

declare global {
  interface Window {
    analytics: any;
    Sentry: any;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    AppRoutingModule,
    MatDialogModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreModule.forFeature(bannerFeatureName, bannerReducer),
    StoreModule.forFeature(brandFeatureName, brandReducer),
    StoreModule.forFeature(bookTypeFeatureName, bookTypeReducer),
    StoreModule.forFeature(cartFeatureName, cartReducer),
    StoreModule.forFeature(planFeatureName, planReducer),
    StoreModule.forFeature(identityFeatureName, identityReducer),
    StoreModule.forFeature(loadingFeatureName, loadingReducer),
    StoreModule.forFeature(locationFeatureName, locationReducer),
    StoreModule.forFeature(nowFeatureName, nowReducer),
    StoreModule.forFeature(menuItemFeatureName, menuItemReducer),
    StoreModule.forFeature(orderHistoryFeatureName, orderHistoryReducer),
    StoreModule.forFeature(orderListFeatureName, orderListReducer),
    StoreModule.forFeature(receiptFeatureName, receiptReducer),
    StoreModule.forFeature(payFeatureName, payReducer),
    StoreModule.forFeature(paymentDetailFeatureName, paymentDetailReducer),
    StoreModule.forFeature(payByCardFeatureName, payByCardReducer),
    StoreModule.forFeature(
      payByCardProcessingFeatureName,
      payByCardProcessingReducer
    ),
    StoreModule.forFeature(
      chargesProcessingFeatureName,
      chargesProcessingReducer
    ),
    StoreModule.forFeature(payByCashFeatureName, payByCashReducer),
    StoreModule.forFeature(sessionFeatureName, sessionReducer),
    StoreModule.forFeature(soldOutFeatureName, soldOutReducer),
    StoreModule.forFeature(specialFeatureName, specialReducer),
    StoreModule.forFeature(timeLimitBarFeatureName, timeLimitBarReducer),
    StoreModule.forFeature(accountIdsFeatureName, accountIdsReducer),
    StoreModule.forFeature(isNestSupportedFeatureName, isNestSupportedReducer),
    SharedModule,
  ],
  providers: [
    AppCommands,
    AppQueries,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CocktailInterceptor,
      multi: true,
      deps: [],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        const scrollEvent = e as Scroll;
        of(scrollEvent)
          .pipe(delay(100)) // Viewの更新を待つ必要があるため遅延させる
          .subscribe((v) => {
            if (v.position) {
              viewportScroller.scrollToPosition(v.position);
            } else {
              viewportScroller.scrollToPosition([0, 0]);
            }
          });
      });
  }
}
