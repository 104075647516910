import { createAction, union } from '@ngrx/store';
import { AccountIds } from 'src/app/models/accounts/account-ids';

export const storeAccountIds = createAction(
  'AccountsActionIds storeAccountIds',
  (payload: AccountIds) => ({ payload })
);

const all = union({
  storeAccountIds,
});

export type AccountsActionsUnion = typeof all;
