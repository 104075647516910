import { createAction, union } from '@ngrx/store';

export const storeIsProcessingNotification = createAction(
  'TimeLimitBarActions storeIsProcessingNotification',
  (payload: boolean) => ({ payload })
);

const all = union({
  storeIsProcessingNotification,
});

export type TimeLimitBarActionsUnion = typeof all;
