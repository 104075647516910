import { createAction, union } from '@ngrx/store';

import { Special } from '../../../models/special/special';

export const storeSpecial = createAction(
  'SpecialActions storeSpecial',
  (payload: Special) => ({ payload })
);

export const clearSpecial = createAction('SpecialActions clearSpecial');

const all = union({
  storeSpecial,
  clearSpecial,
});

export type SpecialActionsUnion = typeof all;
