import { PlanItemController } from '../../../models/plan/plan-item-controller';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { PlanItemControllerResponse } from './plan-item-contoroller.response';

export function adaptPlanItemController(
  res: PlanItemControllerResponse
): PlanItemController {
  return {
    name: res.fields.name,
    delay: res.fields.delay,
    item: adaptMenuItem(res.fields.item),
  };
}
