import { MenuBook } from '../menu-book/menu-book';
import { Banner } from './banner';

export class Banners {
  constructor(private readonly list: Banner[]) {}

  selectRandomizedItem(
    menuBook: MenuBook,
    isDuringExpandMenu: boolean
  ): Banner | null {
    const displayableBannerList = this.selectDisplayableBannerList(
      menuBook,
      isDuringExpandMenu
    );
    const randomCount = Math.floor(
      Math.random() * displayableBannerList.length
    );
    /**
     * バナーは表示可能なものがない場合でもエラーにしないという仕様となっている為
     * エラーハンドリングはしない
     */
    const displayableBanner = displayableBannerList[randomCount];
    if (displayableBanner === undefined) {
      return null;
    }
    return displayableBanner;
  }

  selectDisplayableBannerList(
    menuBook: MenuBook,
    isDuringExpandMenu: boolean
  ): Banner[] {
    return this.list.filter((v) => {
      let bookType;
      if (v.isExternalLinkBanner) {
        return true;
      }
      if (v.item === null) {
        return false;
      }
      try {
        bookType = menuBook.bookTypes.findBookTypeByMenuItemId(v.item.id);
      } catch (e) {
        /**
         * 設定ミスがある場合、商品IDからブックタイプを探せずエラーになるが、処理を継続させたいので握り潰す。
         *
         * @TODO trackError() を入れても良いが、以下理由で一旦見送りました。
         * - 大量にエラーが出てしまう可能性がある
         * - 致命的なエラーではないと考えられる
         */
        return false;
      }
      return isDuringExpandMenu
        ? bookType.isVisibleDuringCourse()
        : bookType.isVisibleDefault();
    });
  }

  *[Symbol.iterator](): IterableIterator<Banner> {
    for (const v of this.list) {
      yield v;
    }
  }
}
