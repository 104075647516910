import { MenuItem } from '../../../models/menu-item/menu-item';
import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { adaptImage } from '../image/adapt-image';
import { MenuItemResponse } from './menu-item.response';

export function adaptMenuItem(res: MenuItemResponse): MenuItem {
  return {
    id: new MenuItemId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    namePrint: res.fields.name_print,
    cardDescription: res.fields.card_description ?? '',
    image: adaptImage(res.fields.image),
    cmsMetadata: adaptCMSMetadata(res),
  };
}
