import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { isNestSupportedFeatureName } from './is-nest-supported.reducer';
import { IsNestSupportedState } from './is-nest-supported.state';

const selectFeature = createFeatureSelector<IsNestSupportedState>(
  isNestSupportedFeatureName
);

export const selectIsNestSupported = pipe(
  select(createSelector(selectFeature, (v) => v))
);
