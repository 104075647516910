import { createAction, union } from '@ngrx/store';

import { SoldOuts } from '../../../../models/sold-out/sold-outs';

export const storeSoldOuts = createAction(
  'SoldOutActions storeSoldOuts',
  (payload: SoldOuts) => ({ payload })
);

const all = union({
  storeSoldOuts,
});

export type SoldOutActionsUnion = typeof all;
