import { AreaItemGroup } from '../../../models/menu-category/area-item-group';
import { AreaItemGroupId } from '../../../models/menu-category/area-item-group-id';
import { MenuItem } from '../../../models/menu-item/menu-item';
import { Plan } from '../../../models/plan/plan';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { isMenuItemResponse } from '../menu-item/is-menu-item-response';
import { adaptPlan } from '../plan/adapt-plan';
import { isSetItemResponse } from '../plan/is-set-item-response';
import { AreaItemGroupResponse } from './area-Item-group.response';

export function adaptAreaItemGroup(res: AreaItemGroupResponse): AreaItemGroup {
  const items = res.fields.items.reduce((acc, cur) => {
    if (isMenuItemResponse(cur)) {
      acc.push(adaptMenuItem(cur));
    } else if (isSetItemResponse(cur)) {
      acc.push(adaptPlan(cur));
    }
    return acc;
  }, [] as Array<MenuItem | Plan>);
  return {
    id: new AreaItemGroupId(res.sys.id),
    name: res.fields.name,
    items,
  };
}
