import { IdentityTimestamps } from '../../../models/identity/identity';
import { GetTimestampsResponse } from './get-timestamps.response';

export function adaptIdentityTimestamp(
  res: GetTimestampsResponse
): IdentityTimestamps {
  const identity = res.result.identity;
  return {
    orderStopTime: identity.orderStopAt ? new Date(identity.orderStopAt) : null,
    endTime: identity.endAt ? new Date(identity.endAt) : null,
  };
}
