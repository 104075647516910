import { createReducer, on } from '@ngrx/store';

import {
  initPayByCardState,
  PayByCardActions,
  storeCardCvcErrorMessage,
  storeCardCvcValidationResult,
  storeCardExpiryErrorMessage,
  storeCardExpiryValidationResult,
  storeCardNumberErrorMessage,
  storeCardNumberValidationResult,
  storeChargeId,
  storeChargesErrorType,
  storeInvoiceDetail,
  storeIsProcessing,
  storeIsProcessingPayByCard,
  storePaymentMethodResult,
  storeWorkflowExecutionId,
} from './pay-by-card.actions';
import { initialState } from './pay-by-card.initial-state';
import { PayByCardState } from './pay-by-card.state';

export function payByCardReducer(
  payState: PayByCardState,
  action: PayByCardActions
): PayByCardState {
  type S = PayByCardState;
  const reducer = createReducer<S>(
    initialState,
    on(initPayByCardState, (state): S => {
      return {
        ...state,
        isCardNumberEmpty: true,
        isCardExpiryEmpty: true,
        isCardCvcEmpty: true,
      };
    }),
    on(storeCardNumberValidationResult, (state, { payload }): S => {
      return {
        ...state,
        isCardNumberCompleted: payload.isCompleted,
        isCardNumberEmpty: payload.isEmpty,
        cardNumberErrorMessage: payload.errorMessage,
      };
    }),
    on(storeCardNumberErrorMessage, (state, { payload }): S => {
      return { ...state, cardNumberErrorMessage: payload };
    }),
    on(storeCardExpiryValidationResult, (state, { payload }): S => {
      return {
        ...state,
        isCardExpiryCompleted: payload.isCompleted,
        isCardExpiryEmpty: payload.isEmpty,
        cardExpiryErrorMessage: payload.errorMessage,
      };
    }),
    on(storeCardExpiryErrorMessage, (state, { payload }): S => {
      return { ...state, cardExpiryErrorMessage: payload };
    }),
    on(storeCardCvcValidationResult, (state, { payload }): S => {
      return {
        ...state,
        isCardCvcCompleted: payload.isCompleted,
        isCardCvcEmpty: payload.isEmpty,
        cardCvcErrorMessage: payload.errorMessage,
      };
    }),
    on(storeCardCvcErrorMessage, (state, { payload }): S => {
      return { ...state, cardCvcErrorMessage: payload };
    }),
    on(storeIsProcessing, (state, { payload }): S => {
      return { ...state, isProcessing: payload };
    }),
    on(storePaymentMethodResult, (state, { payload }): S => {
      return { ...state, paymentMethodResult: payload };
    }),
    on(storeIsProcessingPayByCard, (state, { payload }): S => {
      return { ...state, isProcessingPayByCard: payload };
    }),
    on(storeInvoiceDetail, (state, { payload }): S => {
      return { ...state, invoiceDetail: payload };
    }),
    on(storeWorkflowExecutionId, (state, { payload }): S => {
      return { ...state, workflowExecutionId: payload };
    }),
    on(storeChargeId, (state, { payload }): S => {
      return { ...state, chargeId: payload };
    }),
    on(storeChargesErrorType, (state, { payload }): S => {
      return { ...state, chargesErrorType: payload };
    })
  );
  return reducer(payState, action);
}

export const payByCardFeatureName = 'payByCardFeature';
