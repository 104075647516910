import { Injectable } from '@angular/core';

import { ContentfulClient } from '../../lib/contentful/contentful-client';
import { ContentfulError } from '../../lib/contentful/contentful-error';
import { adaptPlanDetail } from '../../lib/contentful/plan/adapt-plan-detail';
import { PlanResponse } from '../../lib/contentful/plan/plan.response';
import { ApiLocationId } from '../location/api-location-id';
import { PlanDetail } from './plan-detail';
import { PlanId } from './plan-id';

@Injectable({ providedIn: 'root' })
export class PlanRepository {
  constructor(private readonly client: ContentfulClient) {}

  async getPlan(
    planId: PlanId,
    apiLocationId: ApiLocationId
  ): Promise<PlanDetail> {
    let res: PlanResponse | null = null;
    try {
      res = await this.client.listPlan(planId, apiLocationId);
    } catch (e) {
      throw new ContentfulError(e);
    }
    return adaptPlanDetail(res);
  }
}
