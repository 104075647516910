import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';

import { ApiLocationId } from '../../models/location/api-location-id';
import { retryByErrorStatus } from '../../pages/shared/retry-by-error-status/retry-by-error-status';
import { AccountsResponse } from './accounts/accounts.response';
import { GetIdentityRequest } from './identity/identity.request';
import { GetIdentityResponse } from './identity/identity.response';

@Injectable({ providedIn: 'root' })
export class MojitoClient {
  private readonly errorRetryCount = 5;
  private readonly errorStatusList = [502, 504];
  constructor(private client: HttpClient) {}

  /**
   * identity取得
   */
  async getIdentity(request: GetIdentityRequest): Promise<GetIdentityResponse> {
    const params = new HttpParams().set(
      'locationId',
      request.locationId.toString()
    );
    return await firstValueFrom(
      this.client
        .get<GetIdentityResponse>(
          `api/sessions/${request.sessionId.toString()}/identities/${request.identityId.toString()}`,
          { params }
        )
        .pipe(
          retryByErrorStatus<GetIdentityResponse>(
            this.errorRetryCount,
            this.errorStatusList
          )
        )
    );
  }

  getAccounts(apiLocationId: ApiLocationId): Observable<AccountsResponse> {
    return this.client.get<AccountsResponse>(
      `/api/accounts?locationId=${apiLocationId}`
    );
  }
}
