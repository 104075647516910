import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { ModifierId } from '../../../models/modifier/modifier-id';
import { SoldOuts } from '../../../models/sold-out/sold-outs';
import { VariationId } from '../../../models/variation/variation-id';
import { SoldOutResponse } from './sold-out.response';

export function adaptSoldOuts(res: SoldOutResponse): SoldOuts {
  const items: MenuItemId[] = Object.keys(res.items).map(
    (v) => new MenuItemId(v)
  );
  const variations: VariationId[] = Object.keys(res.variations).map(
    (v) => new VariationId(v)
  );
  const modifiers: ModifierId[] = Object.keys(res.modifiers).map(
    (v) => new ModifierId(v)
  );

  return {
    itemIds: items,
    variationIds: variations,
    modifierIds: modifiers,
  };
}
