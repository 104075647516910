import { GetIdentityResponse } from '../../../../lib/mojito/identity/identity.response';

export const getIdentityResponseMock: GetIdentityResponse = {
  status: 'ok',
  result: {
    identity: {
      id: 'trial',
      total: { currencyUnit: 'JPY', amount: 0 },
      events: [],
      orders: [],
      discounts: [],
      invoices: [],
      createdAt: 1679270272216,
    },
  },
};
