import { Session } from '../../../models/session/session';
import { SessionId } from '../../../models/session/session-id';
import { SessionResponse } from './session.response';

export function adaptSession(res: SessionResponse): Session {
  return new Session({
    sessionId: new SessionId(res.result.session.id),
    seats: res.result.session.seats,
    tableNameList: res.result.session.destinations,
  });
}
