import { Injectable } from '@angular/core';

import { CocktailClient } from '../../lib/cocktail/cocktail-client';
import { adaptIdentities } from '../../lib/cocktail/identity/adapt-identities';
import { adaptIdentityTimestamp } from '../../lib/cocktail/identity/adapt-identity-timestamp';
import { Response } from '../../lib/cocktail/response';
import { adaptIdentityDetail } from '../../lib/mojito/identity/adapt-identity-detail';
import { MojitoClient } from '../../lib/mojito/mojito-client';
import { ApiLocationId } from '../location/api-location-id';
import { SessionId } from '../session/session-id';
import { Identity, IdentityDetail, IdentityTimestamps } from './identity';
import { IdentityId } from './identity-id';

@Injectable({ providedIn: 'root' })
export class IdentityRepository {
  constructor(
    private readonly cocktailClient: CocktailClient,
    private readonly mojitoClient: MojitoClient
  ) {}

  async getIdentity(
    sessionId: SessionId,
    identityId: IdentityId,
    apiLocationId: ApiLocationId
  ): Promise<IdentityDetail> {
    const res = await this.mojitoClient.getIdentity({
      sessionId,
      identityId,
      locationId: apiLocationId,
    });
    return adaptIdentityDetail(res.result.identity);
  }

  async getIdentities(params: {
    sessionId: SessionId;
    locationId: ApiLocationId;
  }): Promise<Identity[]> {
    const identitiesResponse = await this.cocktailClient.getIdentities(params);
    return adaptIdentities(identitiesResponse);
  }

  async getIdentityTimestamps(params: {
    sessionId: SessionId;
    identityId: IdentityId;
    locationId: ApiLocationId;
  }): Promise<IdentityTimestamps> {
    const res = await this.cocktailClient.getTimestamps(params);
    return adaptIdentityTimestamp(res);
  }

  async postEndIdentity(inputModel: {
    sessionId: SessionId;
    locationId: ApiLocationId;
    identityId: IdentityId;
    cuid: string;
  }): Promise<Response> {
    return await this.cocktailClient.postEndIdentity(inputModel);
  }
}
