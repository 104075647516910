import { createAction, union } from '@ngrx/store';

import {
  Identity,
  IdentityDetail,
  IdentityTimestamps,
} from '../../../../models/identity/identity';
import { OrderHistories } from '../../../../models/order/order-histories';

export const storeIdentity = createAction(
  'IdentityActions storeIdentity',
  (payload: Identity) => ({
    payload,
  })
);

export const storeIdentityDetail = createAction(
  'IdentityActions storeIdentityDetail',
  (payload: IdentityDetail) => ({
    payload,
  })
);

export const storeIdentityTimestamps = createAction(
  'IdentityActions storeIdentityTimestamps',
  (payload: IdentityTimestamps) => ({
    payload,
  })
);

export const storeOrderHistories = createAction(
  'IdentityActions storeOrderHistories',
  (payload: OrderHistories | null) => ({
    payload,
  })
);

const all = union({
  storeIdentity,
  storeIdentityDetail,
  storeIdentityTimestamps,
  storeOrderHistories,
});

export type IdentityActionsUnion = typeof all;
