import { PaymentIntent } from '../../../models/payment-intent/payment-intent';
import { asPaymentIntentId } from '../../../models/payment-intent/payment-intent-id';
import { mapOrElse } from '../../../utils/map-or-else';
import { adaptCharge } from './adapt-charge';
import { PaymentIntentResponse } from './identity.response';

export function adaptPaymentIntent(v: PaymentIntentResponse): PaymentIntent {
  return {
    id: asPaymentIntentId(v.id),
    charges: mapOrElse(v.charges, (v) => adaptCharge(v)),
  };
}
