import { invalidInstantiation } from './invalid-instantiation';
import { PrimitiveValue } from './primitive-value';

export class NotEmptyStringValue extends PrimitiveValue<string> {
  protected value: string;

  constructor(value: string) {
    // ランタイム上での強引な undefined, null 混入も防ぐために検証。
    if ((value as any) === undefined) {
      throw new TypeError(
        `${invalidInstantiation}: NotEmptyStringValue was given undefined`
      );
    }
    if ((value as any) === null) {
      throw new TypeError(
        `${invalidInstantiation}: NotEmptyStringValue was given null`
      );
    }
    if (value.length === 0) {
      throw new TypeError(
        `${invalidInstantiation}: NotEmptyStringValue was given blank string`
      );
    }

    super(value);
    this.value = value;
  }

  toString(): string {
    return this.value;
  }
}
