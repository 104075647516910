import { LocationId } from '../../../models/location/location-id';
import { Session } from '../../../models/session/session';
import { EventFooterOrderHistoryClickedItem } from './event-footer-order-history-clicked-item';
import { EventFooterOrderHistoryClickedSendItem } from './event-footer-order-history-clicked-send-item';

interface Params {
  locationId: LocationId;
  locationName: string;
  session: Session;
  clickedItem: EventFooterOrderHistoryClickedItem;
}

export class EventFooterOrderHistoryClickedInputModel {
  private readonly locationId: LocationId;
  private readonly locationName: string;
  private readonly session: Session;
  private readonly clickedItem: EventFooterOrderHistoryClickedItem;

  constructor(params: Params) {
    this.locationId = params.locationId;
    this.locationName = params.locationName;
    this.session = params.session;
    this.clickedItem = params.clickedItem;
  }

  asRequest(): EventFooterOrderHistoryClickedSendItem {
    return {
      restaurants_id: this.locationId.toString(),
      restaurant_name: this.locationName,
      sessionId: this.session.getSessionId().toString(),
      table_names: this.session.getTableNameList(),
      area_name: this.clickedItem.areaName,
      page_type: this.clickedItem.pageType,
      page_name: this.clickedItem.pageName,
    };
  }
}
