import { Injectable } from '@angular/core';

import { EventExternalLinkClickedInputModel } from '../../lib/event-logger/event-external-link-clicked/event-external-link-clicked-input-model';
import { EventExternalLinkClickedSendItem } from '../../lib/event-logger/event-external-link-clicked/event-external-link-clicked-send-item';
import { EventFooterOrderHistoryClickedInputModel } from '../../lib/event-logger/event-footer-order-history-clicked/event-footer-order-history-clicked-input-model';
import { EventFooterOrderHistoryClickedSendItem } from '../../lib/event-logger/event-footer-order-history-clicked/event-footer-order-history-clicked-send-item';
import { EventLoggerClient } from '../../lib/event-logger/event-logger-client';
import { EventOrderCompleteExternalLinkClickedInputModel } from '../../lib/event-logger/event-order-complete-external-link-clicked/event-order-complete-clicked-input-model';
import { EventOrderCompleteExternalLinkClickedSendItem } from '../../lib/event-logger/event-order-complete-external-link-clicked/event-order-complete-external-link-clicked-send-item';
import { EventOrderCompleted } from '../../lib/event-logger/event-order-completed/event-order-completed';
import { EventOrderCompletedInputModel } from '../../lib/event-logger/event-order-completed/event-order-completed-input-model';
import { EventPayButtonClickedInputModel } from '../../lib/event-logger/event-pay-button-clicked/event-pay-button-clicked-input-model';
import { EventPayButtonClickedSendItem } from '../../lib/event-logger/event-pay-button-clicked/event-pay-button-clicked-send-item';
import { EventPayByCallStaffClickedInputModel } from '../../lib/event-logger/event-pay-by-call-staff-clicked/event-pay-by-call-staff-clicked-input-model';
import { EventPayByCallStaffClickedSendItem } from '../../lib/event-logger/event-pay-by-call-staff-clicked/event-pay-by-call-staff-clicked-send-item';
import { EventPayByCardExecuteClickedInputModel } from '../../lib/event-logger/event-pay-by-card-execute-clicked/event-pay-by-card-execute-clicked-input-model';
import { EventPayByCardExecuteClickedSendItem } from '../../lib/event-logger/event-pay-by-card-execute-clicked/event-pay-by-card-execute-clicked-send-item';
import { EventPayByCreditCardClickedInputModel } from '../../lib/event-logger/event-pay-by-credit-card-clicked/event-pay-by-credit-card-clicked-input-model';
import { EventPayByCreditCardClickedSendItem } from '../../lib/event-logger/event-pay-by-credit-card-clicked/event-pay-by-credit-card-clicked-send-item';
import { EventProceedToPaymentByDialogClickedInputModel } from '../../lib/event-logger/event-proceed-to-payment-by-dialog-clicked/event-proceed-to-payment-by-dialog-clicked-input-model';
import { EventProceedToPaymentByDialogClickedSendItem } from '../../lib/event-logger/event-proceed-to-payment-by-dialog-clicked/event-proceed-to-payment-by-dialog-clicked-send-item';
import { EventProceedToPaymentClickedInputModel } from '../../lib/event-logger/event-proceed-to-payment-clicked/event-proceed-to-payment-clicked-input-model';
import { EventProceedToPaymentClickedSendItem } from '../../lib/event-logger/event-proceed-to-payment-clicked/event-proceed-to-payment-clicked-send-item';
import { EventProductAdded } from '../../lib/event-logger/event-product-added/event-product-added';
import { EventProductAddedInputModel } from '../../lib/event-logger/event-product-added/event-product-added-input-model';
import { EventProductClicked } from '../../lib/event-logger/event-product-clicked/event-product-clicked';
import { EventProductClickedInputModel } from '../../lib/event-logger/event-product-clicked/event-product-clicked-input-model';
import { EventProductListViewed } from '../../lib/event-logger/event-product-list-viewed/event-product-list-viewed';
import { EventProductListViewedModel } from '../../lib/event-logger/event-product-list-viewed/event-product-list-viewed-model';
import { EventRecommendedExternalLinkClicked } from '../../lib/event-logger/event-recommended-external-link-clicked/event-recommended-external-link-clicked';
import { EventRecommendedExternalLinkClickedInputModel } from '../../lib/event-logger/event-recommended-external-link-clicked/event-recommended-external-link-clicked-input-model';
import { EventStoryExternalLinkClickedSendItem } from '../../lib/event-logger/event-story-external-link-clicked/event-story-external-link-clicked-send-item';
import { EventStoryExternalLinkClickedInputModel } from '../../lib/event-logger/event-story-external-link-clicked/event-story-product-clicked-input-model';
import { EventUseDiscountCouponsClickedInputModel } from '../../lib/event-logger/event-use-discount-coupons-clicked/event-use-discount-coupons-clicked-input-model';
import { EventUseDiscountCouponsClickedSendItem } from '../../lib/event-logger/event-use-discount-coupons-clicked/event-use-discount-coupons-clicked-send-item';

@Injectable({ providedIn: 'root' })
export class EventLoggerRepository {
  constructor(private readonly eventLoggerClient: EventLoggerClient) {}

  trackEventOrderCompleted(eventModel: EventOrderCompletedInputModel): void {
    this.eventLoggerClient.trackLog<EventOrderCompleted>(
      'Order Completed',
      eventModel.asRequest()
    );
  }

  trackEventProductAdded(eventModel: EventProductAddedInputModel): void {
    this.eventLoggerClient.trackLog<EventProductAdded>(
      'Product Added',
      eventModel.asRequest()
    );
  }

  trackEventProductListViewed(eventModel: EventProductListViewedModel): void {
    this.eventLoggerClient.trackLog<EventProductListViewed>(
      'Product List Viewed',
      eventModel.asRequest()
    );
  }

  trackEventProductClicked(eventModel: EventProductClickedInputModel): void {
    this.eventLoggerClient.trackLog<EventProductClicked>(
      'Product Clicked',
      eventModel.asRequest()
    );
  }

  trackEventStoryExternalLinkClicked(
    eventModel: EventStoryExternalLinkClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventStoryExternalLinkClickedSendItem>(
      'Story External Link Clicked',
      eventModel.asRequest()
    );
  }

  trackEventRecommendedExternalLinkClicked(
    eventModel: EventRecommendedExternalLinkClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventRecommendedExternalLinkClicked>(
      'Recommended ExternalLink Clicked',
      eventModel.asRequest()
    );
  }

  trackEventOrderCompleteExternalLinkClicked(
    eventModel: EventOrderCompleteExternalLinkClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventOrderCompleteExternalLinkClickedSendItem>(
      'Order Complete ExternalLink Clicked',
      eventModel.asRequest()
    );
  }

  trackEventPayButtonClicked(
    eventModel: EventPayButtonClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventPayButtonClickedSendItem>(
      'Pay Button Clicked',
      eventModel.asRequest()
    );
  }

  trackEventPayByCallStaffClicked(
    eventModel: EventPayByCallStaffClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventPayByCallStaffClickedSendItem>(
      'Pay By Call Staff Clicked',
      eventModel.asRequest()
    );
  }

  trackEventPayByCreditCardClicked(
    eventModel: EventPayByCreditCardClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventPayByCreditCardClickedSendItem>(
      'Pay By Credit Card Clicked',
      eventModel.asRequest()
    );
  }

  trackEventProceedToPaymentByDialogClicked(
    eventModel: EventProceedToPaymentByDialogClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventProceedToPaymentByDialogClickedSendItem>(
      'Proceed To Payment By Dialog Clicked',
      eventModel.asRequest()
    );
  }

  trackEventFooterOrderHistoryClicked(
    eventModel: EventFooterOrderHistoryClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventFooterOrderHistoryClickedSendItem>(
      'Footer Order History Clicked',
      eventModel.asRequest()
    );
  }

  trackEventProceedToPaymentClicked(
    eventModel: EventProceedToPaymentClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventProceedToPaymentClickedSendItem>(
      'Proceed To Payment Clicked',
      eventModel.asRequest()
    );
  }

  trackEventPayByCardExecuteClicked(
    eventModel: EventPayByCardExecuteClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventPayByCardExecuteClickedSendItem>(
      'Pay By Card Execute Clicked',
      eventModel.asRequest()
    );
  }

  trackEventUseDiscountCouponsClicked(
    eventModel: EventUseDiscountCouponsClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventUseDiscountCouponsClickedSendItem>(
      'Use Discount Coupons Clicked',
      eventModel.asRequest()
    );
  }

  trackEventExternalLinkClicked(
    eventModel: EventExternalLinkClickedInputModel
  ): void {
    this.eventLoggerClient.trackLog<EventExternalLinkClickedSendItem>(
      'External link Clicked',
      eventModel.asRequest()
    );
  }
}
