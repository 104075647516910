import { PayByCardState } from './pay-by-card.state';

export const initialState: PayByCardState = {
  isProcessing: false,
  isCardNumberCompleted: false,
  isCardNumberEmpty: false,
  cardNumberErrorMessage: '',
  isCardExpiryCompleted: false,
  isCardExpiryEmpty: false,
  cardExpiryErrorMessage: '',
  isCardCvcCompleted: false,
  isCardCvcEmpty: false,
  cardCvcErrorMessage: '',
  paymentMethodResult: null,
  isProcessingPayByCard: false,
  invoiceDetail: null,
  workflowExecutionId: null,
  chargeId: null,
  chargesErrorType: null,
};
