import { IdentityDetail } from '../../../models/identity/identity';
import { IdentityId } from '../../../models/identity/identity-id';
import { Price } from '../../../models/price';
import { adaptDiscount } from './adapt-discount';
import { adaptInvoice } from './adapt-invoice';
import { adaptOrderHistoryDetails } from './adapt-order-history-details';
import { IdentityResponse } from './identity.response';

export function adaptIdentityDetail(res: IdentityResponse): IdentityDetail {
  const invoices = res.invoices.map((invoice) => {
    return adaptInvoice(invoice);
  });
  return new IdentityDetail({
    id: new IdentityId(res.id),
    total: new Price(res.total.amount),
    orders: adaptOrderHistoryDetails(res.orders),
    discount:
      res.discounts[0] !== undefined ? adaptDiscount(res.discounts[0]) : null,
    invoices,
    startTime: res.createdAt ? new Date(res.createdAt) : null,
    orderStopTime: res.orderStopAt ? new Date(res.orderStopAt) : null,
    endTime: res.endAt ? new Date(res.endAt) : null,
  });
}
