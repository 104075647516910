import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'preparing',
    loadChildren: () =>
      import('./pages/preparing-page/preparing.module').then(
        (m) => m.PreparingModule
      ),
  },
  {
    path: 'splash',
    loadChildren: () =>
      import('./pages/splash-page/splash.module').then((m) => m.SplashModule),
  },
  {
    path: 'book-type',
    loadChildren: () =>
      import('./pages/book-type-page/book-type.module').then(
        (m) => m.BookTypeModule
      ),
  },
  {
    path: 'special',
    loadChildren: () =>
      import('./pages/special-page/special.module').then(
        (m) => m.SpecialModule
      ),
  },
  {
    path: 'menu-item-detail',
    loadChildren: () =>
      import('./pages/menu-item-detail-page/menu-item-detail.module').then(
        (m) => m.MenuItemDetailModule
      ),
  },
  {
    path: 'set-item-detail',
    loadChildren: () =>
      import('./pages/set-item-detail-page/set-item-detail.module').then(
        (m) => m.SetItemDetailModule
      ),
  },
  {
    path: 'static',
    loadChildren: () =>
      import('./pages/static-page/static.module').then((m) => m.StaticModule),
  },
  {
    path: 'order-list',
    loadChildren: () =>
      import('./pages/order-list-page/order-list.module').then(
        (m) => m.OrderListModule
      ),
  },
  {
    path: 'order-complete',
    loadChildren: () =>
      import('./pages/order-complete-page/order-complete.module').then(
        (m) => m.OrderCompleteModule
      ),
  },

  {
    path: 'order-history',
    loadChildren: () =>
      import('./pages/order-history-page/order-history.module').then(
        (m) => m.OrderHistoryModule
      ),
  },
  {
    path: 'pay',
    loadChildren: () =>
      import('./pages/pay-page/pay.module').then((m) => m.PayModule),
  },
  {
    path: 'pay-order-detail',
    loadChildren: () =>
      import('./pages/pay-order-detail-page/pay-order-detail.module').then(
        (m) => m.PayOrderDetailModule
      ),
  },
  {
    path: 'pay-by-cash',
    loadChildren: () =>
      import('./pages/pay-by-cash-page/pay-by-cash.module').then(
        (m) => m.PayByCashModule
      ),
  },
  {
    path: 'pay-by-card',
    loadChildren: () =>
      import('./pages/pay-by-card-page/pay-by-card.module').then(
        (m) => m.PayByCardModule
      ),
  },
  {
    path: 'processing-page',
    loadChildren: () =>
      import('./pages/processing-page/processing.module').then(
        (m) => m.ProcessingModule
      ),
  },
  {
    path: 'staff-confirm',
    loadChildren: () =>
      import('./pages/staff-confirm-page/staff-confirm.module').then(
        (m) => m.StaffConfirmModule
      ),
  },
  {
    path: 'leaving',
    loadChildren: () =>
      import('./pages/leaving-page/leaving.module').then(
        (m) => m.LeavingModule
      ),
  },
  {
    path: 'payment-detail',
    loadChildren: () =>
      import('./pages/payment-detail-page/payment-detail.module').then(
        (m) => m.PaymentDetailModule
      ),
  },
  {
    path: 'receipt',
    loadChildren: () =>
      import('./pages/receipt-page/receipt-input-page/receipt.module').then(
        (m) => m.ReceiptModule
      ),
  },
  {
    path: 'receipt-confirm',
    loadChildren: () =>
      import(
        './pages/receipt-page/receipt-confirm-page/receipt-confirm.module'
      ).then((m) => m.ReceiptConfirmModule),
  },
  {
    path: 'receipt-end',
    loadChildren: () =>
      import('./pages/receipt-page/receipt-end-page/receipt-end.module').then(
        (m) => m.ReceiptEndModule
      ),
  },
  {
    path: 'receivable-page',
    loadChildren: () =>
      import('./pages/receivable-page/receivable-page.module').then(
        (m) => m.ReceivableModule
      ),
  },
  {
    path: 'url-error',
    loadChildren: () =>
      import('./pages/url-error-page/url-error.module').then(
        (m) => m.UrlErrorModule
      ),
  },
  {
    path: 'server-error',
    loadChildren: () =>
      import('./pages/server-error-page/server-error.module').then(
        (m) => m.ServerErrorModule
      ),
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
