import { createAction, union } from '@ngrx/store';
import { WorkflowErrorType } from '../workflow-error.type';

export const storeWorkflowErrorType = createAction(
  'PayByCardProcessingActions storeWorkflowErrorType',
  (payload: WorkflowErrorType | null) => ({ payload })
);

const all = union({
  storeWorkflowErrorType,
});

export type PayByCardProcessingActions = typeof all;
