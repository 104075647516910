import { ModifierSetControllerId } from '../../../../models/location/modifier-set-controller-id';
import { ModifierId } from '../../../../models/modifier/modifier-id';
import { decrementModifier } from './decrement-modifier';
import { incrementModifier } from './increment-modifier';
import { ModifierWithQuantity } from './modifier-with-quantity';
import { SelectedModifier } from './selected-modifier';
import { SelectedModifiers } from './selected-modifiers';
import { toggleModifier } from './toggle-modifier';

export class SelectedModifierSetControllers {
  constructor(private readonly list: SelectedModifiers[]) {}

  exists(): boolean {
    return 0 < this.count();
  }

  getModifiers(): ModifierWithQuantity[] {
    return this.list
      .map((v) => v.modifiers)
      .reduce((acc, crt) => acc.concat(crt));
  }

  countModifiers(): number {
    return this.getModifiers().length;
  }

  findSelectedModifierSetController(
    modifierSetControllerId: ModifierSetControllerId
  ): SelectedModifiers | null {
    const found = this.list.find((v) => {
      return v.modifierSetController.modifierSetControllerId.eq(
        modifierSetControllerId
      );
    });
    return found ? found : null;
  }

  hasModifierSetController(
    modifierSetControllerId: ModifierSetControllerId
  ): boolean {
    return this.list.some((v) => {
      return v.modifierSetController.modifierSetControllerId.eq(
        modifierSetControllerId
      );
    });
  }

  addModifierSetController(
    selectedModifier: SelectedModifier
  ): SelectedModifierSetControllers {
    return new SelectedModifierSetControllers([
      ...this.list,
      {
        modifierSetController: selectedModifier.modifierSetController,
        modifiers: [{ modifier: selectedModifier.modifier, quantity: 1 }],
      },
    ]);
  }

  getModifier(modifierId: ModifierId): ModifierWithQuantity | null {
    const modifiers = this.getModifiers();
    const modifier = modifiers.find((v) => {
      return v.modifier.modifierId.eq(modifierId);
    });
    return modifier ?? null;
  }

  merge(selectedModifier: SelectedModifier): SelectedModifierSetControllers {
    // 単一選択かつ必須の場合、選択済みオプションと入れ替える
    if (
      selectedModifier.modifierSetController.min === 1 &&
      selectedModifier.modifierSetController.max === 1
    ) {
      return this.replaceSelectedModifier(selectedModifier);
    }

    // 複数選択または任意の場合
    return this.mergeSelectedModifier(selectedModifier);
  }

  hasModifier(modifierId: ModifierId): boolean {
    return this.list.some((v) => {
      return v.modifiers.some((selectedModifierWithQuantity) => {
        return selectedModifierWithQuantity.modifier.modifierId.eq(modifierId);
      });
    });
  }

  increment(
    selectedModifier: SelectedModifier
  ): SelectedModifierSetControllers {
    const mergedList = this.list.map((v) => {
      if (
        !v.modifierSetController.modifierSetControllerId.eq(
          selectedModifier.modifierSetController.modifierSetControllerId
        )
      ) {
        return v;
      }
      const filteredModifierMaps = incrementModifier(
        v.modifiers,
        selectedModifier.modifier
      );
      return {
        modifierSetController: v.modifierSetController,
        modifiers: filteredModifierMaps,
      };
    });
    return new SelectedModifierSetControllers(mergedList);
  }

  decrement(
    selectedModifier: SelectedModifier
  ): SelectedModifierSetControllers {
    const mergedList = this.list
      .map((v) => {
        if (
          !v.modifierSetController.modifierSetControllerId.eq(
            selectedModifier.modifierSetController.modifierSetControllerId
          )
        ) {
          return v;
        }
        const filteredModifierMaps = decrementModifier(
          v.modifiers,
          selectedModifier.modifier
        );
        return {
          modifierSetController: v.modifierSetController,
          modifiers: filteredModifierMaps,
        };
      })
      .filter((v) => {
        return v.modifiers.length > 0;
      });
    return new SelectedModifierSetControllers(mergedList);
  }

  *[Symbol.iterator](): IterableIterator<SelectedModifiers> {
    for (const v of this.list) {
      yield v;
    }
  }

  private count(): number {
    return this.list.length;
  }

  private replaceSelectedModifier(
    selectedModifier: SelectedModifier
  ): SelectedModifierSetControllers {
    const list = this.list.map((v) => {
      if (
        !v.modifierSetController.modifierSetControllerId.eq(
          selectedModifier.modifierSetController.modifierSetControllerId
        )
      ) {
        return v;
      }
      return {
        modifierSetController: selectedModifier.modifierSetController,
        modifiers: [{ modifier: selectedModifier.modifier, quantity: 1 }],
      };
    });
    return new SelectedModifierSetControllers(list);
  }

  private mergeSelectedModifier(
    selectedModifier: SelectedModifier
  ): SelectedModifierSetControllers {
    const mergedList = this.list
      .map((v) => {
        if (
          !v.modifierSetController.modifierSetControllerId.eq(
            selectedModifier.modifierSetController.modifierSetControllerId
          )
        ) {
          return v;
        }
        const filteredModifierMaps = toggleModifier(
          v.modifiers,
          selectedModifier.modifier
        );
        return {
          modifierSetController: v.modifierSetController,
          modifiers: filteredModifierMaps,
        };
      })
      .filter((v) => {
        return v.modifiers.length > 0;
      });
    return new SelectedModifierSetControllers(mergedList);
  }
}
