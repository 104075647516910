import { OrderHistories } from '../../../models/order/order-histories';
import { OrderHistory } from '../../../models/order/order-history';
import { OrderHistoryId } from '../../../models/order/order-history-id';
import { OrderItems } from '../../../models/order/order-items';
import { adaptOrderItem } from './adapt-order-item';
import { OrderHistoryResponse } from './get-orders.response';

export function adaptOrderHistories(
  res: OrderHistoryResponse[]
): OrderHistories {
  const list = res.map((v) => {
    return new OrderHistory({
      id: new OrderHistoryId(v.id),
      items: new OrderItems(v.items.map((v) => adaptOrderItem(v))),
      createTime: new Date(v.createdAt),
    });
  });
  return new OrderHistories(list);
}
