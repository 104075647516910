import { createAction, union } from '@ngrx/store';

import { ApiLocationId } from '../../../../models/location/api-location-id';
import { LocationId } from '../../../../models/location/location-id';
import { LocationOfContentful } from '../../../../models/location/location-of-contentful';
import { MenuBook } from '../../../../models/menu-book/menu-book';

export const storeLocationId = createAction(
  'LocationActions storeLocationId',
  (payload: { locationId: LocationId; apiLocationId: ApiLocationId }) => ({
    payload,
  })
);

export const storeLocation = createAction(
  'LocationActions storeLocation',
  (payload: LocationOfContentful) => ({ payload })
);

export const storeMenuBook = createAction(
  'LocationActions storeMenuBook',
  (payload: MenuBook) => ({ payload })
);

const all = union({
  storeLocationId,
  storeLocation,
  storeMenuBook,
});

export type LocationActionsUnion = typeof all;
