import { Asset } from 'contentful';

import { Video } from '../../../models/video';
import { convertToBffMediaPath } from '../convert-to-bff-media-path';

export function adaptVideo(res: Asset): Video {
  return {
    name: res.fields.title,
    imgUrl: convertToBffMediaPath(res.fields.file.url),
  };
}
