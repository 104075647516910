import { Modifier } from '../../../../models/menu-item/modifier';
import { ModifierWithQuantity } from './modifier-with-quantity';

export function incrementModifier(
  selectedModifierList: ModifierWithQuantity[],
  modifier: Modifier
): ModifierWithQuantity[] {
  const found = selectedModifierList.find((v) => {
    return v.modifier.modifierId.eq(modifier.modifierId);
  });

  if (found === undefined) {
    return selectedModifierList.concat({ modifier, quantity: 1 });
  }

  return selectedModifierList.map((v) => {
    if (!v.modifier.modifierId.eq(modifier.modifierId)) {
      return v;
    }
    return {
      modifier: v.modifier,
      quantity: v.quantity + 1,
    };
  });
}
