import { OrderHistoryDetail } from '../../../models/order/order-history-detail';
import { OrderHistoryId } from '../../../models/order/order-history-id';
import { adaptOrderItemDetails } from './adapt-order-item-details';
import { OrderHistoryResponse } from './order-history.response';

export function adaptOrderHistoryDetail(
  res: OrderHistoryResponse
): OrderHistoryDetail {
  return new OrderHistoryDetail({
    id: new OrderHistoryId(res.id),
    items: adaptOrderItemDetails(res.items),
    createTime: new Date(res.createdAt),
  });
}
