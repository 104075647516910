import { Entry } from 'contentful';
import { CMSMetadata } from '../../../models/cms-metadata';

export function adaptCMSMetadata<T>(res: Entry<T>): CMSMetadata {
  return {
    id: res.sys.id,
    space: res.sys.space?.sys.id,
    contentType: res.sys.contentType.sys.id,
    revision: res.sys.revision?.toString(),
    tags: res.metadata.tags.map((tag) => tag.sys.id),
  };
}
