import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterMenuCommands } from './components/footer-menu.commands';
import { FooterMenuComponent } from './components/footer-menu.component';
import { FooterMenuQueries } from './components/footer-menu.queries';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [FooterMenuComponent],
  exports: [FooterMenuComponent],
  providers: [FooterMenuQueries, FooterMenuCommands],
})
export class FooterMenuModule {}
