import { exists } from '../../utils/exists';
import { BookTypeId } from '../book-type/book-type-id';
import { CMSMetadata } from '../cms-metadata';
import { Image } from '../image';
import { RecommendedComment } from '../menu-item/recommended-comment';
import { RelatedItems } from '../menu-item/related-items';
import { Stamp } from '../menu-item/stamp';
import { Story } from '../menu-item/story';
import { Variation } from '../menu-item/variation';
import { Video } from '../video';
import { PlanId } from './plan-id';
import { PlanItemController } from './plan-item-controller';
import { PlanType } from './plan-type';

export interface Params {
  id: PlanId;
  contentType: string;
  name: string;
  namePrint: string;
  planType: PlanType;
  comment: RecommendedComment | null;
  description: string;
  image: Image;
  video: Video | null;
  stamp: Stamp | null;
  variations: Variation[] | null;
  startTime: Date | null;
  orderStopPeriod: number; // min
  planEndPeriod: number; // min
  extraMenuBookTypeId: BookTypeId | null;
  planItemControllers: PlanItemController[] | null;
  relatedItems: RelatedItems | null;
  story: Story | null;
  cmsMetadata: CMSMetadata;
}

export class PlanDetail {
  readonly id: PlanId;
  readonly contentType: string;
  readonly name: string;
  readonly namePrint: string;
  readonly planType: PlanType;
  readonly comment: RecommendedComment | null;
  readonly description: string;
  readonly image: Image;
  readonly video: Video | null;
  readonly stamp: Stamp | null;
  readonly variations: Variation[] | null;
  readonly startTime: Date | null;
  readonly orderStopPeriod: number;
  readonly planEndPeriod: number;
  readonly extraMenuBookTypeId: BookTypeId | null;
  readonly planItemControllers: PlanItemController[] | null;
  readonly relatedItems: RelatedItems | null;
  readonly story: Story | null;
  readonly cmsMetadata: CMSMetadata;

  constructor(params: Params) {
    this.id = params.id;
    this.contentType = params.contentType;
    this.name = params.name;
    this.namePrint = params.namePrint;
    this.planType = params.planType;
    this.comment = params.comment;
    this.description = params.description;
    this.image = params.image;
    this.video = params.video;
    this.stamp = params.stamp;
    this.variations = params.variations;
    this.startTime = params.startTime;
    this.orderStopPeriod = params.orderStopPeriod;
    this.planEndPeriod = params.planEndPeriod;
    this.extraMenuBookTypeId = params.extraMenuBookTypeId;
    this.planItemControllers = params.planItemControllers;
    this.relatedItems = params.relatedItems;
    this.story = params.story;
    this.cmsMetadata = params.cmsMetadata;
  }

  getPlanId(): PlanId {
    return this.id;
  }

  getExtraMenuBookTypeId(): BookTypeId | null {
    return this.extraMenuBookTypeId;
  }

  /**
   * @TODO https://github.com/toreta/toreta-ap-ox-app/pull/476/files#r698988923
   * 残り時間の算出を確認する。
   */
  getRemainingTime(target: Date): number {
    if (!exists(this.startTime)) {
      return 0;
    }

    const orderStop =
      this.startTime.valueOf() + this.orderStopPeriod * 60 * 1000;
    const remainingTime = Math.ceil((orderStop - target.valueOf()) / 1000 / 60);
    return 0 <= remainingTime ? remainingTime : 0;
  }

  isDuringExpandMenu(target: Date): boolean {
    return this.hasExtraMenuBookTypeId() ? !this.isOrderStop(target) : false;
  }

  isOrderStop(target: Date): boolean {
    if (!exists(this.startTime)) {
      return false;
    }

    const orderStop =
      this.startTime.valueOf() + this.orderStopPeriod * 60 * 1000;
    return orderStop < target.valueOf();
  }

  isEnd(target: Date): boolean {
    if (!exists(this.startTime)) {
      return false;
    }

    const end = this.startTime.valueOf() + this.planEndPeriod * 60 * 1000;
    return 0 > end - target.valueOf();
  }

  hasExtraMenuBookTypeId(): boolean {
    return this.extraMenuBookTypeId !== null;
  }
}
