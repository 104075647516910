import { MenuItemGroup } from '../../../models/menu-item-group/menu-item-group';
import { MenuItem } from '../../../models/menu-item/menu-item';
import { Plan } from '../../../models/plan/plan';
import { RecommendedItem } from '../../../models/recommended/recommended-item';
import { adaptMedia } from '../media/adapt-media';
import { adaptMenuItemGroup } from '../menu-item-group/adapt-menu-item-group';
import { MenuItemGroupResponse } from '../menu-item-group/menu-item-group.response';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { MenuItemResponse } from '../menu-item/menu-item.response';
import { adaptPlan } from '../plan/adapt-plan';
import { PlanResponse } from '../plan/plan.response';
import { isMenuItem } from './is-menu-item';
import { isMenuItemGroup } from './is-menu-item-group';
import { RecommendedItemResponse } from './recommended-item.response';

export function adaptItem(
  res: MenuItemResponse | MenuItemGroupResponse | PlanResponse
): MenuItem | MenuItemGroup | Plan {
  if (isMenuItem(res)) {
    return adaptMenuItem(res);
  }
  if (isMenuItemGroup(res)) {
    return adaptMenuItemGroup(res);
  }
  return adaptPlan(res);
}

export function adaptRecommendedItem(
  res: RecommendedItemResponse
): RecommendedItem {
  return {
    media: adaptMedia(res.fields.image),
    item: adaptItem(res.fields.item),
  };
}
