import { BookTypeId } from '../../../../models/book-type/book-type-id';
import { MenuBook } from '../../../../models/menu-book/menu-book';
import { PlanDetail } from '../../../../models/plan/plan-detail';
import { exists } from '../../../../utils/exists';
import { BookTypeViewModel } from './book-type-view-model';

export function makeBookTypeViewModel(
  menuBook: MenuBook,
  selectedBookTypeId: BookTypeId | null,
  plan: PlanDetail | null,
  now: Date
): BookTypeViewModel[] {
  return [...menuBook.bookTypes]
    .filter((bookType) => {
      if (!exists(plan)) {
        return true;
      }
      if (
        plan.extraMenuBookTypeId === null ||
        !bookType.bookTypeId.eq(plan.extraMenuBookTypeId)
      ) {
        return true;
      }

      return !plan.isOrderStop(now);
    })
    .map((bookType) => {
      return {
        bookTypeId: bookType.bookTypeId,
        name: bookType.name,
        icon: bookType.icon,
        isSelected:
          selectedBookTypeId === null
            ? false
            : bookType.bookTypeId.eq(selectedBookTypeId),
        firstMenuCategoryId: bookType.getFirstCategoryId(),
      };
    });
}
