import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { FooterMenuModule } from './footer-menu/footer-menu.module';
import { LoadingModule } from './loading/loading.module';
import { LocationBackButtonModule } from './location-back-button/location-back-button.module';
import { OrderListButtonModule } from './order-list-button/order-list-button.module';

@NgModule({
  imports: [
    CommonModule,
    OrderListButtonModule,
    FooterMenuModule,
    LoadingModule,
    LocationBackButtonModule,
    HttpClientModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    OrderListButtonModule,
    FooterMenuModule,
    LoadingModule,
    LocationBackButtonModule,
    HttpClientModule,
  ],
})
export class SharedModule {}
