import { Asset, Entry } from 'contentful';

import { BookTypeResponse } from '../book-type/book-type.response';
import { RecommendedCommentResponse } from '../recommended-comment/recommended-comment.response';
import { RelatedItemsResponse } from '../related-item/related-items.response';
import { StampResponse } from '../stamp/stamp-response';
import { StoryResponse } from '../story/story.response';
import { VariationResponse } from '../variation/variation.response';
import { PlanItemControllerResponse } from './plan-item-contoroller.response';

export const PLAN_RESPONSE_INCLUDE_LEVEL = 4;

export interface PlanFieldResponse {
  name: string;
  name_print: string;
  plan_type: string;
  description: string;
  comment?: RecommendedCommentResponse;
  image: Asset;
  video?: Asset;
  stamp?: StampResponse;
  variations: VariationResponse[];
  order_stop_period: number;
  plan_end_period: number;
  extra_menu?: BookTypeResponse;
  plan_item_controllers?: PlanItemControllerResponse[];
  related_items?: RelatedItemsResponse;
  story?: StoryResponse;
}

export type PlanResponse = Entry<PlanFieldResponse>;
