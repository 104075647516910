import { Asset, Entry } from 'contentful';

import { AreaItemGroupResponse } from '../area-item-group/area-Item-group.response';

export const SPECIAL_RESPONSE_INCLUDE_LEVEL = 3;

export interface SpecialFieldResponse {
  name: string;
  description?: string;
  image_l?: Asset;
  image_m?: Asset;
  image_s?: Asset;
  area_l?: AreaItemGroupResponse;
  area_m?: AreaItemGroupResponse[];
  area_s?: AreaItemGroupResponse[];
}

export type SpecialResponse = Entry<SpecialFieldResponse>;
