import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { adaptNow } from '../../lib/shiba/now/adapt-now';
import { ShibaClient } from '../../lib/shiba/shiba-client';

@Injectable({ providedIn: 'root' })
export class NowRepository {
  constructor(private readonly shibaClient: ShibaClient) {}

  async getNow(): Promise<number> {
    const res = await firstValueFrom(this.shibaClient.getNow());
    return adaptNow(res);
  }
}
