import { createReducer, on } from '@ngrx/store';

import { SoldOutActionsUnion, storeSoldOuts } from './sold-out.actions';
import { initialState } from './sold-out.initial-state';
import { SoldOutState } from './sold-out.state';

export function soldOutReducer(
  soldOutState: SoldOutState,
  action: SoldOutActionsUnion
): SoldOutState {
  type S = SoldOutState;
  const reducer = createReducer<S>(
    initialState,
    on(storeSoldOuts, (state, { payload }): S => {
      return { ...state, soldOuts: payload };
    })
  );
  return reducer(soldOutState, action);
}

export const soldOutFeatureName = 'soldOutFeature';
