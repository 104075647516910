import { createReducer, on } from '@ngrx/store';
import {
  ChargesProcessingActions,
  storeChargesStateErrorType,
} from './charges-processing.actions';
import { initialState } from './charges-processing.initial-state';
import { ChargesProcessingState } from './charges-processing.state';

export function chargesProcessingReducer(
  chargesProcessingState: ChargesProcessingState,
  action: ChargesProcessingActions
): ChargesProcessingState {
  type S = ChargesProcessingState;
  const reducer = createReducer<S>(
    initialState,
    on(storeChargesStateErrorType, (state, { payload }): S => {
      return { ...state, chargesStateErrorType: payload };
    })
  );
  return reducer(chargesProcessingState, action);
}

export const chargesProcessingFeatureName = 'chargesProcessingFeature';
