import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { adaptAccountIds } from '../../lib/mojito/accounts/adapt-account-ids';
import { MojitoClient } from '../../lib/mojito/mojito-client';
import { ApiLocationId } from '../location/api-location-id';
import { AccountIds } from './account-ids';

@Injectable({ providedIn: 'root' })
export class AccountsRepository {
  constructor(private readonly client: MojitoClient) {}

  async getAccountIds(apiLocationId: ApiLocationId): Promise<AccountIds> {
    const res = await firstValueFrom(this.client.getAccounts(apiLocationId));
    return adaptAccountIds(res);
  }
}
