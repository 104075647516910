import { FavoriteTasteAreaItemGroup } from '../../../models/recommended/favorite-taste-area-item-group';
import { adaptImage } from '../image/adapt-image';
import { adaptRecommendedItemGroupList } from './adapt-recommended-item-group-list';
import { FavoriteTasteAreaItemGroupResponse } from './favorite-taste-area-Item-group.response';

export function adaptFavoriteTasteAreaItemGroup(
  res: FavoriteTasteAreaItemGroupResponse
): FavoriteTasteAreaItemGroup {
  const recommendedItemGroupList = res.fields.recommended_item_group_list.map(
    (v) => {
      return adaptRecommendedItemGroupList(v);
    }
  );
  return {
    name: res.fields.name,
    icon: adaptImage(res.fields.icon),
    recommendedItemGroupList,
  };
}
