import { createAction, union } from '@ngrx/store';

export const storeIsProcessingOrderStop = createAction(
  'OrderHistoryActions storeIsProcessingOrderStop',
  (payload: boolean) => ({ payload })
);

export const storeIsProcessingNotification = createAction(
  'OrderHistoryActions storeIsProcessingNotification',
  (payload: boolean) => ({ payload })
);

export const storeIsProcessingRefresh = createAction(
  'OrderHistoryActions storeIsProcessingRefresh',
  (payload: boolean) => ({ payload })
);

const all = union({
  storeIsProcessingOrderStop,
  storeIsProcessingNotification,
  storeIsProcessingRefresh,
});

export type OrderHistoryActionsUnion = typeof all;
