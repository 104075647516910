import { SessionResponse } from 'src/app/lib/cocktail/session/session.response';

export const getSessionsResponseMock: SessionResponse = {
  status: 'ok',
  result: {
    session: {
      id: 'trial',
      destinations: ['体験版'],
      seats: 1,
    },
  },
};
