import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { nonNullFilter } from '../../non-null-filter';
import { nowFeatureName } from './now.reducer';
import { NowState } from './now.state';

const selectFeature = createFeatureSelector<NowState>(nowFeatureName);

export const selectNow = pipe(
  select(createSelector(selectFeature, (v) => v.now)),
  nonNullFilter(),
  distinctUntilChanged((a, b) => a.valueOf() === b.valueOf())
);
