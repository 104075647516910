import { createAction, union } from '@ngrx/store';

import { Brand } from '../../../../models/brand/brand';

export const storeBrand = createAction(
  'BrandActions storeBrand',
  (payload: Brand) => ({ payload })
);

const all = union({
  storeBrand,
});

export type BrandActionsUnion = typeof all;
