import { createReducer, on } from '@ngrx/store';

import { CartItem } from '../../../../models/cart-item/cart-item';
import { CartItemId } from '../../../../models/cart-item/cart-item-id';
import {
  addItemToCart,
  CartActionsUnion,
  decrementCartItemQuantity,
  deleteCartItem,
  incrementCartItemQuantity,
  initializeCart,
  stopAnimation,
} from './cart.actions';
import { initialState } from './cart.initial-state';
import { CartState } from './cart.state';
import { findSameItemFromCart } from './find-same-item-from-cart';

export function cartReducer(
  cartState: CartState,
  action: CartActionsUnion
): CartState {
  type S = CartState;
  const reducer = createReducer<CartState>(
    initialState,
    on(initializeCart, (): S => {
      return {
        ...initialState,
      };
    }),
    on(addItemToCart, (state, { payload }): S => {
      // 同じ商品がカートに存在する場合は、数量を増やす。
      const found = findSameItemFromCart(state.cartItems, payload);

      if (found) {
        return {
          ...state,
          cartItems: incrementTargetItemQuantity(
            state.cartItems,
            found.cartItemId
          ),
          isStartAnimation: true,
        };
      }

      return {
        ...state,
        cartItems: [...state.cartItems, payload],
        isStartAnimation: true,
      };
    }),
    on(incrementCartItemQuantity, (state, { payload }): S => {
      return {
        ...state,
        cartItems: incrementTargetItemQuantity(
          state.cartItems,
          payload.cartItemId
        ),
      };
    }),
    on(decrementCartItemQuantity, (state, { payload }): S => {
      const target = findSameItemFromCart(state.cartItems, payload);

      // 数量が0になる場合は、カートからアイテムを削除する。
      if (target?.quantity === 1) {
        return {
          ...state,
          cartItems: state.cartItems.filter((item) => {
            return item.cartItemId !== payload.cartItemId;
          }),
        };
      }

      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.cartItemId === target?.cartItemId) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }

          return item;
        }),
      };
    }),
    on(deleteCartItem, (state, { payload }): S => {
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => !item.cartItemId.eq(payload)
        ),
      };
    }),
    on(stopAnimation, (state): S => {
      return {
        ...state,
        isStartAnimation: false,
      };
    })
  );

  return reducer(cartState, action);
}

function incrementTargetItemQuantity(
  items: CartItem[],
  targetItemId: CartItemId
): CartItem[] {
  return items.map((item) => {
    if (item.cartItemId.eq(targetItemId)) {
      return {
        ...item,
        quantity: item.quantity + 1,
      };
    }

    return item;
  });
}

export const cartFeatureName = 'cartFeature';
