<nav class="FooterNav" *ngIf="queries.session$ | async as session">
  <ng-container
    *ngIf="queries.bookTypeViewModels$ | async as bookTypeViewModels"
  >
    <ng-container *ngFor="let bookType of bookTypeViewModels">
      <button
        (click)="
          onClickBookType(bookType.bookTypeId, bookType.firstMenuCategoryId)
        "
        class="FooterNav_Button"
      >
        <span class="FooterNav_BookTypeIconArea">
          <!-- 画像は修飾目的で使用しているため、altは意図的に空にしている -->
          <picture>
            <source
              [srcset]="bookType.icon.imgUrl + '?fm=webp'"
              type="image/webp"
            />
            <img
              alt=""
              class="FooterNav_BookTypeIconImage"
              [class.FooterNav_BookTypeIconImage-isSelected]="
                bookType.isSelected
              "
              [src]="bookType.icon.imgUrl"
            />
          </picture>
        </span>
        <span
          class="FooterNav_Label"
          [class.FooterNav_Label-isSelected]="bookType.isSelected"
        >
          {{ bookType.name }}
        </span>
      </button>
    </ng-container>
  </ng-container>

  <!-- 注文履歴 -->
  <button
    (click)="onClickOrderHistory()"
    class="FooterNav_Button"
    *ngIf="canDisplayOrderHistoryNavButton()"
  >
    <span class="FooterNav_OrderHistoryIconArea">
      <!-- 画像は修飾目的で使用しているため、altは意図的に空にしている -->
      <img
        alt=""
        class="FooterNav_OrderHistoryIconImage"
        [class.FooterNav_OrderHistoryIconImage-isSelected]="
          isSelectedOrderHistory
        "
        src="/assets/img/menu_history@3x.png"
      />
    </span>
    <span
      class="FooterNav_Label"
      [class.FooterNav_Label-isSelected]="isSelectedOrderHistory"
    >
      伝票/会計
    </span>
  </button>
</nav>
