import { OrderItemDetails } from '../../../models/order/order-item-details';
import { adaptOrderItemDetail } from './adapt-order-item-detail';
import { ItemResponse } from './order-history.response';

export function adaptOrderItemDetails(res: ItemResponse[]): OrderItemDetails {
  // OrderItem[]に変換する
  const orderItemList = res.map((v) => adaptOrderItemDetail(v));

  // OrderItemsで返す
  return new OrderItemDetails(orderItemList);
}
