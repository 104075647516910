import { Identity } from './models/identity/identity';

export function extractIdentity(identities: Identity[] | null): Identity {
  if (identities === null) {
    throw new Error('Identities is null.');
  }
  if (identities.length === 0) {
    throw new Error('Identities is empty.');
  }
  if (typeof identities[0] === 'undefined') {
    throw new Error('identities[0] is undefined');
  }
  return identities[0]; // APの場合、identityは1つ
}
