import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiLocationId } from '../../models/location/api-location-id';
import { LocationId } from '../../models/location/location-id';
import { MenuItemId } from '../../models/menu-item/menu-item-id';
import { PlanId } from '../../models/plan/plan-id';
import { SpecialId } from '../../models/special/special-id';
import { retryByErrorStatus } from '../../pages/shared/retry-by-error-status/retry-by-error-status';
import { adaptEntry } from './adapt-entry';
import {
  BRAND_RESPONSE_INCLUDE_LEVEL,
  BrandResponse,
} from './brand/brand.response';
import { ContentfulResponse } from './contentful-response';
import {
  LOCATION_RESPONSE_INCLUDE_LEVEL,
  LocationResponse,
} from './location/location.response';
import {
  MENU_ITEM_RESPONSE_INCLUDE_LEVEL,
  MenuItemResponse,
} from './menu-item/menu-item.response';
import {
  PLAN_RESPONSE_INCLUDE_LEVEL,
  PlanResponse,
} from './plan/plan.response';
import {
  SPECIAL_RESPONSE_INCLUDE_LEVEL,
  SpecialResponse,
} from './special/special.response';

/**
 * @TODO RepositoryとClientの見直し #320
 * https://github.com/toreta/toreta-ap-ox-app/issues/320
 */
@Injectable({ providedIn: 'root' })
export class ContentfulClient {
  private readonly mojitoErrorRetryCount = 5;
  private readonly mojitoErrorListToRetry = [502, 504];
  constructor(private client: HttpClient) {}

  /**
   * メニューブックを取得
   */
  listLocation(
    locationId: LocationId,
    apiLocationId: ApiLocationId
  ): Promise<LocationResponse> {
    return firstValueFrom(
      this.client
        .get<ContentfulResponse<LocationResponse>>(
          `/api/cms/entries/${locationId.toString()}/${LOCATION_RESPONSE_INCLUDE_LEVEL}?locationId=${apiLocationId}`
        )
        .pipe(
          map((res) => adaptEntry<LocationResponse>(res)),
          retryByErrorStatus<LocationResponse>(
            this.mojitoErrorRetryCount,
            this.mojitoErrorListToRetry
          )
        )
    );
  }

  /**
   * メニューアイテムを取得
   */
  listMenuItem(
    menuItemId: MenuItemId,
    apiLocationId: ApiLocationId
  ): Promise<MenuItemResponse> {
    return firstValueFrom(
      this.client
        .get<ContentfulResponse<MenuItemResponse>>(
          `/api/cms/entries/${menuItemId.toString()}/${MENU_ITEM_RESPONSE_INCLUDE_LEVEL}?locationId=${apiLocationId}`
        )
        .pipe(
          map((res) => adaptEntry<MenuItemResponse>(res)),
          retryByErrorStatus<MenuItemResponse>(
            this.mojitoErrorRetryCount,
            this.mojitoErrorListToRetry
          )
        )
    );
  }

  /**
   * まとめを取得
   */
  listSpecial(
    specialId: SpecialId,
    apiLocationId: ApiLocationId
  ): Promise<SpecialResponse> {
    return firstValueFrom(
      this.client
        .get<ContentfulResponse<SpecialResponse>>(
          `/api/cms/entries/${specialId.toString()}/${SPECIAL_RESPONSE_INCLUDE_LEVEL}?locationId=${apiLocationId}`
        )
        .pipe(
          map((res) => adaptEntry<SpecialResponse>(res)),
          retryByErrorStatus<SpecialResponse>(
            this.mojitoErrorRetryCount,
            this.mojitoErrorListToRetry
          )
        )
    );
  }

  /**
   * プランを取得
   */
  listPlan(
    planId: PlanId,
    apiLocationId: ApiLocationId
  ): Promise<PlanResponse> {
    return firstValueFrom(
      this.client
        .get<ContentfulResponse<PlanResponse>>(
          `/api/cms/entries/${planId.toString()}/${PLAN_RESPONSE_INCLUDE_LEVEL}?locationId=${apiLocationId}`
        )
        .pipe(
          map((res) => adaptEntry<PlanResponse>(res)),
          retryByErrorStatus<PlanResponse>(
            this.mojitoErrorRetryCount,
            this.mojitoErrorListToRetry
          )
        )
    );
  }

  /**
   * ブランド情報を取得
   */
  listBrand(apiLocationId: ApiLocationId): Promise<BrandResponse> {
    return firstValueFrom(
      this.client
        .get<ContentfulResponse<BrandResponse>>(
          `/api/cms/content-types/brand/${BRAND_RESPONSE_INCLUDE_LEVEL}?locationId=${apiLocationId}`
        )
        .pipe(
          map((res) => adaptEntry<BrandResponse>(res)),
          retryByErrorStatus<BrandResponse>(
            this.mojitoErrorRetryCount,
            this.mojitoErrorListToRetry
          )
        )
    );
  }
}
