import { MenuItemDetail } from '../../../models/menu-item/menu-item-detail';
import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { adaptImage } from '../image/adapt-image';
import { adaptRecommendedComment } from '../recommended-comment/adapt-recommended-comment';
import { adaptRelatedItems } from '../related-item/adapt-related-items';
import { adaptStamp } from '../stamp/adapt-stamp';
import { adaptStory } from '../story/adapt-story';
import { adaptVariation } from '../variation/adapt-variation';
import { adaptVideo } from '../video/adapt-video';
import { MenuItemResponse } from './menu-item.response';

export function adaptMenuItemDetail(res: MenuItemResponse): MenuItemDetail {
  return {
    id: new MenuItemId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    namePrint: res.fields.name_print,
    comment: res.fields.comment
      ? adaptRecommendedComment(res.fields.comment)
      : null,
    cardDescription: res.fields.card_description ?? '',
    image: adaptImage(res.fields.image),
    video: res.fields.video ? adaptVideo(res.fields.video) : null,
    stamp: res.fields.stamp ? adaptStamp(res.fields.stamp) : null,
    variations: res.fields.variations.map((v) => adaptVariation(v)),
    relatedItems: res.fields.related_items
      ? adaptRelatedItems(res.fields.related_items)
      : null,
    story: res.fields.story ? adaptStory(res.fields.story) : null,
    cmsMetadata: adaptCMSMetadata(res),
  };
}
