import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { accountIdsFeatureName } from './account-ids.reducer';
import { AccountIdsState } from './accounts.state';

const selectFeature = createFeatureSelector<AccountIdsState>(
  accountIdsFeatureName
);

export const selectAccountIds = select(
  createSelector(selectFeature, (state) => state)
);

export const selectSalesAccountId = pipe(
  select(createSelector(selectFeature, (v) => v?.salesAccountId))
);

export const selectSalesDiscountsAccountId = pipe(
  select(createSelector(selectFeature, (v) => v?.salesDiscountsAccountId))
);

export const selectStripeTradeAccountsReceivableAccountId = pipe(
  select(
    createSelector(
      selectFeature,
      (v) => v?.stripeTradeAccountsReceivableAccountId
    )
  )
);

export const selectTradeAccountsReceivableAccountId = pipe(
  select(
    createSelector(selectFeature, (v) => v?.tradeAccountsReceivableAccountId)
  )
);

export const selectUnconfirmedTradeAccountsReceivableAccountId = pipe(
  select(
    createSelector(
      selectFeature,
      (v) => v?.unconfirmedTradeAccountsReceivableAccountId
    )
  )
);

export const selectReceivableAccountId = pipe(
  select(createSelector(selectFeature, (v) => v?.receivableAccountId))
);
