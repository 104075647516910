/**
 * https://www.contentful.com/help/available-validations/#regular-expression-regex
 * ContentfulのURLバリデーションルールを使用
 */
export const regex =
  /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/;

export function isUrlValid(value: string): boolean {
  if (value === '') {
    return false;
  }
  return regex.test(value);
}
