import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiLocationId } from '../../models/location/api-location-id';
import { IsNestSupportedResponse } from '../shiba/nest/is-nest-supported';
import { NowResponse } from './now/now.response';

@Injectable({ providedIn: 'root' })
export class ShibaClient {
  constructor(private client: HttpClient) {}

  /**
   * 日時を取得
   */
  getNow(): Observable<NowResponse> {
    return this.client.get<NowResponse>('/api/now');
  }

  getIsNestSupported(
    apiLocationId: ApiLocationId
  ): Observable<IsNestSupportedResponse> {
    return this.client.get<IsNestSupportedResponse>(
      `/api/nest/get-is-nest-supported?locationId=${apiLocationId}`
    );
  }
}
