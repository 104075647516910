import { PrintNotificationController } from './print-notification-controller';
import { PrintNotificationType } from './print-notification-type';
import { Printer } from './printer';

export interface Params {
  defaultPrinter: Printer;
  list: PrintNotificationController[];
}

export class PrintNotificationControllers {
  private readonly defaultPrinter: Printer;
  private readonly list: PrintNotificationController[];

  constructor(params: Params) {
    this.defaultPrinter = params.defaultPrinter;
    this.list = params.list;
  }
  exists(): boolean {
    return this.list.length > 0;
  }
  findFromNotificationTypeAndTableName(
    type: PrintNotificationType,
    tableName: string
  ): Printer[] {
    if (!this.exists()) {
      return [this.defaultPrinter];
    }
    const printers = this.list
      .filter((v) => {
        return v.type === type && v.tables.some((v) => v.name === tableName);
      })
      .map((v) => v.printer);

    return printers.length > 0 ? printers : [this.defaultPrinter];
  }
}
