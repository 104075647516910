import { createAction, union } from '@ngrx/store';
import { DiscountDetail } from '../../../models/discount/discount-detail';
import { InvoiceDetail } from '../../../models/invoice/invoice-detail';

export const storeInvoiceDetailAndDiscountDetail = createAction(
  'PaymentDetailActions storeInvoiceDetailAndDiscountDetail',
  (payload: {
    invoiceDetail: InvoiceDetail;
    discountDetail: DiscountDetail | null;
  }) => ({
    payload,
  })
);

const all = union({
  storeInvoiceDetailAndDiscountDetail,
});

export type PaymentDetailActionsUnion = typeof all;
