import { createAction, union } from '@ngrx/store';
import { DiscountDetail } from '../../../models/discount/discount-detail';
import { InvoiceDetail } from '../../../models/invoice/invoice-detail';

export const storeAgreedRule = createAction('PayActions storeIsAgreedRules');

export const storeIsProcessingPayByCash = createAction(
  'PayActions storeIsProcessingPayByCash',
  (payload: boolean) => ({ payload })
);

export const storeIsProcessingPayByCard = createAction(
  'PayActions storeIsProcessingPayByCard',
  (payload: boolean) => ({ payload })
);

export const storeIsProcessingNotification = createAction(
  'PayActions storeIsProcessingNotification',
  (payload: boolean) => ({ payload })
);

export const storeIsProcessingRefresh = createAction(
  'PayActions storeIsProcessingRefresh',
  (payload: boolean) => ({ payload })
);

export const storeInvoiceDetailAndDiscountDetail = createAction(
  'PayActions storeInvoiceDetailAndDiscountDetail',
  (payload: {
    invoiceDetail: InvoiceDetail;
    discountDetail: DiscountDetail | null;
  }) => ({
    payload,
  })
);

export const storeInvoiceDetail = createAction(
  'PayActions storeInvoiceDetail',
  (payload: InvoiceDetail) => ({ payload })
);

const all = union({
  storeAgreedRule,
  storeIsProcessingPayByCash,
  storeIsProcessingPayByCard,
  storeIsProcessingNotification,
  storeIsProcessingRefresh,
  storeInvoiceDetailAndDiscountDetail,
  storeInvoiceDetail,
});

export type PayActionsUnion = typeof all;
