import { OrderItemDetail } from './order-item-detail';

export class OrderItemDetails {
  constructor(private readonly list: OrderItemDetail[]) {}

  exists(): boolean {
    return 0 < this.count();
  }

  validOrderItems(): OrderItemDetails {
    const validOrderItemList = this.list.filter(
      (v) => v.quantity > 0 && v.subtotal.value > 0
    );
    return new OrderItemDetails(validOrderItemList);
  }

  *[Symbol.iterator](): IterableIterator<OrderItemDetail> {
    for (const v of this.list) {
      yield v;
    }
  }

  private count(): number {
    return this.validOrderItems().list.length;
  }
}
