import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EventLoggerClient {
  trackLog<T>(eventName: string, params: T): void {
    if (!environment.production && !environment.staging) {
      return;
    }
    // 本番環境とステージング環境の場合、Segmentにログを送信する
    window.analytics.track(eventName, params);
  }
}
