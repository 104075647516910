import { Modifier } from '../../../models/menu-item/modifier';
import { ModifierId } from '../../../models/modifier/modifier-id';
import { Price } from '../../../models/price';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { ModifierResponse } from './modifier.response';

export function adaptModifiers(res: ModifierResponse[]): Modifier[] {
  return res.map((v) => {
    return {
      modifierId: new ModifierId(v.sys.id),
      contentType: v.sys.contentType.sys.id,
      name: v.fields.name,
      namePrint: v.fields.name_print ?? '',
      unitPriceDelta: new Price(v.fields.unit_price_delta),
      cmsMetadata: adaptCMSMetadata(v),
    };
  });
}
