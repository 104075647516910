import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isTrial } from '../../../utils/is-trial';
import { getRequestType } from './get-request-type';
import { getIdentitiesResponseMock } from './identity/get-identities-response.mock';
import { getIdentityOrdersResponseMock } from './identity/get-identity-orders-response.mock';
import { getIdentityResponseMock } from './identity/get-identity-response.mock';
import { getIdentityTimestampsResponseMock } from './identity/get-identity-timestamps-response.mock';
import { getSessionsResponseMock } from './session/get-sessions-response.mock';

@Injectable()
export class CocktailInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!isTrial()) {
      return next.handle(req);
    }
    if (req.method === 'POST') {
      return of(
        new HttpResponse({
          status: 400,
        })
      );
    }
    const requestType = getRequestType(req.url);
    switch (requestType) {
      case 'identity':
        return of(
          new HttpResponse({
            status: 200,
            body: getIdentityResponseMock,
          })
        );
      case 'identities':
        return of(
          new HttpResponse({
            status: 200,
            body: getIdentitiesResponseMock,
          })
        );
      case 'sessions':
        return of(
          new HttpResponse({
            status: 200,
            body: getSessionsResponseMock,
          })
        );
      case 'identityOrders':
        return of(
          new HttpResponse({
            status: 200,
            body: getIdentityOrdersResponseMock,
          })
        );
      case 'identityTimeStamps':
        return of(
          new HttpResponse({
            status: 200,
            body: getIdentityTimestampsResponseMock,
          })
        );
      default:
        return next.handle(req);
    }
  }
}
