import { ReceiptState } from './receipt.state';

export const initialState: ReceiptState = {
  enteredEmail: '',
  isEmailValid: false,
  isEmailTouched: false,
  enteredAddressee: '',
  isAddresseeValid: false,
  isAddresseeTouched: false,
  isProcessing: false,
};
