import { Invoice } from '../../../models/identity/identity';
import { InvoiceId } from '../../../models/invoice/invoice-id';
import { ReceiptId } from '../../../models/invoice/receipt-id';
import { Price } from '../../../models/price';
import { mapOrElse } from '../../../utils/map-or-else';
import { adaptPayment } from './adapt-payment';
import { adaptPaymentIntent } from './adapt-payment-intent';
import { InvoiceResponse } from './identity.response';

export function adaptInvoice(res: InvoiceResponse): Invoice {
  const id = new InvoiceId(res.id);
  const payments = res.payments.map((payment) => adaptPayment(payment));
  const total = new Price(res.total.amount);
  const balance = new Price(res.balance.amount);
  const receipt = res.receipt
    ? {
        id: new ReceiptId(res.receipt.id),
        createAt: res.receipt.createAt,
      }
    : null;

  const payableBalance = new Price(res.payableBalance.amount);

  const paymentIntents = mapOrElse(res.paymentIntents, (v) =>
    adaptPaymentIntent(v)
  );

  return {
    id,
    payments,
    receipt,
    total,
    payableBalance,
    paymentIntents,
  };
}
