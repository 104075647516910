import { createAction, union } from '@ngrx/store';

export const startSending = createAction('OrderListActions startSending');

export const stopSending = createAction('OrderListActions stopSending');

const all = union({
  startSending,
  stopSending,
});

export type OrderListActionsUnion = typeof all;
