import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { ModifierId } from '../../../models/modifier/modifier-id';
import { OrderItemDetail } from '../../../models/order/order-item-detail';
import { Price } from '../../../models/price';
import { VariationId } from '../../../models/variation/variation-id';
import { ModifierWithQuantity } from '../../../pages/shared/modifier-bottom-sheet/components/modifier-with-quantity';
import { ItemResponse } from './order-history.response';

export function adaptOrderItemDetail(res: ItemResponse): OrderItemDetail {
  // オーダー情報からバリエーション情報を取得
  const variationResponse = res.components.find((v) => v.type === 'VARIATION');
  if (variationResponse === undefined) {
    throw new Error('variation is undefined.');
  }

  // オーダー情報からオプション情報を取得
  const modifiersResponse = res.components.filter((v) => v.type === 'MODIFIER');

  // Modifierに変換
  const modifiers: ModifierWithQuantity[] =
    modifiersResponse.length === 0
      ? []
      : modifiersResponse.map((v) => {
          return {
            modifier: {
              modifierId: new ModifierId(v.id),
              contentType: v.metadata?.conentType ?? '',
              name: v.name.forDisplay,
              namePrint: v.name.forPrinting ?? '',
              unitPriceDelta: new Price(Number(v.unitPrice.amount)),
              cmsMetadata: v.cmsMetadata,
            },
            quantity: Number(v.quantityPerItem),
          };
        });

  return new OrderItemDetail({
    menuItemId: new MenuItemId(res.id),
    menuItemName: res.name.forDisplay,
    quantity: Number(res.quantity),
    unitPrice: new Price(Number(variationResponse.unitPrice.amount)),
    variationId: new VariationId(variationResponse.id),
    variationName:
      res.layout.forDisplay === 'HIDE_VARIATION'
        ? ''
        : variationResponse.name.forDisplay,
    modifiers,
    metadata: res.metadata ?? null,
    subtotal: new Price(Number(res.total.amount)),
  });
}
