import { createAction, union } from '@ngrx/store';

export const enterEmail = createAction(
  'ReceiptActions enterEmail',
  (payload: string) => ({ payload })
);

export const blurEmail = createAction('ReceiptActions blurEmail');

export const enterAddressee = createAction(
  'ReceiptActions enterAddressee',
  (payload: string) => ({ payload })
);

export const blurAddressee = createAction('ReceiptActions blurAddressee');

export const storeIsProcessing = createAction(
  'ReceiptActions storeIsProcessing',
  (payload: boolean) => ({ payload })
);

const all = union({
  enterEmail,
  blurEmail,
  enterAddressee,
  blurAddressee,
  storeIsProcessing,
});

export type ReceiptActions = typeof all;
