import { Asset } from 'contentful';

import { Image } from '../../../models/image';
import { convertToBffMediaPath } from '../convert-to-bff-media-path';

export function adaptImage(res: Asset): Image {
  return {
    name: res.fields.title,
    imgUrl: convertToBffMediaPath(res.fields.file.url),
  };
}
