import { AreaItemGroup } from '../../../models/menu-category/area-item-group';
import { MenuCategory } from '../../../models/menu-category/menu-category';
import { MenuCategoryId } from '../../../models/menu-category/menu-category-id';
import { adaptAreaItemGroup } from '../area-item-group/adapt-area-item-group';
import { adaptImage } from '../image/adapt-image';
import { adaptMenuItem } from '../menu-item/adapt-menu-item';
import { isMenuItemResponse } from '../menu-item/is-menu-item-response';
import { adaptPlan } from '../plan/adapt-plan';
import { isPlanResponse } from '../plan/is-plan-response';
import { MenuCategoryResponse } from './menu-category.response';

export function adaptMenuCategory(
  menuCategoryResponse: MenuCategoryResponse
): MenuCategory {
  let areaL: AreaItemGroup | null = null;
  let areaSList: AreaItemGroup[] = [];

  // カテゴリ内の全ての商品
  const items = menuCategoryResponse.fields.items
    .filter((v) => [isMenuItemResponse(v), isPlanResponse(v)].some((v) => v))
    .map((v) => {
      if (isMenuItemResponse(v)) {
        return adaptMenuItem(v);
      }
      return adaptPlan(v);
    });

  // 商品エリア大に表示する商品
  if (menuCategoryResponse.fields.area_l) {
    areaL = adaptAreaItemGroup(menuCategoryResponse.fields.area_l);
  }

  // 商品エリア小に表示する商品
  if (menuCategoryResponse.fields.area_s_list) {
    areaSList = menuCategoryResponse.fields.area_s_list.map((v) => {
      return adaptAreaItemGroup(v);
    });
  }

  return {
    id: new MenuCategoryId(menuCategoryResponse.sys.id),
    contentType: menuCategoryResponse.sys.contentType.sys.id,
    name: menuCategoryResponse.fields.name,
    icon: adaptImage(menuCategoryResponse.fields.icon),
    description: menuCategoryResponse.fields.description ?? '',
    items,
    areaL,
    areaSList,
  };
}
