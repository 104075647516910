import { LocationState } from './location.state';

export const initialState: LocationState = {
  locationId: null,
  apiLocationId: null,
  name: '',
  tel: '',
  menuBook: null,
  printItemControllers: null,
  printControllers: null,
  printNotificationControllers: null,
  welcomeMessage: '',
  staffImages: null,
  orderCompleteBanner: null,
  logoImage: null,
};
