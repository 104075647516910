import { MenuItemGroup } from '../../../models/menu-item-group/menu-item-group';
import { MenuItemGroupId } from '../../../models/menu-item-group/menu-item-group-id';
import { MenuItemGroupResponse } from './menu-item-group.response';

export function adaptMenuItemGroup(res: MenuItemGroupResponse): MenuItemGroup {
  return {
    id: new MenuItemGroupId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    description: res.fields.description ?? '',
    cardDescription: res.fields.card_description ?? '',
  };
}
