import { createReducer, on } from '@ngrx/store';

import {
  PayByCardProcessingActions,
  storeWorkflowErrorType,
} from './pay-by-card-processing.actions';
import { initialState } from './pay-by-card-processing.initial-state';
import { PayByCardProcessingState } from './pay-by-card-processing.state';

export function payByCardProcessingReducer(
  payByCardProcessingState: PayByCardProcessingState,
  action: PayByCardProcessingActions
): PayByCardProcessingState {
  type S = PayByCardProcessingState;
  const reducer = createReducer<S>(
    initialState,
    on(storeWorkflowErrorType, (state, { payload }): S => {
      return { ...state, workflowErrorType: payload };
    })
  );
  return reducer(payByCardProcessingState, action);
}

export const payByCardProcessingFeatureName = 'payByCardProcessingFeature';
