import { Banner } from '../../../models/banner/banner';
import { Banners } from '../../../models/banner/banners';
import { adaptMedia } from '../media/adapt-media';
import { isPlanResponse } from '../plan/is-plan-response';
import { isSetItemResponse } from '../plan/is-set-item-response';
import { adaptBannerItem } from './adapt-banner-item';
import { BannerResponse } from './banner.response';

export function adaptBanners(res: BannerResponse[]): Banners {
  const list: Banner[] = res.reduce((acc, cur) => {
    if (
      cur.fields.menu_item &&
      isPlanResponse(cur.fields.menu_item) &&
      !isSetItemResponse(cur.fields.menu_item)
    ) {
      return acc;
    }
    return [
      ...acc,
      {
        media: adaptMedia(cur.fields.image),
        alt: cur.fields.alt,
        item: cur.fields.menu_item
          ? adaptBannerItem(cur.fields.menu_item)
          : null,
        externalLinkUrl: cur.fields.external_link_url ?? '',
        externalLinkButtonLabel:
          cur.fields.external_link_button_label ?? '詳しくはこちら',
        isExternalLinkBanner:
          cur.fields.menu_item === undefined && !!cur.fields.external_link_url,
      },
    ];
  }, [] as Banner[]);
  return new Banners(list);
}
