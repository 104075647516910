import { MonoTypeOperatorFunction, of, throwError } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

export function retryByErrorStatus<T>(
  count: number,
  includedError: number[] = []
): MonoTypeOperatorFunction<T> {
  //Rxjsを8以上にするとretryWhenが使えなくなるのでバージョンを上げた際はretryのoptionを使用する
  return retryWhen<T>((errors) =>
    errors.pipe(
      mergeMap((error, i) =>
        i >= count || includedError.every((e) => e !== error.status)
          ? throwError(error)
          : of(error)
      )
    )
  );
}
