import { RecommendedAreaItemGroupId } from '../../../models/recommended/recommended-area-item-group-id';
import { RecommendedAreaSItemGroup } from '../../../models/recommended/recommended-area-s-item-group';
import { RecommendedItem } from '../../../models/recommended/recommended-item';
import { adaptImage } from '../image/adapt-image';
import { isPlanResponse } from '../plan/is-plan-response';
import { isSetItemResponse } from '../plan/is-set-item-response';
import { adaptRecommendedItem } from './adapt-recommended-item';
import { RecommendedAreaSItemGroupResponse } from './recommended-area-s-item-group.response';

export function adaptRecommendedAreaSItemGroup(
  res: RecommendedAreaSItemGroupResponse
): RecommendedAreaSItemGroup {
  const recommendedItemList = res.fields.recommended_item_list.reduce(
    (acc, cur) => {
      if (
        isPlanResponse(cur.fields.item) &&
        !isSetItemResponse(cur.fields.item)
      ) {
        return acc;
      }
      return [...acc, adaptRecommendedItem(cur)];
    },
    [] as RecommendedItem[]
  );
  return {
    id: new RecommendedAreaItemGroupId(res.sys.id),
    name: res.fields.name,
    icon: adaptImage(res.fields.icon),
    recommendedItemList,
  };
}
