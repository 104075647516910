import { createReducer, on } from '@ngrx/store';

import { BannerActionsUnion, storeBanners } from './banner.actions';
import { initialState } from './banner.initial-state';
import { BannerState } from './banner.state';

export function bannerReducer(
  bannerState: BannerState,
  action: BannerActionsUnion
): BannerState {
  type S = BannerState;
  const reducer = createReducer<S>(
    initialState,
    on(storeBanners, (state, { payload }): S => {
      return {
        ...state,
        banners: payload,
      };
    })
  );
  return reducer(bannerState, action);
}

export const bannerFeatureName = 'bannerFeature';
