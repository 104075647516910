import { CartItem } from '../../../../models/cart-item/cart-item';
import { IsCartItemOrderStopFunction } from '../../plan/make-is-cart-item-order-stop-function';
import { IsCartItemSoldOutFunction } from '../../sold-out/make-is-cart-item-sold-out-function';

export function countCartItems(
  cartItems: CartItem[],
  isSoldOut: IsCartItemSoldOutFunction<CartItem>,
  isOrderStop: IsCartItemOrderStopFunction<CartItem>
): number {
  return cartItems
    .filter((v) => !isSoldOut(v))
    .filter((v) => !isOrderStop(v))
    .reduce((acc, cur) => acc + cur.quantity, 0);
}
