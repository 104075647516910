import { assertFilledString } from '../../utils/assert-filled-string';
import { FilledString } from '../../utils/filled-string';
import { Nominal } from '../../utils/nominal';

export type PaymentIntentId = Nominal<FilledString, 'PaymentIntentId'>;

function assertPaymentIntentId(v: unknown): asserts v is PaymentIntentId {
  assertFilledString(v);
}

export function asPaymentIntentId(v: unknown): PaymentIntentId {
  assertPaymentIntentId(v);
  return v;
}
