import { createAction, union } from '@ngrx/store';

import { CartItem } from '../../../../models/cart-item/cart-item';
import { CartItemId } from '../../../../models/cart-item/cart-item-id';

export const initializeCart = createAction('CartActions initializeCart');

export const addItemToCart = createAction(
  'CartActions createAction',
  (payload: CartItem) => ({ payload })
);

export const incrementCartItemQuantity = createAction(
  'CartActions incrementCartItemQuantity',
  (payload: CartItem) => ({ payload })
);

export const decrementCartItemQuantity = createAction(
  'CartActions decrementCartItemQuantity',
  (payload: CartItem) => ({ payload })
);

export const deleteCartItem = createAction(
  'CartActions deleteCartItem',
  (payload: CartItemId) => ({ payload })
);

export const stopAnimation = createAction('CartActions stopAnimation');

const all = union({
  initializeCart,
  addItemToCart,
  incrementCartItemQuantity,
  decrementCartItemQuantity,
  deleteCartItem,
  stopAnimation,
});

export type CartActionsUnion = typeof all;
