import { ModifierId } from '../../../models/modifier/modifier-id';
import { SoldOuts } from '../../../models/sold-out/sold-outs';

export type IsModifierSoldOutFunction<T> = (value: T) => boolean;

export function makeIsModifierSoldOutFunction(
  soldOuts: SoldOuts
): IsModifierSoldOutFunction<ModifierId> {
  return (id) => {
    return soldOuts.modifierIds.some((v) => v.eq(id));
  };
}
