import { OrderItem } from './order-item';

export class OrderItems {
  constructor(private list: OrderItem[]) {}

  eq(other: OrderItems): boolean {
    if (this.list.length !== other.list.length) {
      return false;
    }

    return this.list.every((v, i) => {
      const orderItem = [...other][i];
      if (orderItem === undefined) {
        return false;
      }
      return v.eqQuantity(orderItem);
    });
  }

  *[Symbol.iterator](): IterableIterator<OrderItem> {
    for (const v of this.list) {
      yield v;
    }
  }
}
