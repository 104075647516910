import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { ShibaClient } from '../../lib/shiba/shiba-client';
import { ApiLocationId } from '../location/api-location-id';

@Injectable({ providedIn: 'root' })
export class NestRepository {
  constructor(private readonly shibaClient: ShibaClient) {}

  async getIsNestSupported(apiLocationId: ApiLocationId): Promise<boolean> {
    const res = await firstValueFrom(
      this.shibaClient.getIsNestSupported(apiLocationId)
    );
    return res.isNestSupported;
  }
}
