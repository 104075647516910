import { MenuItemGroupResponse } from '../menu-item-group/menu-item-group.response';
import { MenuItemResponse } from '../menu-item/menu-item.response';
import { PlanResponse } from '../plan/plan.response';
import { ContentTypeIds } from './content-type-ids';

export function isMenuItem(
  arg: MenuItemResponse | MenuItemGroupResponse | PlanResponse
): arg is MenuItemResponse {
  return arg.sys.contentType.sys.id === ContentTypeIds.menuItem;
}
