export abstract class PrimitiveValue<T extends string | number | boolean> {
  constructor(protected value: T) {}

  valueOf(): T {
    return this.value;
  }

  eq(other: PrimitiveValue<T>): boolean {
    return this.valueOf() === other.valueOf();
  }

  abstract toString(): string;
}
