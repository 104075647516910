import { OrderHistory } from './order-history';

export class OrderHistories {
  constructor(private list: OrderHistory[]) {}

  eq(other: OrderHistories): boolean {
    if (this.list.length !== other.list.length) {
      return false;
    }

    const sortedOther = other.getSortedListByCreateTime();
    const sortedThis = this.getSortedListByCreateTime();
    return sortedThis.every((v, i) => {
      const orderHistory = [...sortedOther][i];
      if (orderHistory === undefined) {
        return false;
      }
      return v.eq(orderHistory);
    });
  }

  // 注文日時の昇順で並べ替え
  getSortedListByCreateTime(): OrderHistory[] {
    return this.list.slice().sort((a, b) => {
      return a.createTime.getTime() - b.createTime.getTime();
    });
  }

  count(): number {
    return this.list.length;
  }

  *[Symbol.iterator](): IterableIterator<OrderHistory> {
    for (const v of this.list) {
      yield v;
    }
  }
}
