import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { nonNullFilter } from '../../non-null-filter';
import { sessionFeatureName } from './session.reducer';
import { SessionState } from './session.state';

const selectFeature = createFeatureSelector<SessionState>(sessionFeatureName);

export const selectSession = pipe(
  select(createSelector(selectFeature, (v) => v.session)),
  distinctUntilChanged()
);

export const selectSessionId = pipe(
  select(createSelector(selectFeature, (v) => v.session)),
  nonNullFilter(),
  map((v) => v.getSessionId()),
  distinctUntilChanged()
);
