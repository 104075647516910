import { OrderHistoryDetail } from '../order/order-history-detail';
import { OrderHistoryDetails } from '../order/order-history-details';
import { OrderItemDetail } from '../order/order-item-detail';
import { PlanTime } from './plan-time';

function getPlanItems(histories: OrderHistoryDetails): OrderItemDetail[] {
  return histories.getSortedListByCreateTime().reduce((acc, cur) => {
    const tmp: OrderItemDetail[] = [...cur.items]
      .filter((v) => (v.isPlan() || v.isPlanExtensions()) && v.exists()) // 価格が0円のものは対象だが、数量0以下は対象外
      .map((v) => {
        const contentType = v.metadata?.contentType ?? '';

        if (contentType === '') {
          throw new Error(
            'contentType plans or plan_extensions should be specified'
          );
        }

        return v;
      });

    if (tmp.length === 0) {
      return acc;
    }

    return [...acc, ...tmp];
  }, [] as OrderItemDetail[]);
}

function getStartTime(histories: OrderHistoryDetails): Date | null {
  const tmp = histories.getSortedListByCreateTime().reduce((acc, cur) => {
    const tmp: OrderItemDetail[] = [...cur.items].filter((v) => {
      return v.exists() && v.isPlan();
    });
    if (tmp.length === 0) {
      return acc;
    }

    return [...acc, cur];
  }, [] as OrderHistoryDetail[]);

  if (tmp[0] === undefined) {
    return null;
  }
  return tmp[0].createTime;
}

/**
 * @TODO 飲み放題関連の計算をBFFで行うように変更する
 * https://toreta.atlassian.net/browse/AP-639
 */
export function makePlanTime(histories: OrderHistoryDetails): PlanTime | null {
  const planItems = getPlanItems(histories);
  if (planItems.length === 0) {
    return null;
  }
  return {
    startTime: getStartTime(histories),
    orderStopPeriod: planItems.reduce(
      (acc, cur) => acc + Number(cur.metadata?.orderStopPeriod ?? 0),
      0
    ),
    planEndPeriod: planItems.reduce(
      (acc, cur) => acc + Number(cur.metadata?.planEndPeriod ?? 0),
      0
    ),
  };
}
