import { PlanType } from '../../../models/plan/plan-type';
import { PreconditionError } from '../../../utils/assert-string';

export function assertPlanType(v: unknown, target = ''): asserts v is PlanType {
  if (v !== 'course' && v !== 'set') {
    throw new PreconditionError(`${target} should be string`.trim());
  }
}

export function asPlanType(v: unknown): PlanType {
  assertPlanType(v);
  return v;
}
