import { OrderHistoryId } from './order-history-id';
import { OrderItems } from './order-items';

export interface Params {
  id: OrderHistoryId;
  items: OrderItems;
  createTime: Date;
}

export class OrderHistory {
  readonly id: OrderHistoryId;
  readonly items: OrderItems;
  readonly createTime: Date;

  constructor(params: Params) {
    this.id = params.id;
    this.items = params.items;
    this.createTime = params.createTime;
  }

  eq(other: OrderHistory): boolean {
    return [
      this.id.eq(other.id),
      this.items.eq(other.items),
      this.createTime.valueOf() === other.createTime.valueOf(),
    ].every((v) => v);
  }
}
