import { createReducer, on } from '@ngrx/store';

import { NowActionsUnion, storeNow } from './now.actions';
import { initialState } from './now.initial-state';
import { NowState } from './now.state';

export function nowReducer(
  nowState: NowState,
  action: NowActionsUnion
): NowState {
  type S = NowState;

  const reducer = createReducer<S>(
    initialState,
    on(storeNow, (state, { payload }): S => {
      return { ...state, now: new Date(payload) };
    })
  );

  return reducer(nowState, action);
}

export const nowFeatureName = 'nowFeature';
