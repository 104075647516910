import { SessionId } from './session-id';

interface Params {
  sessionId: SessionId;
  seats: number;
  tableNameList: string[];
}
export class Session {
  private readonly sessionId: SessionId;
  private readonly seats: number;
  private readonly tableNameList: string[];
  private readonly firstTableName: string;

  constructor(params: Params) {
    this.sessionId = params.sessionId;
    this.seats = params.seats;
    this.tableNameList = params.tableNameList;
    const firstTableName = this.tableNameList[0];
    if (firstTableName === undefined) {
      throw new Error(`FirstTableName is undefined.`);
    }
    this.firstTableName = firstTableName;
  }

  getSessionId(): SessionId {
    return this.sessionId;
  }

  getSeats(): number {
    return this.seats;
  }

  getTableNameList(): string[] {
    return this.tableNameList;
  }

  getFirstTableName(): string {
    return this.firstTableName;
  }

  getTableNames(): string {
    return this.tableNameList.join(',');
  }
}
