import { createAction, union } from '@ngrx/store';

export const storeIsProcessing = createAction(
  'PayByCashActions storeIsProcessing',
  (payload: boolean) => ({ payload })
);

const all = union({
  storeIsProcessing,
});

export type PayByCashActions = typeof all;
