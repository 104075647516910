import { SelectedModifier } from '../../modifier-bottom-sheet/components/selected-modifier';
import { SelectedModifierSetControllers } from '../../modifier-bottom-sheet/components/selected-modifier-set-controllers';

export function decrementModifier(
  selectedModifiers: SelectedModifierSetControllers | null,
  selectedModifier: SelectedModifier
): SelectedModifierSetControllers {
  // 選択済みオプションがない場合、引数なしで生成したインスタンスを返す
  if (selectedModifiers === null) {
    return new SelectedModifierSetControllers([]);
  }

  // 同じオプション選択パターンを探す
  const hasModifierSetController = selectedModifiers.hasModifierSetController(
    selectedModifier.modifierSetController.modifierSetControllerId
  );

  // 同じオプション選択パターンがない場合、そのまま返す
  if (!hasModifierSetController) {
    return selectedModifiers;
  }

  // 同じオプション選択パターンがある場合
  return selectedModifiers.decrement(selectedModifier);
}
