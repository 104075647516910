import { createAction, union } from '@ngrx/store';

export const storeIsNestSupported = createAction(
  'IsNestSupported storeIsNestSupported',
  (payload: boolean) => ({ payload })
);

const all = union({
  storeIsNestSupported,
});

export type IsNestSupportedActionsUnion = typeof all;
