/**
 * https://www.contentful.com/developers/docs/references/errors/
 */
interface ContentfulErrorResponse {
  message: string;
  requestId: string;
  sys: {
    type: string;
    id: string;
  };
}

export class ContentfulError {
  readonly message: string;
  readonly requestId: string;
  readonly sys: {
    type: string;
    id: string;
  };

  constructor(param: ContentfulErrorResponse) {
    this.message = param.message;
    this.requestId = param.requestId;
    this.sys = param.sys;
  }

  getErrorResponse(): ContentfulErrorResponse {
    return {
      message: this.message,
      requestId: this.requestId,
      sys: this.sys,
    };
  }
}
