import { NotEmptyStringValue } from '../not-empty-string-value';
import { PreferNominal } from '../prefer-nominal';

/**
 * contentful content type location entry id
 *
 * @TODO class LocationId を CmsLocationId に変更する
 * https://toreta.atlassian.net/browse/AP-636
 */
export class LocationId extends NotEmptyStringValue {
  locationId!: PreferNominal;
}
