import { RecommendedItemGroup } from '../../../models/recommended/recommended-item-group';
import { adaptImage } from '../image/adapt-image';
import { adaptMenuItemGroup } from '../menu-item-group/adapt-menu-item-group';
import { RecommendedItemGroupResponse } from './recommended-item-group.response';

export function adaptRecommendedItemGroupList(
  res: RecommendedItemGroupResponse
): RecommendedItemGroup {
  return {
    image: adaptImage(res.fields.image),
    itemGroup: adaptMenuItemGroup(res.fields.item_group),
  };
}
