import { Special } from '../../../models/special/special';
import { SpecialId } from '../../../models/special/special-id';
import { adaptAreaItemGroup } from '../area-item-group/adapt-area-item-group';
import { SpecialResponse } from './special.response';

export function adaptSpecial(res: SpecialResponse): Special {
  return {
    specialId: new SpecialId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name,
    description: res.fields?.description ?? '',
    areaL: res.fields.area_l ? adaptAreaItemGroup(res.fields.area_l) : null,
    areaS: res.fields.area_s
      ? res.fields.area_s.map((v) => adaptAreaItemGroup(v))
      : null,
  };
}
