import { createReducer, on } from '@ngrx/store';

import {
  PaymentDetailActionsUnion,
  storeInvoiceDetailAndDiscountDetail,
} from './payment-detail.actions';
import { initialState } from './payment-detail.initial-state';
import { PaymentDetailState } from './payment-detail.state';

export function paymentDetailReducer(
  paymentDetailState: PaymentDetailState,
  action: PaymentDetailActionsUnion
): PaymentDetailState {
  type S = PaymentDetailState;
  const reducer = createReducer<S>(
    initialState,
    on(storeInvoiceDetailAndDiscountDetail, (state, { payload }): S => {
      return {
        ...state,
        invoiceDetail: payload.invoiceDetail,
        discountDetail: payload.discountDetail,
      };
    })
  );
  return reducer(paymentDetailState, action);
}

export const paymentDetailFeatureName = 'paymentDetailFeature';
