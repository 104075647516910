import { createAction, union } from '@ngrx/store';
import { PaymentMethodResult } from '@stripe/stripe-js';
import { InvoiceDetail } from '../../../models/invoice/invoice-detail';
import { WorkflowExecutionId } from '../../../models/request-pay-by-card/workflow-execution-id';
import { ChargesErrorType } from '../charges-error.type';
import { ChargeId } from 'src/app/models/charge/charge-id';

export interface ValidationResult {
  isCompleted: boolean;
  isEmpty: boolean;
  errorMessage: string;
}

export const initPayByCardState = createAction(
  'PayByCardActions initPayByCardState'
);

export const storeCardNumberValidationResult = createAction(
  'PayByCardActions storeCardNumberValidationResult',
  (payload: ValidationResult) => ({ payload })
);

export const storeCardNumberErrorMessage = createAction(
  'PayByCardActions storeCardNumberErrorMessage',
  (payload: string) => ({ payload })
);

export const storeCardExpiryValidationResult = createAction(
  'PayByCardActions storeCardExpiryValidationResult',
  (payload: ValidationResult) => ({ payload })
);

export const storeCardExpiryErrorMessage = createAction(
  'PayByCardActions storeCardExpiryErrorMessage',
  (payload: string) => ({ payload })
);

export const storeCardCvcValidationResult = createAction(
  'PayByCardActions storeCardCvcValidationResult',
  (payload: ValidationResult) => ({ payload })
);

export const storeCardCvcErrorMessage = createAction(
  'PayByCardActions storeCardCvcErrorMessage',
  (payload: string) => ({ payload })
);

export const storeIsProcessing = createAction(
  'PayByCardActions storeIsProcessing',
  (payload: boolean) => ({ payload })
);

export const storePaymentMethodResult = createAction(
  'PayByCardActions storePaymentMethodResult',
  (payload: PaymentMethodResult | null) => ({ payload })
);

export const storeIsProcessingPayByCard = createAction(
  'PayByCardActions storeIsProcessingPayByCard',
  (payload: boolean) => ({ payload })
);

export const storeInvoiceDetail = createAction(
  'PayByCardActions storeInvoiceDetail',
  (payload: InvoiceDetail) => ({ payload })
);

export const storeWorkflowExecutionId = createAction(
  'PayByCardActions storeWorkflowExecutionId',
  (payload: WorkflowExecutionId) => ({ payload })
);

export const storeChargeId = createAction(
  'ChargeId  storeChargeId ',
  (payload: ChargeId) => ({ payload })
);

export const storeChargesErrorType = createAction(
  'Charges Error Type',
  (payload: ChargesErrorType | null) => ({ payload })
);

const all = union({
  initPayByCardState,
  storeCardNumberValidationResult,
  storeCardNumberErrorMessage,
  storeCardExpiryValidationResult,
  storeCardExpiryErrorMessage,
  storeCardCvcValidationResult,
  storeCardCvcErrorMessage,
  storeIsProcessing,
  storePaymentMethodResult,
  storeIsProcessingPayByCard,
  storeInvoiceDetail,
  storeWorkflowExecutionId,
  storeChargeId,
  storeChargesErrorType,
});

export type PayByCardActions = typeof all;
