import { createReducer, on } from '@ngrx/store';

import {
  BookTypeActionsUnion,
  storeScrollLeftCategory,
} from './book-type.actions';
import { initialState } from './book-type.initial-state';
import { BookTypeState } from './book-type.state';

export function bookTypeReducer(
  bookTypeState: BookTypeState,
  action: BookTypeActionsUnion
): BookTypeState {
  type S = BookTypeState;
  const reducer = createReducer<S>(
    initialState,
    on(storeScrollLeftCategory, (state, { payload }): S => {
      return { ...state, scrollLeftCategory: payload };
    })
  );
  return reducer(bookTypeState, action);
}

export const bookTypeFeatureName = 'bookTypeFeature';
