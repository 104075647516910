import { OrderHistoryId } from './order-history-id';
import { OrderItemDetails } from './order-item-details';

export interface Params {
  id: OrderHistoryId;
  items: OrderItemDetails;
  createTime: Date;
}

export class OrderHistoryDetail {
  readonly id: OrderHistoryId;
  readonly items: OrderItemDetails;
  readonly createTime: Date;

  constructor(params: Params) {
    this.id = params.id;
    this.items = params.items;
    this.createTime = params.createTime;
  }

  hasValidOrderItems(): boolean {
    return this.items.exists();
  }
}
