import { Image } from '../image';

export class StaffImages {
  constructor(private readonly list: Image[]) {
    if (this.list[0] === undefined) {
      throw new Error(`staff image is undefined.`);
    }
  }

  getRandomStaffImage(): Image {
    /**
     * staffImageが0件の場合エラーが起きてしまうが
     * コンストラクタで１件以上取得できることは担保できているので、
     * この処理には到達しない。そのため、"noUncheckedIndexedAccess"で起きる型エラーを除外している
     **/
    // @ts-ignore: Unreachable code error
    return this.list[Math.floor(Math.random() * this.count())];
  }

  *[Symbol.iterator](): IterableIterator<Image> {
    for (const v of this.list) {
      yield v;
    }
  }

  private count(): number {
    return this.list.length;
  }
}
