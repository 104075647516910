import { isIdentitiesRequest } from './identity/is-identities-request';
import { isIdentityOrdersRequest } from './identity/is-identity-orders';
import { isIdentityRequest } from './identity/is-identity-request';
import { isIdentityTimestampsRequest } from './identity/is-identity-timestamp';
import { isSessionsRequest } from './session/is-sessions-request';

type ReturnType =
  | 'identity'
  | 'identities'
  | 'sessions'
  | 'identityOrders'
  | 'identityTimeStamps'
  | '';

export function getRequestType(path: string): ReturnType {
  if (isSessionsRequest(path)) {
    return 'sessions';
  }
  if (isIdentityRequest(path)) {
    return 'identity';
  }
  if (isIdentitiesRequest(path)) {
    return 'identities';
  }
  if (isIdentityOrdersRequest(path)) {
    return 'identityOrders';
  }
  if (isIdentityTimestampsRequest(path)) {
    return 'identityTimeStamps';
  }
  return '';
}
