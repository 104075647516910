import { createReducer, on } from '@ngrx/store';

import {
  OrderHistoryActionsUnion,
  storeIsProcessingNotification,
  storeIsProcessingOrderStop,
  storeIsProcessingRefresh,
} from './order-history.actions';
import { initialState } from './order-history.initial-state';
import { OrderHistoryState } from './order-history.state';

export function orderHistoryReducer(
  orderItemState: OrderHistoryState,
  action: OrderHistoryActionsUnion
): OrderHistoryState {
  type S = OrderHistoryState;
  const reducer = createReducer<S>(
    initialState,
    on(storeIsProcessingOrderStop, (state, { payload }): S => {
      return { ...state, isProcessingOrderStop: payload };
    }),
    on(storeIsProcessingNotification, (state, { payload }): S => {
      return { ...state, isProcessingNotification: payload };
    }),
    on(storeIsProcessingRefresh, (state, { payload }): S => {
      return { ...state, isProcessingRefresh: payload };
    })
  );
  return reducer(orderItemState, action);
}

export const orderHistoryFeatureName = 'orderHistoryFeature';
