import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { soldOutFeatureName } from './sold-out.reducer';
import { SoldOutState } from './sold-out.state';

const selectFeature = createFeatureSelector<SoldOutState>(soldOutFeatureName);

export const selectSoldOuts = pipe(
  select(createSelector(selectFeature, (v) => v.soldOuts))
);
