import { Entry } from 'contentful';
import { ExternalLinkResponse } from './external-link-field-response';

export const BRAND_RESPONSE_INCLUDE_LEVEL = 1;
export interface BrandFieldResponse {
  title: string;
  external_links?: ExternalLinkResponse[];
}

export type BrandResponse = Entry<BrandFieldResponse>;
