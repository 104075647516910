import { Variation } from '../../../models/menu-item/variation';
import { Price } from '../../../models/price';
import { VariationId } from '../../../models/variation/variation-id';
import { adaptCMSMetadata } from '../cms-metadata/adapt-cms-metadata';
import { adaptModifierSetControllers } from '../modifier/adapt-modifier-set-controllers';
import { VariationResponse } from './variation.response';

export function adaptVariation(res: VariationResponse): Variation {
  return {
    variationId: new VariationId(res.sys.id),
    contentType: res.sys.contentType.sys.id,
    name: res.fields.name ?? '',
    namePrint: res.fields.name_print ?? '',
    unitPrice: new Price(res.fields.unit_price),
    modifierSetControllers: res.fields.modifier_set_controllers
      ? adaptModifierSetControllers(res.fields.modifier_set_controllers)
      : null,
    cmsMetadata: adaptCMSMetadata(res),
  };
}
