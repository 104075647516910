import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { firstValueFrom } from 'rxjs';
import { AppQueries, Ids } from './app.queries';
import { extractIdentity } from './extract-identity';
import { isPlan } from './lib/contentful/menu-book/adapt-menu-book';
import { AccountIds } from './models/accounts/account-ids';
import { AccountsRepository } from './models/accounts/accounts-repository';
import { BookType } from './models/book-type/book-type';
import { BookTypeId } from './models/book-type/book-type-id';
import { BookTypes } from './models/book-type/book-types';
import { Brand } from './models/brand/brand';
import { BrandRepository } from './models/brand/brand-repository';
import {
  Identity,
  IdentityDetail,
  IdentityTimestamps,
} from './models/identity/identity';
import { IdentityId } from './models/identity/identity-id';
import { IdentityRepository } from './models/identity/identity.repository';
import { ApiLocationId } from './models/location/api-location-id';
import { LocationId } from './models/location/location-id';
import { LocationOfContentful } from './models/location/location-of-contentful';
import { LocationRepository } from './models/location/location-repository';
import { MenuBook } from './models/menu-book/menu-book';
import { NestRepository } from './models/nest/nest-repository';
import { NowRepository } from './models/now/now.repository';
import { OrderHistories } from './models/order/order-histories';
import { OrderRepository } from './models/order/order.repository';
import { makePlanTime } from './models/plan/make-plan-time';
import { PlanDetail } from './models/plan/plan-detail';
import { PlanId } from './models/plan/plan-id';
import { PlanRepository } from './models/plan/plan-repository';
import { Session } from './models/session/session';
import { SessionId } from './models/session/session-id';
import { SessionRepository } from './models/session/session.repository';
import { SoldOutRepository } from './models/sold-out/sold-out.repository';
import { storeAccountIds } from './pages/shared/account-ids/store/account-ids.actions';
import { storeBrand } from './pages/shared/brand/store/brand.actions';
import { Dispatcher } from './pages/shared/dispatcher';
import {
  storeIdentity,
  storeIdentityDetail,
  storeIdentityTimestamps,
  storeOrderHistories,
} from './pages/shared/identity/store/identity.actions';
import {
  startBooting,
  stopBooting,
} from './pages/shared/loading/store/loading.actions';
import {
  storeLocation,
  storeLocationId,
  storeMenuBook,
} from './pages/shared/location/store/location.actions';
import { assertNestSupportedAccounts } from './pages/shared/nest/assert-nest-supported-accounts';
import { storeIsNestSupported } from './pages/shared/nest/store/is-nest-supported.actions';
import { storeNow } from './pages/shared/now/store/now.actions';
import {
  storePlan,
  storePlanTime,
} from './pages/shared/plan/store/plan.actions';
import { storeSession } from './pages/shared/session/store/session.actions';
import { storeSoldOuts } from './pages/shared/sold-out/store/sold-out.actions';
import { trackError } from './pages/shared/track-error';
import { exists } from './utils/exists';

@Injectable()
export class AppCommands {
  constructor(
    private readonly dispatcher: Dispatcher,
    private readonly router: Router,
    private readonly queries: AppQueries,
    private readonly sessionRepo: SessionRepository,
    private readonly identityRepo: IdentityRepository,
    private readonly locationRepo: LocationRepository,
    private readonly soldOutRepo: SoldOutRepository,
    private readonly planRepo: PlanRepository,
    private readonly nowRepo: NowRepository,
    private readonly orderRepo: OrderRepository,
    private readonly brandRepo: BrandRepository,
    private readonly accountsRepo: AccountsRepository,
    private readonly nestRepo: NestRepository,
    private dialogRef: MatDialog
  ) {}

  async init(ids: Ids): Promise<void> {
    this.startBooting();

    let brand: Brand | null = null;
    let session: Session | null = null;
    let identities: Identity[] | null = null;
    let identityDetail: IdentityDetail | null = null;
    let identity: Identity | null = null;

    try {
      // M2Mトークン発行数を抑えるため、初回リクエストを配列で飛ばさないこと
      identities = await this.identityRepo.getIdentities({
        sessionId: ids.sessionId,
        locationId: ids.apiLocationId,
      });
      identity = extractIdentity(identities);
      [brand, session, identityDetail] = await Promise.all([
        this.brandRepo.getBrand(ids.apiLocationId),
        this.sessionRepo.getSession(ids.sessionId, ids.apiLocationId),
        this.identityRepo.getIdentity(
          ids.sessionId,
          identity.id,
          ids.apiLocationId
        ),
      ]);
    } catch (e) {
      this.stopBooting();

      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storeBrand(brand));
    this.dispatcher.dispatch(storeSession(session));
    this.dispatcher.dispatch(
      storeLocationId({
        locationId: ids.locationId,
        apiLocationId: ids.apiLocationId,
      })
    );
    this.dispatcher.dispatch(storeIdentity(identity));
    this.dispatcher.dispatch(storeIdentityDetail(identityDetail));

    this.stopBooting();

    await this.initLocationAndPlan(ids.locationId, identityDetail);
    try {
      await this.fetchAccountIds(ids.apiLocationId);
      await this.fetchIsNestSupported(ids.apiLocationId);
      const accountIds = await firstValueFrom(this.queries.accountIds$);
      const isNestSupported = await firstValueFrom(
        this.queries.isNestSupported$
      );
      assertNestSupportedAccounts(isNestSupported, accountIds);
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  async preparing(): Promise<void> {
    await this.router.navigate(['preparing'], {
      queryParamsHandling: 'preserve',
    });
  }

  async orderStart(): Promise<void> {
    const locationId = await firstValueFrom(this.queries.locationId$);
    if (locationId === null) {
      throw new Error('LocationId must be non-null');
    }
    const apiLocationId = await firstValueFrom(this.queries.apiLocationId$);
    if (apiLocationId === null) {
      throw new Error('ApiLocationId must be non-null');
    }
    const session = await firstValueFrom(this.queries.session$);
    if (session === null) {
      throw new Error('Session must be non-null');
    }

    try {
      await this.soldOutRepo.listenSoldOuts(apiLocationId).subscribe(
        (soulOuts) => {
          this.dispatcher.dispatch(storeSoldOuts(soulOuts));
        },
        async (e) => {
          trackError(e);
          await this.router.navigate(['server-error'], {
            queryParamsHandling: 'preserve',
          });
        }
      );
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
    }

    const currentPath = this.router.url.split('?')[0]?.split('/')[1] ?? '';
    // 準備中ページだった場合のみ、起動画面に遷移する
    if (currentPath === 'preparing') {
      await this.router.navigate(['splash'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  async orderStop(): Promise<void> {
    const session = await firstValueFrom(this.queries.session$);
    const identity = await firstValueFrom(this.queries.identity$);
    const apiLocationId = await firstValueFrom(this.queries.apiLocationId$);
    /**
     * 最新のinvoiceを取得する
     * storeのidentityDetailを更新すると orderStopに値が流れて無限ループになるので、更新しない
     * https://toreta.atlassian.net/browse/AP-468
     *
     * @TODO [リファクタリング] AppCommands.OrderStop で identity ではなく
     * invoice のみ取得するエンドポイントを叩くようにする #546
     * https://github.com/toreta/toreta-ap-ox-app/issues/546
     */
    let identityDetail: IdentityDetail | null = null;
    try {
      identityDetail = await this.identityRepo.getIdentity(
        session.getSessionId(),
        identity.id,
        apiLocationId
      );
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    if (!identityDetail.hasInvoices()) {
      await this.router.navigate(['order-history'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }

    const currentPath = this.router.url.split('?')[0]?.split('/')[1] ?? '';
    /**
     * 決済フロー・お支払い内容確認画面・領収書発行・エラー画面、未収画面の場合スルーする
     * identity.endAtの更新に失敗しても、退店状態で閲覧できるページは閲覧できる必要がある
     */
    if (
      currentPath === 'pay' ||
      currentPath === 'pay-order-detail' ||
      currentPath === 'pay-by-cash' ||
      currentPath === 'pay-by-card' ||
      currentPath === 'processing-page' ||
      currentPath === 'staff-confirm' ||
      currentPath === 'server-error' ||
      currentPath === 'leaving' ||
      currentPath === 'payment-detail' ||
      currentPath === 'receipt' ||
      currentPath === 'receipt-confirm' ||
      currentPath === 'receipt-end' ||
      currentPath === 'receivable-page'
    ) {
      return;
    }

    this.dialogRef.closeAll(); // 開きっぱなしのダイアログがあった場合、閉じる
    await this.router.navigate(['pay'], {
      queryParamsHandling: 'preserve',
    });
  }

  async leaving(): Promise<void> {
    const locationId = await firstValueFrom(this.queries.locationId$);
    if (locationId === null) {
      throw new Error('LocationId must be non-null');
    }
    const session = await firstValueFrom(this.queries.session$);
    if (session === null) {
      throw new Error('Session must be non-null');
    }

    const currentPath = this.router.url.split('?')[0]?.split('/')[1] ?? '';
    // お支払い内容確認画面・領収書発行・エラー画面、未収画面の場合スルーする
    if (
      currentPath === 'processing-page' ||
      currentPath === 'leaving' ||
      currentPath === 'payment-detail' ||
      currentPath === 'receipt' ||
      currentPath === 'receipt-confirm' ||
      currentPath === 'receipt-end' ||
      currentPath === 'receivable-page' ||
      currentPath === 'server-error'
    ) {
      return;
    }

    this.dialogRef.closeAll(); // 開きっぱなしのダイアログがあった場合、閉じる
    await this.router.navigate(['leaving'], {
      queryParamsHandling: 'preserve',
    });
  }

  async receivable(): Promise<void> {
    const currentPath = this.router.url.split('?')[0]?.split('/')[1] ?? '';
    // 決済フロー・エラー画面の場合スルーする
    if (
      currentPath === 'processing-page' ||
      currentPath === 'server-error' ||
      currentPath === 'receivable-page'
    ) {
      return;
    }
    this.dialogRef.closeAll(); // 開きっぱなしのダイアログがあった場合、閉じる
    await this.router.navigate(['receivable-page'], {
      queryParamsHandling: 'preserve',
    });
  }

  async urlError(): Promise<void> {
    await this.router.navigate(['url-error']);
  }

  /**
   * planId、extraMenuBookTypeIdを fetchLocation() メソッド内で取得すると
   * それぞれ情報が最新でない場合でも呼べてしまい意図した挙動にならないないため、引数で指定している
   */
  async fetchLocation(
    locationId: LocationId,
    planId: PlanId | null,
    extraMenuBookTypeId: BookTypeId | null,
    apiLocationId: ApiLocationId
  ): Promise<void> {
    let location: LocationOfContentful | null = null;
    try {
      location = await this.locationRepo.getLocation(
        locationId,
        planId,
        extraMenuBookTypeId,
        apiLocationId
      );
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storeLocation(location));
  }

  async fetchAccountIds(apiLocationId: ApiLocationId): Promise<void> {
    let accountIds: AccountIds;
    try {
      accountIds = await this.accountsRepo.getAccountIds(apiLocationId);
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storeAccountIds(accountIds));
  }

  async fetchPlan(
    identityDetail: IdentityDetail,
    apiLocationId: ApiLocationId
  ): Promise<void> {
    const planId = identityDetail.getOrders().getFirstOrderPlanId();
    let plan: PlanDetail | null = null;
    try {
      if (planId !== null) {
        plan = await this.planRepo.getPlan(planId, apiLocationId);
      }
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storePlan(plan));
  }

  async initLocationAndPlan(
    locationId: LocationId,
    identityDetail: IdentityDetail
  ): Promise<void> {
    const apiLocationId = await firstValueFrom(this.queries.apiLocationId$);

    this.startBooting();

    /**
     * プラン情報取得 => 店舗情報取得 => プラン時間更新の順番は厳守！
     */
    // プラン情報取得
    await this.fetchPlan(identityDetail, apiLocationId);

    // 店舗情報取得
    const planId = await firstValueFrom(this.queries.planId$);
    const extraMenuBookTypeId = await firstValueFrom(
      this.queries.extraMenuBookTypeId$
    );
    await this.fetchLocation(
      locationId,
      planId,
      extraMenuBookTypeId,
      apiLocationId
    );

    this.stopBooting();

    // プラン時間更新
    if (!exists(extraMenuBookTypeId)) {
      return;
    }
    await this.updatePlanTime(identityDetail);
  }

  /**
   * @TODO ポーリングで取得している情報をBFFでまとめる
   * https://toreta.atlassian.net/browse/AP-640
   */
  async pollingEvents(ids: Ids): Promise<void> {
    const identity = await firstValueFrom(this.queries.identity$);
    let orderHistories: OrderHistories | null = null;
    let identityTimestamps: IdentityTimestamps | null = null;
    try {
      [identityTimestamps, orderHistories] = await Promise.all([
        this.identityRepo.getIdentityTimestamps({
          sessionId: ids.sessionId,
          identityId: identity.id,
          locationId: ids.apiLocationId,
        }),
        this.orderRepo.getOrders({
          sessionId: ids.sessionId,
          identityId: identity.id,
          locationId: ids.apiLocationId,
        }),
      ]);
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storeIdentityTimestamps(identityTimestamps));
    this.dispatcher.dispatch(storeOrderHistories(orderHistories));
  }

  /**
   * @TODO 飲み放題関連の計算をBFFで行うように変更する
   * https://toreta.atlassian.net/browse/AP-639
   */
  async pollingNow(): Promise<void> {
    let now: number;

    try {
      now = await this.nowRepo.getNow();
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }

    this.dispatcher.dispatch(storeNow(now));
  }

  async updateIdentityDetail(
    sessionId: SessionId,
    identityId: IdentityId,
    apiLocationId: ApiLocationId
  ): Promise<void> {
    let identityDetail: IdentityDetail | null = null;
    try {
      identityDetail = await this.identityRepo.getIdentity(
        sessionId,
        identityId,
        apiLocationId
      );
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    this.dispatcher.dispatch(storeIdentityDetail(identityDetail));

    // プラン情報取得
    await this.fetchPlan(identityDetail, apiLocationId);
    // メニューブック更新
    const extraMenuBookTypeId = await firstValueFrom(
      this.queries.extraMenuBookTypeId$
    );
    const latestPlanId = await firstValueFrom(this.queries.planId$);
    const storedAllMenuBook = await firstValueFrom(this.queries.allMenuBook$);
    const menuBook: MenuBook = {
      name: storedAllMenuBook.name,
      bookTypes: new BookTypes(
        [...storedAllMenuBook.bookTypes].map((v) => {
          const planId = isPlan(extraMenuBookTypeId, v.bookTypeId)
            ? latestPlanId
            : null;
          return new BookType({
            bookTypeId: v.bookTypeId,
            name: v.name,
            icon: v.icon,
            recommended: v.recommended,
            categories: v.categories,
            caution: v.caution,
            displayCustomerApp: v.displayCustomerApp,
            planId,
          });
        })
      ),
    };
    this.dispatcher.dispatch(storeMenuBook(menuBook));
  }

  /**
   * extraMenuBookTypeIdを updatePlanTime() メソッド内で取得すると
   * 情報が最新でない場合でも呼べてしまい意図した挙動にならないないため、引数で指定している
   */
  async updatePlanTime(identityDetail: IdentityDetail): Promise<void> {
    const planTime = makePlanTime(identityDetail.getOrders());
    this.dispatcher.dispatch(storePlanTime(planTime));
  }

  private startBooting(): void {
    this.dispatcher.dispatch(startBooting());
  }

  private stopBooting(): void {
    this.dispatcher.dispatch(stopBooting());
  }

  async fetchIsNestSupported(apiLocationId: ApiLocationId): Promise<void> {
    let isNestSupported: boolean = false;
    try {
      isNestSupported = await this.nestRepo.getIsNestSupported(apiLocationId);
    } catch (e) {
      trackError(e);
      await this.router.navigate(['server-error'], {
        queryParamsHandling: 'preserve',
      });
    }
    this.dispatcher.dispatch(storeIsNestSupported(isNestSupported));
  }
}
