import { isUrlValid } from '../../../models/brand/is-url-valid';
import { Story } from '../../../models/menu-item/story';
import { adaptImage } from '../image/adapt-image';
import { StoryResponse } from './story.response';

export function adaptStory(res: StoryResponse): Story {
  if (
    res.fields.external_link_url !== undefined &&
    !isUrlValid(res.fields.external_link_url)
  ) {
    throw new Error('External Link Url is invalid.');
  }
  return {
    title: res.fields.title,
    image: res.fields.image ? adaptImage(res.fields.image) : null,
    bodyText: res.fields.body_text,
    externalLinkUrl: res.fields.external_link_url,
    externalLinkButtonLabel:
      res.fields.external_link_button_label !== undefined
        ? res.fields.external_link_button_label
        : '詳しくはこちら',
  };
}
