import { FloatValue } from './float-value';
import { PreferNominal } from './prefer-nominal';

export class Price extends FloatValue {
  price!: PreferNominal;

  constructor(readonly value: number) {
    super(value);
  }

  toString(): string {
    // ひとまず固定
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(this.value);
  }

  toNumber(): number {
    return Number(this.value);
  }

  // 小数点切り上げ
  getSplit(seats: number): Price {
    return new Price(Math.ceil(this.value / seats));
  }
}
