<ng-container *ngIf="queries.cartItems$ | async as cartItems">
  <ng-container *ngIf="cartItems.length > 0">
    <a
      [routerLink]="'/order-list'"
      queryParamsHandling="preserve"
      class="OrderList"
      >注文リストを見る
      <ng-container *ngIf="queries.cartItemCount$ | async as cartItemCount">
        <span
          [@addCartDefault]="
            (queries.isStartAnimation$ | async) ? 'addCart' : 'default'
          "
          class="OrderList-Counter"
          >{{ cartItemCount }}</span
        >
      </ng-container>
    </a>
  </ng-container>
</ng-container>
