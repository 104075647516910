import { assertFilledString } from '../../utils/assert-filled-string';
import { PreconditionError } from '../../utils/assert-string';
import { ChargeStatus, chargeStatuses } from './charge-status';

export function assertChargeState(v: unknown): asserts v is ChargeStatus {
  assertFilledString(v);
  if (!chargeStatuses.includes(v as ChargeStatus)) {
    throw new PreconditionError('value should be charge state');
  }
}
