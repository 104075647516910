import { GetTimestampsResponse } from 'src/app/lib/cocktail/identity/get-timestamps.response';

export const getIdentityTimestampsResponseMock: GetTimestampsResponse = {
  status: 'ok',
  result: {
    identity: {
      id: 'trial',
    },
  },
};
