import { eqDate } from '../../pages/shared/session/store/eq-date';
import { ReceivableAccountId } from '../accounts/receivable-account-id';
import { Charge } from '../charge/charge';
import { Discount } from '../discount/discount';
import { Invoice as InvoiceCore } from '../invoice/invoice';
import { InvoiceId } from '../invoice/invoice-id';
import { Payment as PaymentCore } from '../invoice/payment';
import { OrderHistoryDetails } from '../order/order-history-details';
import { Price } from '../price';
import { IdentityId } from './identity-id';

/**
 * GET /sessions/[sessionId]/identities/[identityId]?locationId=[locationId] と
 * GET /sessions/[sessionId]/identities?locationId=[locationId]
 * のレスポンス内容が異なるので2種類のinterfaceを用意しました。
 */
export interface Identity {
  id: IdentityId;
}

export interface IdentityTimestamps {
  orderStopTime: Date | null; // オーダーストップ前は null
  endTime: Date | null; // 退店前は null
}

export type Payment = Pick<
  PaymentCore,
  'id' | 'type' | 'providerId' | 'provider'
>;

export type Invoice = Omit<InvoiceCore, 'payments'> & {
  payments: Payment[];
};

export interface Params {
  id: IdentityId;
  total: Price;
  orders: OrderHistoryDetails;
  discount: Discount | null;
  invoices: Invoice[];
  startTime: Date | null;
  orderStopTime: Date | null;
  endTime: Date | null;
}

export class IdentityDetail {
  private readonly id: IdentityId;
  private readonly total: Price;
  private readonly orders: OrderHistoryDetails;
  private readonly discount: Discount | null;
  private readonly invoices: Invoice[];
  private readonly startTime: Date | null; // 開始前は null
  private readonly orderStopTime: Date | null; // オーダーストップ前は null
  private readonly endTime: Date | null; // 退店前は null

  constructor(params: Params) {
    this.id = params.id;
    this.total = params.total;
    this.orders = params.orders;
    this.discount = params.discount;
    this.invoices = params.invoices;
    this.startTime = params.startTime;
    this.orderStopTime = params.orderStopTime;
    this.endTime = params.endTime;
  }

  getTotal(): Price {
    return this.total;
  }

  getOrders(): OrderHistoryDetails {
    return this.orders;
  }

  getDiscount(): Discount | null {
    return this.discount;
  }

  getInvoice(): Invoice {
    // 個別会計できないので、invoiceは1つ
    return this.invoices[0];
  }

  getCharges(): Charge[] {
    return this.invoices[0].paymentIntents.flatMap((v) => v.charges);
  }

  getInvoiceId(): InvoiceId {
    // 個別会計できないので、invoiceは1つ
    return this.invoices[0].id;
  }

  hasDiscounts(): boolean {
    return this.discount !== null;
  }

  hasInvoices(): boolean {
    return this.invoices.length > 0;
  }

  eqIdentityTime(other: IdentityDetail): boolean {
    return [
      eqDate(this.startTime, other.startTime),
      eqDate(this.orderStopTime, other.orderStopTime),
      eqDate(this.endTime, other.endTime),
    ].every((v) => v);
  }

  eqIdentityOrderStopTime(orderStopTime: Date | null): boolean {
    return eqDate(this.orderStopTime, orderStopTime);
  }

  eqIdentityEndTime(endTime: Date | null): boolean {
    return eqDate(this.endTime, endTime);
  }

  getIsPreparing(): boolean {
    return [
      this.startTime === null,
      this.orderStopTime === null,
      this.endTime === null,
    ].every((v) => v);
  }

  getIsOrderStart(): boolean {
    if (this.orderStopTime !== null || this.endTime !== null) {
      return false;
    }
    return this.startTime !== null;
  }

  getIsOrderStop(): boolean {
    if (this.orderStopTime === null || this.endTime !== null) {
      return false;
    }
    return this.orderStopTime.valueOf() <= Date.now();
  }

  getIsLeaving(): boolean {
    if (this.endTime === null) {
      return false;
    }
    return this.endTime.valueOf() <= Date.now();
  }

  getIsReceivable(receivableAccountId: ReceivableAccountId): boolean {
    return this.getCharges().some(
      (v) => v.metadata.debitAccountId === receivableAccountId.toString()
    );
  }

  getMergedIdentityTimestamps(timestamps: IdentityTimestamps): IdentityDetail {
    return new IdentityDetail({
      id: this.id,
      total: this.total,
      orders: this.orders,
      discount: this.discount,
      invoices: this.invoices,
      startTime: this.startTime,
      orderStopTime: timestamps.orderStopTime,
      endTime: timestamps.endTime,
    });
  }
}
