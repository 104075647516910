import { RecommendedAreaItemGroupId } from '../../../models/recommended/recommended-area-item-group-id';
import { RecommendedAreaLItemGroup } from '../../../models/recommended/recommended-area-l-item-group';
import { RecommendedItem } from '../../../models/recommended/recommended-item';
import { isPlanResponse } from '../plan/is-plan-response';
import { isSetItemResponse } from '../plan/is-set-item-response';
import { adaptRecommendedItem } from './adapt-recommended-item';
import { RecommendedAreaLItemGroupResponse } from './recommended-area-l-item-group.response';

export function adaptRecommendedAreaLItemGroup(
  res: RecommendedAreaLItemGroupResponse
): RecommendedAreaLItemGroup {
  const recommendedItemList = res.fields.recommended_item_list.reduce(
    (acc, cur) => {
      if (
        isPlanResponse(cur.fields.item) &&
        !isSetItemResponse(cur.fields.item)
      ) {
        return acc;
      }
      return [...acc, adaptRecommendedItem(cur)];
    },
    [] as RecommendedItem[]
  );
  return {
    id: new RecommendedAreaItemGroupId(res.sys.id),
    name: res.fields.name,
    recommendedItemList,
  };
}
