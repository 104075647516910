import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { EventFooterOrderHistoryClickedInputModel } from '../../../../lib/event-logger/event-footer-order-history-clicked/event-footer-order-history-clicked-input-model';
import { EventFooterOrderHistoryClickedItem } from '../../../../lib/event-logger/event-footer-order-history-clicked/event-footer-order-history-clicked-item';
import { EventLoggerRepository } from '../../../../models/event-logger/event-logger-repository';
import { storeScrollLeftCategory } from '../../../book-type-page/store/book-type.actions';
import { Dispatcher } from '../../dispatcher';
import { trackError } from '../../track-error';
import { FooterMenuQueries } from './footer-menu.queries';

@Injectable()
export class FooterMenuCommands {
  constructor(
    private readonly queries: FooterMenuQueries,
    private readonly dispatcher: Dispatcher,
    private readonly eventLoggerRepo: EventLoggerRepository
  ) {}

  onClickBookType(): void {
    this.dispatcher.dispatch(storeScrollLeftCategory(0));
  }

  async sendLogEventFooterOrderHistoryClicked(
    clickedItem: EventFooterOrderHistoryClickedItem
  ): Promise<void> {
    const locationId = await firstValueFrom(this.queries.locationId$);
    const locationName = await firstValueFrom(this.queries.locationName$);
    const session = await firstValueFrom(this.queries.session$);
    const inputModel = new EventFooterOrderHistoryClickedInputModel({
      locationId,
      locationName,
      session,
      clickedItem,
    });
    try {
      this.eventLoggerRepo.trackEventFooterOrderHistoryClicked(inputModel);
    } catch (e) {
      trackError(e);
    }
  }
}
