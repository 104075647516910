import { MetadataAny } from '../../lib/cocktail/models/metadata-any';
import { ModifierWithQuantity } from '../../pages/shared/modifier-bottom-sheet/components/modifier-with-quantity';
import { exists } from '../../utils/exists';
import { MenuItemId } from '../menu-item/menu-item-id';
import { Price } from '../price';
import { VariationId } from '../variation/variation-id';

export interface Params {
  menuItemId: MenuItemId;
  menuItemName: string;
  quantity: number;
  unitPrice: Price;
  variationId: VariationId;
  variationName: string;
  modifiers: ModifierWithQuantity[];
  metadata: MetadataAny | null;
  subtotal: Price;
}

export class OrderItemDetail {
  readonly menuItemId: MenuItemId;
  readonly menuItemName: string;
  readonly quantity: number;
  readonly unitPrice: Price;
  readonly variationId: VariationId;
  readonly variationName: string;
  readonly modifiers: ModifierWithQuantity[];
  readonly subtotal: Price;
  readonly metadata: MetadataAny | null;

  constructor(params: Params) {
    this.menuItemId = params.menuItemId;
    this.menuItemName = params.menuItemName;
    this.quantity = params.quantity;
    this.unitPrice = params.unitPrice;
    this.variationId = params.variationId;
    this.variationName = params.variationName;
    this.modifiers = params.modifiers;
    this.subtotal = params.subtotal;
    this.metadata = params.metadata;
  }

  isPlan(): boolean {
    if (!exists(this.metadata)) {
      return false;
    }
    return [
      this.metadata.id,
      this.metadata.contentType === 'plans',
      Number(this.metadata.orderStopPeriod) > 0,
      Number(this.metadata.planEndPeriod) > 0,
    ].every((v) => v);
  }

  isPlanExtensions(): boolean {
    if (!exists(this.metadata)) {
      return false;
    }
    return [
      this.metadata.id,
      this.metadata.contentType === 'plan_extensions',
      Number(this.metadata.orderStopPeriod) > 0,
      Number(this.metadata.planEndPeriod) > 0,
    ].every((v) => v);
  }

  exists(): boolean {
    return this.quantity > 0;
  }
}
