import { ErrorHandler, Injectable } from '@angular/core';
import { CHUNK_ERROR_MESSAGE } from './error-message';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (CHUNK_ERROR_MESSAGE.test(error.message)) {
      window.location.reload();
    }
  }
}
