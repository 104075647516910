import { createReducer, on } from '@ngrx/store';
import { storeIsNestSupported } from './is-nest-supported.actions';
import { initialState } from './is-nest-supported.initial-state';
import { IsNestSupportedState } from './is-nest-supported.state';

export const isNestSupportedReducer = createReducer<IsNestSupportedState>(
  initialState,
  on(storeIsNestSupported, (state, { payload }) => {
    return payload ?? state;
  })
);

export const isNestSupportedFeatureName = 'isNestSupportedFeature';
