import { MenuItemGroupId } from '../../../models/menu-item-group/menu-item-group-id';
import { MenuItemId } from '../../../models/menu-item/menu-item-id';
import { PlanId } from '../../../models/plan/plan-id';
import { SoldOuts } from '../../../models/sold-out/sold-outs';

export type IsMenuItemSoldOutFunction<T> = (value: T) => boolean;

export function makeIsMenuItemSoldOutFunction(
  soldOuts: SoldOuts
): IsMenuItemSoldOutFunction<MenuItemId | MenuItemGroupId | PlanId> {
  return (id) => {
    if (id instanceof MenuItemGroupId) {
      return false;
    }
    return soldOuts.itemIds.some((v) => v.eq(id));
  };
}
